export const ButtonConstant = {
  Submit: "Submit",
  Cancel: "Cancel",
  Next: "Next",
  Back: "Back",
  Skip: "Skip",
  Finish: "Finish",
  Ok: "Ok",
  Confirm: "Confirm",
  Save: "Save",
  Delete: "Delete",
  Process: "Process",
  Preview: "Preview",
  Close: "Close",
  DownloadCSV: "Download CSV Template",
  IgnoreErrors: "Ignore Errors",
  SaveForLater: "Save for Later",
  NoDoNotAddIt: "No, Do Not Add it",
  YesAddIt: "Yes, Add it",
  NoDoNotUpdate: "No, Do Not Update",
  YesUpdate: "Yes, Update",
  SaveAndClose: "Save & Close"
};

export const ClientInformationConstant = {
  ClientInformation: "Client Information",
  ClientSearch: "Client Search",
  ClientID: "Client ID",
  TaxYear: "Tax Year",
  OfficeLocation: "Office Location",
  ERO: "ERO",
  EngagementType: "Engagement Type",
  Taxpayer: "Taxpayer",
  Spouse: "Spouse",
  Name: "Name",
  Email: "Email",
  Mobile: "Mobile",
  Deceased: "Deceased",
  AddAdditionalTaxpayer: "Add Additional Taxpayer",
  RolloverPriorInfo: "Rollover Prior Year Information",
  ClientSearchKeyName: "clientSearch",
  ClientIDKeyName: "clientId",
  TaxYearKeyName: "taxYear",
  OfficeLocationKeyName: "officeLocation",
  EROKeyName: "ero",
  TaxpayerKeyName: "taxpayer",
  NameKeyName: "name",
  EmailKeyName: "emailAddress",
  MobileKeyName: "mobileNumber",
  IsDeceasedKeyName: "isDeceased",
  CountryCodeKeyName: "countryCode",
  SpouseKeyName: "spouse",
  AddAdditionalTaxpayerKeyName: "addAdditionalTaxpayer",
  RolloverPriorInfoKeyName: "rollover",
  SelectEngagementType: "Select Engagement Type",
  EngagementTypeName:"engagementType",
};

export const ErrorsConstant = {
  IgnoreErrors: "Ignore Errors",
  IgnoreCommitable: "The Organizer will still be delivered with this batch",
  // OrganizerStillDelivered: "Organizer will still be delivered with this batch",
  IgnoreUncommitable: "The Organizer will be removed from this batch"
};

export const OrganizerConstant = {
  PleaseNote: "Please Note :",
  DoNotUpload: "Do not upload encrypted, password-protected, or locked documents.",
  BatchWarning: "Please note, any documents uploaded for signature will apply to all deliverables within the batch.",
  TaxSoftware: "Tax Software",
  BatchName: "Batch Name",
  ERO: "ERO", 
  EngagementType: "Engagement Type",
  TaxYear: "Tax Year",
  OfficeLocation: "Office Location",
  SelectZip: "Select Zip File",
  SelectCSV: "Select CSV File",
  FileSizeLimit: "File size limit is 1GB.",
  IncludeEL: "Include Engagement Letter",
  IncludeOrganizers: "Includes Fillable Organizers",
  DeliverWithoutOrganizers: "Deliver without Fillable Organizers",
  SignToggleDisabledTitle: "Enable to e-Sign Organizer page",
  ESignDisabled: "This page has been identified as a source document page. E-signing is not available for this page.",
  EngagementLetter:"Batch contains engagement letter",
};
export const FILE_UPLOAD_MAXSIZE = "1073741824";

export const EsignDcoumentsConstant = {
  RecentQuestionnaires: "Recent Questionnaires",
  AtleastOneEngagementLetter: "There should be at least one page in Engagement Letter",
  BatchAlertMessage: "Please note, any documents uploaded for signature will apply to all deliverables within the batch.",

};
export const OrganizersConstant = {
  AtleastOneOrganizer :"There should be at least one page in Organizer"
}

export const CustomQuestionsConstant = {
  RecentQuestionnaires: "Recent Questionnaires"
};

export const RequestDocumentConstant = {
  RecentQuestionnaires: "Recent Questionnaires",
  DELETE_ALL_MODAL_TITLE: "Confirm Delete",
  DELETE_ALL_MODAL_TEXT: "Are you sure you want to delete all request list items? This cannot be undone.",
  ALERT_MESSAGE: "Please note, the Document Request List template selected will apply to all deliverables within the batch.",
  NO_DRL_DESCRIPTION: "Please add documents you request from recipient.",
  DRL_DESCRIPTION: "Please review the document list below.",
};

export const RequestDocumentNotAvailableType = 'Not Available';
export const RequestDocumentOtherType = 'Other';
export const RequestDocumentAdditionalDRLType = 'Additional DRL';


export const DocumentRequestConstants = {
  DocumentItem: {
    maxLength: 250,
  },
  NoAutoDrItemText: "By using a template the AI Recognized List will be disabled",
  DeleteAllModal: {
    modalHeader: "Confirm Delete",
    modalBody: "Are you sure you want to delete all request list items? This cannot be undone."
  },
  NoDocsRequestDocsHeader: "Please add documents you request from recipient.",
  NoTemplatesTooltip: "No Template exists.",
  RequestDocsHeader: "Please review the document list below.",
  SourceDocumentDisbaled: "Gather source document setting disabled",
  AddDocumentModal: {
    title: "Add Document(s)",
    maxLength: 250,
    validationText: "Field cannot be blank",
    bodyTitle: "Document Name",
    placeholder: "Enter Document Name",
    placeholderCategory: "Select Template",
  },
  Category: "Category",
  SelectDocumentTemplate: "Please select a template",
  NA: "N/A",
  ZeroCategory:"-",
  UndoNA: "Undo N/A",
  NAToolTip: "Request item marked as not applicable by taxpayer",
  UnCategorized:"Uncategorized",
}

export const DeliveryOptionsConstant = {
  TaxpayerDashboard: "Taxpayer Dashboard",
  Description: "Set internal contact and set message to recipient on Gather landing page.",
  EmailNotificationDescription: "Assign firm members to receive notifications upon taxpayer completion points.",
  ReminderDescription: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.",
  Preview: "Preview",
  PreviewUnavailable: "Preview Unavailable",
  New: "New",
  Edit: "Edit",
  View: "View",
  ContactPerson: "Contact Person",
  MessageTemplate: "Message Template",
  Template: "Template",
  EmailNotifications: "Email Notifications",
  SenderName: "Sender Name",
  EngagementLetters: "Engagement Letters",
  CompletedOrganizers: "Completed Organizers",
  Name: "Name",
  UploadedFiles: "Uploaded Files",
  Reminders: "Reminders",
  Signing: "Signing",
  Organizers: "Organizers",
  SendSigningReminder: "Send Signing Reminder every",
  SendOrganizerReminder: "Send Organizer Reminder every",
  SinceLastReminder: "since last reminder/initial email",
  CustomQuestions: "Custom Questions",
  RequestDocuments: "Request Documents",
  GatherDefaultSettings: "gatherDefaultSettings",
  SelectedContactPerson: "selectedContactPerson",
  SelectedMessageTemplate: "selectedMessageTemplate",
  SelectedSender: "selectedSenders",
  completedELNotificationUser: "completedELNotificationUser",
  completedOrganizerNotificationUser: "completedOrganizerNotificationUser",
  uploadedFilesNotificationUser: "uploadedFilesNotificationUser",
  enableSigningReminder: "enableEsignReminder",
  SigningReminderDays: "esignReminderDays",
  OrganizerReminderDays: "organizerReminderDays",
  enableOrganizerReminder: "enableOrganizerReminder",
  PreparerMessageShow: "preparerMessageShow",
  AddMessageModalTitle: "Add Message from Tax Preparer",
  EditMessageModalTitle: "Edit Message from Tax Preparer",
  ViewMessageModalTitle: "Message from Tax Preparer",
  FinishGatherProcessing: "Finish Gather Processing",
  SelectSenderName: "Please select sender name",
  SelectContactPerson: "Please select contact person",
  ELCompletedNotificationUser: "Please select engagement letter completed notification user",
  OrgCompletedNotificationUser: "Please select Organizer completed notification user",
  FilesUploadedNotificationUser: "Please select files uploaded notification user",
  SelectDeliveryMessageTemplate: "Please select a template",
  NoSignatureDocumentToolTip: "No signature documents associated with the GTR",
  NoOrganizerDocumentToolTip: "No organizer documents associated with the GTR",
};

export const ReportProblemConstants = {
  Success: "Problem reported successfully. Your Tracking ID is: ",
  Title: "Report a Problem",
  Failed: "Problem reporting failed",
  Failure: "Failure",
}

export const ValidationMessageValues = {
  0: "None",
  1: "Missing",
  2: "Invalid",
  3: "Incorrect Length",
  4: "Contains a Space",
  5: "Format Invalid",
  6: "Contains Non-Numeric Characters",
  7: "Duplicate",
  8: "Encrypted document not supported",
  9: "Invalid or Missing",
  10: "Missing but other Information Present",
  11: "Contains Invalid Character",
  12: "Missing organizer pdf for this Client ID",
  13: "Encrypted Organizer pdf is not supported",
  14: "Missing Engagement letter pdf for this Client ID",
  15: "Encrypted Engagement letter pdf is not supported"
}

export const BatchColumnValues = {
  0: "None",
  1: "Client Id",
  2: "Taxpayer Email",
  3: "Taxpayer First Name",
  4: "Taxpayer Last Name",
  5: "Taxpayer Country Code",
  6: "Taxpayer Mobile Number",
  7: "Spouse Email",
  8: "Spouse First Name", 
  9: "Spouse Last Name",
  10: "Spouse Country Code",
  11: "Spouse Mobile Number",
  12: "DocumentGuid"
}

export const TemplateModalConstants = {
  //Templates list
  RecentQuestionnaires: "Recent Questionnaires",
  StartFromScratch: "Start from scratch",
  BuildNewQuestionnaire: "Build a new questionnaire",
  BuildNewTemplate: "Build your own Template",
  WithYourCustomQuestions: "with your custom questions.",
  CreateNew: "Create New",
  // Edit Template
  UntitledQuestionnaire: "Title here",
  QuestionnaireDescription: "Brief description here",
  AddNewSection: "Add New Section",
  //Add Template Modal
  AddTemplateModalHeader: "Create New Template",
  EditTemplateModalHeader: "Rename Template",
  TemplateNamePlaceholder: "Enter template name",
  TemplateNameValidationMessage: "Please enter template name",
  TemplateDesciptionPlaceholder: "Enter template description",
  DeleteDefaultTemplateConfirmation: "This template is set to default , do you still want to delete this template ?",
  DeleteTitle: "Permanent Delete",
  DeleteConfirmation: "Are you sure want to delete the template ?",
  DeleteSuccessMessage: "Template deleted successfully", //TO confirm
  CustomTemplate: "Custom Template",
  DeleteTemplateInUse: "Template In-use",
  DeleteConfirmationForTemplateInUse:
    "You are trying to delete a questionnaire template that is being used for an organizer in process. Please confirm if you wish to permanently delete the selected questionnaire template.",
  DeleteConfirmationNote: "Uploaded Gather request(s) will retain this saved questionnaire until updated manually.",
  // Add Questionaire Modal

  AddModalHeader: "Create New Questionnaire",
  EditModalHeader: "Rename Questionnaire",
  AddModalConfirmButton: "Continue",
  EditModalConfirmButton: "Save",
  NameLabel: "Name",
  NamePlaceholder: "Enter questionnaire name",
  NameValidationMessage: "Please enter questionnaire name",
  DescriptionValidationMessage: "Please enter questionnaire description",
  TemplateNameMaxLength: 100,
  TemplateDescriptionMaxLength: 100,
  NameMaxLength: 150,
  DesciptionLabel: "Description",
  DesciptionPlaceholder: "Enter questionnaire description",
  DesciptionMaxLength: 500,
  EditSuccessMessage: "Your changes are saved successfully", //TO confirm
  AddSuccessMessage: "Template added successfully", //TO confirm
  SetDefaultTemplateSuccess: "Default template set successfully",
  UnsetDefaultTemplateSuccess: "Default template selection removed successfully",

  //Add Section Modal
  AddSectionModalHeader: "Create New Section",
  EditSectionModalHeader: "Rename Section",
  MergeSectionModalHeader: "Merge Section",
  ReorderSections: "Reorder Sections",
  AddSectionModalConfirmButton: "Continue",
  EditSectionModalConfirmButton: "Save",
  SectionMerge: "Merge",
  SectionNameLabel: "Name",
  SectionNamePlaceholder: "Enter section name",
  SectionNameValidationMessage: "Please enter section name",
  SectionNameMaxLength: 100
};

export const FormPreviewConstants = {
  RequiredValidation: "This field is required",
  ParagraphTypeQuestion: {
    placeholder: "Answer goes here",
    maxLength: 3250
  },
  MultiCoiceTypeQuestion: {
    optionAlreadyExists: "Option already exists"
  }
};

export const CustomQuestionsTabConstants = {
  ChooseTemplate: "Choose Template",
  SelectTemplate: "Select Template",
  NoTemplate: "No Template Selected",
  NoQuestionnaiers: "No questionnaire yet?",
  GetStarted: "Let’s get started!",
  DeleteQuestionTitle: "Delete question and section",
  DeleteSectionTitle: "Section contains questions",
  DeleteQuestionConfrimation:
    "If you delete the last question within the section, the section will be removed. Do you wish to continue?",
  DeleteSectionConfirmation:
    "There are questions added to the section you are trying to delete. Continuing with this action will remove the section and question(s) under the section. Do you want to continue?",
  AtleastOneSectionRequired: "Template requires at least one section",
  AtleastOneQuestionRequired: "Section requires at least one question"
};

export const CustomQuestionConstants = {
  DeleteSectionTitle: "Confirm delete section",
  DeleteSectionMessage: "Deleting the section will remove all questions created under the section. Do you wish to continue?",
  OnSaveTitle: "Changes not saved",
  OnSaveMessage: "Any changes made to Custom questions will be lost on navigating without saving.Do you wish to continue?",
  PromptMessage: "Any changes made to Custom questions will be lost on navigating without saving. Do you wish to continue?"
};

export const HelpTextConstant = {
  ClientSignature: "Drag and drop to place client's signature",
  SignatureDate: "Drag and drop to place date of signature",
  Initial: "Drag and drop to place Initials control",
  Name: "Drag and drop to place Name control",
  Title: "Drag and drop to place Title control",
  Email: "Drag and drop to place Email Address control",
  CustomQuestion: "Drag and drop to place Custom Question control",
  Text: "Drag and drop to place Text control",
  Checkbox: "Drag and drop to place Checkbox control",
  RadioButton: "Drag and drop to place Radio Button control"
};

export const SignatureControlConstants = {
  ControlIdPrefix: "control_"
};

export const SignaturePanelConstants = {
  Signature: {
    header: "Signature Settings",
    title: "To require this signature, please select the checkbox below.",
    tooltipContent: ""
  },
  Default: {
    header: "Tool Tip",
    title: "Add Text",
    tooltipContent: "This text will help the signer to enter the necessary details"
  },
  CheckBoxControl: {
    header: "Checkbox properties",
    title: "How many checkbox(es) would you like to add?",
    tooltipContent: "Mention the count of checkbox(es) to be added. All checkboxes will be selectable by Taxpayer."
  },
  RadioButtonControl: {
    header: "Radio button properties",
    title: "How many radio button(s) would you like to add?",
    tooltipContent: "Mention the count of radio button(s) to be added. For one set Taxpayer can select only one option."
  }
};

export const PdfViewerConstant = {
  Recipient: "Recipient(s)",
  SignatureFields: "Signature Fields",
  DragandDrop: "Drag & drop to place signature",
  EROFields: "ERO Fields",
  EroSignatureStamp: "ERO Signature Stamp",
};

export const ClientTrackingEvents = {
  InitialEmail: "Initial Email ",
  ReminderEmail: "Organizer Reminder Email Sent"
};

export const OrganizerClientIdConstants = {
  ClientIdMaxLength: 150,
  ClientIdLengthForEllipsis: 47
};

export const OrganizerClientConstants = {
  MaxLength: 200
}

export const LocationMismatchModalConstants = {
  SubmitButton: "Continue",
  Content: {
    Line1: "By changing Office Location to",
    Line2: ` {0} `,
    Line3: "you will no longer have access to it as you are not assigned to that office.",
    Line4: "Press Continue to change office location or Cancel to keep the current office location."
  }
};

export const ReviewClientInfoModalConstants = {
  headerMessage:
    "You have changed some information that differs from Client Management. Would you like us to update Client Management with this new data ?",
  title: "Review and Confirm Client Information",
  cancelButtonText: "No, Do Not Update",
  saveButtonText: "Yes, Update"
};

export const ClientInfoConstants = {
  emailUpdatedConfirmation:
    "You have updated the email address on file, would you like to resend the Gather to the new email address?"
};

export const BatchClientInfoConstants = {
  MaxLength: 150
}

export const Upload = {
  BatchCsvTemplateFileName: 'Batch_CSV_Template.csv'
}

export const TemplateOptionsConstant = {
  SignDocuments: "Sign Documents",
  AskCustomQuestions: "Ask Custom Questions",
  SendFillableOrganizer: "Send Fillable Organizer",
  RequestDocuments: "Request Documents"
}

export const OrganizerFilterConstants = {
  FilterNameAlreadyExists: "Filter name already exists.",
  FilterNameIsNotValid: "Filter name should not contain special characters: # / ? \\",
  FilterFieldsAreEmpty: "Filter fields are Empty! Please provide at least one.",
  PleaseEnterFilterName: "Please enter filter name",
  FilterDoesNotExists: "Filter doesn't exists.",
  DeleteFilter: "Are you sure you want to delete this filter?",
  DefaultFilterDelete: "Default filter cannot be deleted.",
  Title: {
    FilterSaveUpdate: "Save/Update Filter",
  },
  OtherMessage: {
    DoYouWantToCreateNewFilterOrUpdateTheCurrent: "Do you want to create New Filter or Update the Current?"
  },
  ControlLabel: {
    YesCreateNew: "Yes, Create New",
    NoUpdateTheCurrent: "No, Update The Current"
  }
}

export const InLineActionConstants = {
  Menu: {
    UnlockGather: "Unlock Gather Request",
    ResendAccessLink: "Resend Access Link",
    ClientView: "Client View",
    ClientTracking: "Client Tracking",
    SendReminder: "Send Reminder",
    ViewAccessCode: "View Access Code",
    ReopenOrganizer: "Reopen Organizer",
    ReportAProblem: "Report a Problem",
    ReopenByFirm: "Reopen by Firm",
    CloseByFirm: "Close by Firm",
    RestoreGather: "Restore Gather",
    EditClientInfo: "Edit Client Info",
  },
  Delete: "Delete",
  Download: "Download",
  NotAvailableForClosed: "Not available while gather is closed",
  ReopenSourceDocUpload: "Reopen Source Document Upload",
  DocumentUploadDisabled: "Document upload disabled. Contact System Admin.",
};

export const MessageSettingsConstants = {
  DeleteMessage: "Delete Message",
  AddMessage: "Add Message",
  EditMessage: "Edit Message",
}

export const EngagementTypeSettingsConstants = {
  DeleteMessage: "Delete Engagement Type",
  AddMessage: "Add Engagement Type",
  EditMessage: "Edit Engagement Type",
}

export const UploadOrganizerConstants = {
  EncryptedFileError: "is an encrypted document and cannot be used. Please remove encryption and try again"
}

export const ModalClosePopupConstants = {
  Heading: "Confirm Close",
  CloseProformaPrompt: "Your changes will not be saved. Are you sure \u00A0 you want to close the Gather?",
  CloseBatchPrompt: "Your changes will not be saved. Are you sure \u00A0 you want to close the Batch?"
}


export const SignControls = {
  DisabledText: "This control is currently not supported for use on Engagement Letters."
}
export const PDFLeftPanelConstants = {
  SignatureRequired: "Require signature from client",
  SourceDocumentEnabled: "Require source document",
  Delete: "Delete",
  DisabledDeleteTitle: "Page requires signature",
  DeleteMessage:"Are you sure you want to move this file to deleted?"
}

export const BatchUploadNotifierConstants = {
  BatchUploadError: "cannot be uploaded since the size is greater than 1 GB",
  BatchEncryptedFileError: "is an encrypted document and cannot be used. Please remove encryption and try again",
  BatchUploadSuccess:"Batch uploaded successfully.",
  BatchUploadCSVWarning:"Please upload CSV file",
}

export const RequestDocsHoverText="Click for detailed view"