import {utc} from "moment";
import { FC, useEffect, useRef, useState } from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { CustomModal, GenericModalProps } from "../../UIComponents/CustomModal";
import { formatBytes, isExceedingOneDay } from "helper/HelperFunctions";
import { FileZipIcon, InfoIcon } from "assets/svg/SVGIconsCollection";
import { DeleteIcon, DownloadHeaderIcon } from "assets/icons/IconCollection";
import { clearAll, deleteZip, downloadAll, downloadZip, getMyDownloads } from "store/services/my-download-service";
import { useAppDispatch, useAppSelector } from "common/hooks/redux-hooks";
import { NO_DATA_TEXT } from "helper/Constants";
import { DownloadStatus, IDownloadedZipFilesModel, IMyDownloadRequest } from "common/model/download/downloaded-zip-files";
import { MyDownloadConstant } from "./my-download-constant";
import "./style.scss";
import { GetEngagementLetterStatus, GetOrganizerStatus, GetSourceDocumentStatus } from "common/model/GatherDocumentModel";
import { IDeliveredGatherStoreState } from "common/model/delivered-gather-table";
import { requestDeliveredGather } from "store/services/delivered-gather-service";
import { AppState } from "store";

export const MyDownload: FC<GenericModalProps> = (props) => {
  const [myDownloadState, setMyDownloadState] = useState<IDownloadedZipFilesModel[]>([]);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [isRefreshRequired, setIsRefreshRequired] = useState<boolean>(false);
  const filterButtonRef = useRef<HTMLSpanElement>(null);

  const deliveredGatherData: IDeliveredGatherStoreState = useAppSelector((state: AppState) => state.deliveredGatherReducer);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (props.show && !isLoaded) {
      fetchData();
    }
  });

  const fetchData = () => {
    dispatch(
      getMyDownloads((data) => {
        setMyDownloadState(data);
        setIsLoaded(true);
      })
    );
  };

  const onDownload = (jobId: any, fileName: string) => {
    var request: IMyDownloadRequest = {
      jobId: jobId,
      fileName: fileName
    };
    dispatch(downloadZip(request));
    setIsRefreshRequired(true);
  };

  const onClearAll = () => {
    dispatch(clearAll(fetchData));
    setIsRefreshRequired(true);
  };

  const onDownloadAll = () => {
    let activeMyDownloads = myDownloadState.filter((ele) => ele.status !== DownloadStatus.InProgress);
    if (activeMyDownloads.length > 0) {
      dispatch(downloadAll());
      setIsRefreshRequired(true);
    }
  };

  const onDelete = (jobId: any) => {
    dispatch(deleteZip(jobId, false, fetchData));
    setIsRefreshRequired(true);
  };

  const handleClose = () => {
    setMyDownloadState([]);
    setIsLoaded(false);
    setIsRefreshRequired(false);
    props.onHide();
    if (isRefreshRequired && deliveredGatherData) {
      dispatch(requestDeliveredGather(
        deliveredGatherData.query,
        deliveredGatherData.page,
        deliveredGatherData.pageSize));
    }
  };

  const parseEngagementStatus = (status: string): string => {
    let statuses: string[] = [];
    status.split(",").map((item) => {
      statuses.push(GetEngagementLetterStatus(Number(item)));
    });
    return statuses.join(", ");
  };

  const parseSourceDocumentStatus = (status: string): string => {
    let statuses: string[] = [];
    status.split(",").map((item) => {
      statuses.push(GetSourceDocumentStatus(Number(item)));
    });
    return statuses.join(", ");
  };

  const parseOrganizerStatus = (status: string): string => {
    let statuses: string[] = [];
    status.split(",").map((item) => {
      statuses.push(GetOrganizerStatus(Number(item)));
    });
    return statuses.join(", ");
  };

  const formatDate = (dateString: string): string => {
    let date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const isValidKey = (value: any): boolean => {
    const valuesToCheck = [null, undefined, "", 0];
    return !valuesToCheck.includes(value);
  };

  const getFilterPopup = (filter: any) => {
    return (
      <Popover id="popover-to-delete-confirm" style={{ fontSize: "14px", width: "auto" }}>
        {isValidKey(filter) ? (
          <>
            <Popover.Header as="h4" style={{ fontSize: "16px", padding: "8px 16px" }}>
              Applied Filters
            </Popover.Header>
            <Popover.Body>
              <ul style={{ marginRight: "1rem", paddingRight: "0px" }}>
                {isValidKey(filter.taxPayerName) && (
                  <li style={{ fontWeight: "bold" }} key={0}>
                    TaxPayer Name - <span style={{ fontWeight: "normal" }}>{filter.taxPayerName}</span>
                  </li>
                )}
                {isValidKey(filter.clientId) && (
                  <li style={{ fontWeight: "bold" }} key={1}>
                    Client Id - <span style={{ fontWeight: "normal" }}>{filter.clientId}</span>
                  </li>
                )}
                {isValidKey(filter.ero) && (
                  <li style={{ fontWeight: "bold" }} key={2}>
                    ERO/Signer - <span style={{ fontWeight: "normal" }}>{filter.ero}</span>
                  </li>
                )}
                {isValidKey(filter.sentBy) && (
                  <li style={{ fontWeight: "bold" }} key={3}>
                    Sent By - <span style={{ fontWeight: "normal" }}>{filter.sentBy}</span>
                  </li>
                )}
                {isValidKey(filter.deliveredOn) && (
                  <li style={{ fontWeight: "bold" }} key={4}>
                    Delivered Date - <span style={{ fontWeight: "normal" }}>{formatDate(filter.deliveredOn)}</span>
                  </li>
                )}
                {isValidKey(filter.organizerStatus) && (
                  <li style={{ fontWeight: "bold" }} key={5}>
                    Organizer Status -{" "}
                    <span style={{ fontWeight: "normal" }}>{parseOrganizerStatus(filter.organizerStatus)}</span>
                  </li>
                )}
                {isValidKey(filter.sourceDocStatus) && (
                  <li style={{ fontWeight: "bold" }} key={6}>
                    Source Document Status -{" "}
                    <span style={{ fontWeight: "normal" }}>{parseSourceDocumentStatus(filter.sourceDocStatus)}</span>
                  </li>
                )}
                {isValidKey(filter.engLetterStatus) && (
                  <li style={{ fontWeight: "bold" }} key={7}>
                    Engagement Letter Status -{" "}
                    <span style={{ fontWeight: "normal" }}>{parseEngagementStatus(filter.engLetterStatus)}</span>
                  </li>
                )}
                {isValidKey(filter.taxYear) && (
                  <li style={{ fontWeight: "bold" }} key={8}>
                    Tax Year - <span style={{ fontWeight: "normal" }}>{filter.taxYear}</span>
                  </li>
                )}
                {isValidKey(filter.batchName) && (
                  <li style={{ fontWeight: "bold" }} key={9}>
                    Batch Name - <span style={{ fontWeight: "normal" }}>{filter.batchName}</span>
                  </li>
                )}
                {isValidKey(filter.isArchived) && (
                  <li style={{ fontWeight: "bold" }} key={10}>
                    Is Archived - <span style={{ fontWeight: "normal" }}>{filter.isArchived ? "Yes" : "No"}</span>
                  </li>
                )}
                {isValidKey(filter.archivedBy) && (
                  <li style={{ fontWeight: "bold" }} key={10}>
                    Archived By - <span style={{ fontWeight: "normal" }}>{filter.archivedBy}</span>
                  </li>
                )}
                {isValidKey(filter.documentStatus) && (
                  <li style={{ fontWeight: "bold" }} key={12}>
                    Document Status - <span style={{ fontWeight: "normal" }}>{filter.documentStatus}</span>
                  </li>
                )}
                {isValidKey(filter.engagementType) && (
                  <li style={{ fontWeight: "bold" }} key={13}>
                    Engagement Type - <span style={{ fontWeight: "normal" }}>{filter.engagementType}</span>
                  </li>
                )}
                {isValidKey(filter.organizerReminder) && (
                  <li style={{ fontWeight: "bold" }} key={14}>
                    Organizer Reminder - <span style={{ fontWeight: "normal" }}>{filter.organizerReminder}</span>
                  </li>
                )}
                {isValidKey(filter.signingReminder) && (
                  <li style={{ fontWeight: "bold" }} key={15}>
                    Signing Reminder - <span style={{ fontWeight: "normal" }}>{filter.signingReminder}</span>
                  </li>
                )}
                {isValidKey(filter.taxpayerEmail) && (
                  <li style={{ fontWeight: "bold" }} key={16}>
                    Taxpayer Email - <span style={{ fontWeight: "normal" }}>{filter.taxpayerEmail}</span>
                  </li>
                )}
                {isValidKey(filter.spouseEmail) && (
                  <li style={{ fontWeight: "bold" }} key={17}>
                    Spouse Email - <span style={{ fontWeight: "normal" }}>{filter.spouseEmail}</span>
                  </li>
                )}
              </ul>
            </Popover.Body>
          </>
        ) : (
          <Popover.Header as="h4" style={{ fontSize: "16px", padding: "8px 16px" }}>
            No filters applied
          </Popover.Header>
        )}
      </Popover>
    );
  };

  const isDownloadAllowed = (item: IDownloadedZipFilesModel) => {
    return (
      (item.status.toString() === DownloadStatus[DownloadStatus.Ready] ||
        item.status.toString() === DownloadStatus[DownloadStatus.PartialReady]) &&
      item.fileSize > 0
    );
  };

  const isDeleteAllowed = (item: IDownloadedZipFilesModel) => {
    return (
      isDownloadAllowed(item) ||
      item.status.toString() === DownloadStatus[DownloadStatus.Error] ||
      isExceedingOneDay(new Date(item.createdOn))
    );
  };

  const isDownloadInProgress = (item: IDownloadedZipFilesModel) => {
    return item.status.toString() === DownloadStatus[DownloadStatus.InProgress];
  };

  const downloadTitle = (item: IDownloadedZipFilesModel) => {
    if( item.status.toString() === DownloadStatus[DownloadStatus.InProgress])
    {
      return isDownloadInProgress(item)
      ? MyDownloadConstant.Download.DownloadInProgress
      : isDownloadAllowed(item)
        ? MyDownloadConstant.Download.Download
        : MyDownloadConstant.Download.DownloadNotAllowed;
    }
    else{
      return item.message !==null ? item.message 
        : MyDownloadConstant.Download.Failed
    }
    

  };

  const deleteTitle = (item: IDownloadedZipFilesModel) => {
    return isDeleteAllowed(item)
      ? MyDownloadConstant.Delete.Delete
      : isDownloadInProgress(item)
        ? MyDownloadConstant.Delete.DeleteNotAllowedWhileDownloadInProgress
        : MyDownloadConstant.Delete.DeleteNotAllowed;
  };

  const isDownloadAllAllowed = myDownloadState.find((item) => isDownloadAllowed(item)) !== undefined;
  const isDeleteAllAllowed = myDownloadState.find((item) => isDeleteAllowed(item)) !== undefined;

  return (
    <CustomModal
      title="My Downloads"
      show={props.show}
      onHide={handleClose}
      className="header-my-download"
      confirmButtonName="Download All"
      cancelButtonName={myDownloadState?.length === 0 ? "Close" : "Clear All"}
      onCancelButtonClick={myDownloadState?.length === 0 ? handleClose : onClearAll}
      cancelDisabled={myDownloadState?.length > 0 && !isDeleteAllAllowed}
      confirmDisabled={myDownloadState?.length > 0 && !isDownloadAllAllowed}
      hideConfirmButton={myDownloadState?.length == 0}
      removeBodyPadding={true}
      onSubmit={onDownloadAll}
      customSize="600"
      isConfirmModal
    >
      {myDownloadState.length > 0 ? (
        myDownloadState?.map((item, index) => {
          return (
            <div key={item.id} className="download-row-wrapper">
              <div className="file-details">
                <FileZipIcon />
                <div className="file-details-wrapper">
                  <div className="file-details-name">
                    <span className="ellipsis" title={item.fileName}>
                      {item.fileName}
                    </span>
                    <OverlayTrigger placement={"right-start"} overlay={getFilterPopup(item.requestFilters)} trigger={"hover"}>
                      <span ref={filterButtonRef}>
                        <InfoIcon color="#898D91" />
                      </span>
                    </OverlayTrigger>
                  </div>
                  <div className="file-details-date-time">
                    <span>{utc(item.createdOn).local().format("MM/DD/YYYY HH:mm A")}</span>
                    <span>- {formatBytes(item.fileSize)}</span>
                  </div>
                </div>
              </div>
              <div className="actions-wrapper">
                <span
                  title={downloadTitle(item)}
                  className={!isDownloadAllowed(item) ? "inline-disabled" : ""}
                  onClick={() => isDownloadAllowed(item) && onDownload(item.jobId, item.fileName)}
                >
                  <DownloadHeaderIcon disabled={!isDownloadAllowed(item)} />
                </span>
                <span
                  title={deleteTitle(item)}
                  className={!isDeleteAllowed(item) ? "inline-disabled" : ""}
                  onClick={() => isDeleteAllowed(item) && onDelete(item.jobId)}
                >
                  <DeleteIcon disabled={!isDeleteAllowed(item)} />
                </span>
              </div>
            </div>
          );
        })
      ) : (
        <p className="no-data-text">{NO_DATA_TEXT}</p>
      )}
    </CustomModal>
  );
};
export default MyDownload;
