import { FC, useEffect, useState } from "react";
import {IUserLoginHistory} from "common/model/login-history";
import { useAppDispatch, useAppSelector } from "common/hooks/redux-hooks";
import { getLoginHistory, updateLastLoginHistory } from "store/services/login-history-service";
import { Button, Col, Modal, Row,Form } from "react-bootstrap";
import { getBrowserDetails, getFormattedLocalDateTime } from "helper/HelperFunctions";
import { IUserDefaultSettings } from "common/model/userSetting";
import { AppState } from "store";
import { getDefaultSettingsData } from "store/slices/userSetting-slice";
import { DateFormatConstants, LoginHistoryConstants } from "helper/Constants";

interface ILastLoginProps {
    show: boolean;
    onHide: React.Dispatch<React.SetStateAction<boolean>>;
    defaultSettings: IUserDefaultSettings;
  }

  const LastLoginModal: FC<ILastLoginProps> = ({show,onHide,defaultSettings}) => {
    const [enablePrompt,setEnablePrompt] = useState(false);
    const [lastLogin,setLastLogin] = useState<IUserLoginHistory>();
    const loginHistory = useAppSelector((state:AppState)=>state.loginHistoryReducer.loginHistory);
    const deviceId = useAppSelector((state:AppState)=>state.loginHistoryReducer.deviceId);
    const dispatch = useAppDispatch();
    const handleCancel = () => {
        onHide(false);
    }

    const handleToggleChange = (value: boolean) => {
  
        const updatedSettings = {
            ...defaultSettings,
            enableLastLogin: enablePrompt
        }
        setEnablePrompt(value);
        dispatch(
            updateLastLoginHistory(updatedSettings, () => {
                dispatch(getDefaultSettingsData(updatedSettings));
            })
          );
        

    }

    useEffect(()=>{
        dispatch(getLoginHistory(1,15));
    },[]);

    useEffect(()=>{
        //get the loginhistories
        if(loginHistory?.length > 1){
            loginHistory[0].deviceId === deviceId 
            ? setLastLogin(loginHistory[1])
            : setLastLogin(loginHistory[0]);
        }
    },[loginHistory])

    return (
        <>
        <Modal className="last-login" show={show && (lastLogin ? true : false)}
        onHide={handleCancel}
        >
            <Modal.Header closeButton closeVariant="white">
                Last Login
            </Modal.Header>
            <Modal.Body>
                <div>
                    <Row className="mb-3">
                        <Col sm={3}><b>{LoginHistoryConstants.LogInOn}</b></Col>
                        <Col>{getFormattedLocalDateTime(lastLogin?.loggedInOn ?? "",DateFormatConstants.LAST_LOGIN_DATE_FORMAT)}</Col>
                    </Row>
                    <Row className="mb-3">
                        <Col sm={3}><b>{LoginHistoryConstants.IPaddress}</b></Col>
                        <Col>{lastLogin?.ipAddress}</Col>
                    </Row>
                    <Row>
                        <Col sm={3}><b>{LoginHistoryConstants.Browser}</b></Col>
                        <Col>{lastLogin && getBrowserDetails(lastLogin?.clientApp)}</Col>
                    </Row>
                </div>
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-between">
                    <Form.Check
                            onChange={() => handleToggleChange(!enablePrompt)}
                            checked={enablePrompt}
                            type="checkbox"
                            id="last_login"
                            label={LoginHistoryConstants.LastLoginNote}
                        />
                   
                <Button className='login-history-close'  onClick={handleCancel}>
                {LoginHistoryConstants.LastLoginClose}
                </Button>
            </Modal.Footer>
        </Modal>
        </>
    )

  };

  export default LastLoginModal;