import { IMyDownloadAllResponse } from "common/model/download/downloaded-zip-files";

export class DisplayDownloadFile {

    public showFile = (blob: Blob, fileName: string) => {
        // It is necessary to create a new blob object with mime-type explicitly set
        // otherwise only Chrome works like it should
        var newBlob = new Blob([blob], { type: blob.type })

        // IE doesn't allow using a blob object directly as link href
        // instead it is necessary to use msSaveOrOpenBlob
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(newBlob, fileName);
            return;
        }

        // For other browsers: 
        // Create a link pointing to the ObjectURL containing the blob.
        const data = window.URL.createObjectURL(newBlob);
        var link = document.createElement('a');
        link.href = data;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        setTimeout(function () {
            // For Firefox it is necessary to delay revoking the ObjectURL
            window.URL.revokeObjectURL(data);
            document.body.removeChild(link);
        }, 100);
    }

    public directDownload = (url: string, fileName?: string) => {
        var link = document.createElement('a');
        link.href = url;
        if (fileName) {
            link.download = fileName;
        }
        document.body.appendChild(link);
        link.click();
        setTimeout(function () {
            document.body.removeChild(link);
        }, 100);
    }

    public directsasDownloadsa = (url: string, fileName?: string) => {
        const link = document.createElement('a');

        // Set the href to the provided SAS URL
        link.href = url;
    
        // Set the download attribute to the provided file name, or fall back to a default
        link.download = fileName || 'downloaded-file';
    
        // Append the link to the body (some browsers require this)
        document.body.appendChild(link);
    
        // Trigger the download by programmatically clicking the link
        link.click();
    
        // Remove the link from the DOM after triggering the download
        document.body.removeChild(link);
    }

    public directBulkDownload = async (filesToDownload: IMyDownloadAllResponse[]) => {
        for (const file of filesToDownload) {
            const response = await fetch(file.uri);
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = file.fileName || 'download';
            document.body.appendChild(a);
            a.click();
            setTimeout(() => {
                document.body.removeChild(a);
                window.URL.revokeObjectURL(url);
            }, 100);
        }
    }
}