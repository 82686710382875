import { ITemplateList } from "common/model/custom-questions";
import { generateRandomNumber } from "helper/HelperFunctions";
//TODO This model will be replaced with actual API response
// import { actionTypes as customQuestionsActionTypes } from "src/store/settings/CustomQuestions/ActionTypes";

// import { generateRandomNumber } from "src/components/helper/HelperFunctions";

export enum TemplateType {
  DefaultTemplate,
  NoTemplate,
  SavedTemplate
}
export enum QuestionType {
  PARAGRAPH = 1,
  YESNO,
  MULTICHOICE
}
export const HasCustomQuestion: Readonly<Record<TemplateType, any>> = {
  [TemplateType.DefaultTemplate]: null,
  [TemplateType.NoTemplate]: false,
  [TemplateType.SavedTemplate]: true
};

export interface ICustomQuestionsState {
  isLoading: boolean;
  customQuestions: ICustomQuestion[];
  questionnaireTemplates: ITemplateList[];
  selectedTemplate: ISelectedTemplate | null;
}

export interface ICustomQuestion {
  id: number;
  name: string;
  description: string;
  isDefault: boolean;
  sections?: ISections[];
}
export interface ITemplate {
  templateId: number;
  name: string;
  description: string;
}
export interface ISection {
  sectionId: number;
  sectionName: string;
  order: number;
  isDeleted: false;
  sectionItems: ISectionItem[];
}
export interface ISectionItem {
  SectionItemId: number;
  questionTitle: string;
  questionTypeId: number;
  order: number;
  isRequired: boolean;
  isDeleted: boolean;
  questionDetails: {
    answer: string;
  };
}
export interface ICustomQuestionTemplate {
  formId: number;
  template: ITemplate;
  title: string;
  description: string;
  isDeleted: boolean;
  sections?: ISections[];
}
export interface ISelectedTemplate {
  formId?: number;
  template?: ITemplate;
  title: string;
  label: string;
  value: number;
  description: string;
  isDefault?: boolean;
  isDeleted?: boolean;
  sections: ISection[];
}

export const intialSectionData: ISections = {
  id: 0,
  sectionId: 0,
  order: 1,
  sectionName: "",
  sectionItems: []
};
export const customQuestionasInitialState: ICustomQuestion = {
  id: 0,
  name: "",
  description: "",
  isDefault: false,
  sections: [intialSectionData]
};
export const customQuestionsInitialState: ICustomQuestionsState = {
  isLoading: false,
  customQuestions: [customQuestionasInitialState]
};



// export interface RequestCustomQuestions {
//     type: customQuestionsActionTypes.REQUEST_CUSTOM_QUESTIONS;
// }

// export interface ReceivedCustomQuestions {
//     type: customQuestionsActionTypes.RECEIVED_CUSTOM_QUESTIONS;
//     customQuestions: ICustomQuestionsState;
// }
// export interface CompletedCustomQuestions {
//     type: customQuestionsActionTypes.COMPLETED_CUSTOM_QUESTIONS;
// }

// export interface RequestCustomQuestion {
//     type: customQuestionsActionTypes.REQUEST_CUSTOM_QUESTION;
// }

// export interface ReceivedCustomQuestion {
//     type: customQuestionsActionTypes.RECEIVED_CUSTOM_QUESTION;
// }
// export interface CompletedCustomQuestion {
//     type: customQuestionsActionTypes.COMPLETED_CUSTOM_QUESTION;
// }

// export interface RequestUpdateCustomQuestion {
//     type: customQuestionsActionTypes.REQUEST_UPDATE_CUSTOM_QUESTION;
// }

// export interface ReceivedUpdateCustomQuestion {
//     type: customQuestionsActionTypes.RECEIVED_UPDATE_CUSTOM_QUESTION;
//     customQuestion: ICustomQuestion;
// }
// export interface CompletedUpdateCustomQuestion {
//     type: customQuestionsActionTypes.COMPLETED_UPDATE_CUSTOM_QUESTION;
// }

// export interface RequestDeleteCustomQuestion {
//     type: customQuestionsActionTypes.REQUEST_DELETE_CUSTOM_QUSETION;
// }

// export interface ReceivedDeleteCustomQuestion {
//     type: customQuestionsActionTypes.RECEIVED_DELETE_CUSTOM_QUSETION;
// }
// export interface CompletedDeleteCustomQuestion {
//     type: customQuestionsActionTypes.COMPLETED_DELETE_CUSTOM_QUSETION;
// }

// export interface RequestSetDefaultCustomQuestion {
//     type: customQuestionsActionTypes.REQUEST_SET_DEFAULT_CUSTOM_QUESTION;
// }

// export interface ReceivedSetDefaultCustomQuestion {
//     type: customQuestionsActionTypes.RECEIVED_SET_DEFAULT_CUSTOM_QUESTION;
// }
// export interface CompletedSetDefaultCustomQuestion {
//     type: customQuestionsActionTypes.COMPLETED_SET_DEFAULT_CUSTOM_QUESTION;
// }

// export interface RequestGetTemplateInUse {
//     type: customQuestionsActionTypes.REQUEST_GET_TEMPLATE_IN_USE;
// }

// export interface ReceivedGetTemplateInUse {
//     type: customQuestionsActionTypes.RECEIVED_GET_TEMPLATE_IN_USE;
// }
// export interface CompletedGetTemplateInUse {
//     type: customQuestionsActionTypes.COMPLETED_GET_TEMPLATE_IN_USE;
// }

export interface IQuestionnaireState {
  isLoading: boolean;
  formData: IFormData;
  customQuestionSettings: ICustomQuestionSettings;
}
export interface IFormData {
  formId: number;
  template: ITemplateModalData;
  title: string;
  isTitleValid?: boolean;
  description: string;
  isDescriptionValid?: boolean;
  sections: ISections[];
  isDeleted?: boolean;
}

export interface ISections {
  id: number;
  sectionId: number;
  sectionName: string;
  order: number;
  sectionItems: ISectionItems[];
  isDeleted?: boolean;
}
export interface IChoiceModel {
  id: number;
  text: string;
  isSelected?: boolean;
  isDeleted: boolean;
  isValid?: boolean;
  isDuplicate?: boolean;
  subQuestions: ISectionItems[];
}
export const initialChoiceData: IChoiceModel = {
  id: 0,
  text: "",
  isValid: true,
  isSelected: false,
  isDeleted: false,
  isDuplicate: false,
  subQuestions: []
};

export interface ISectionItems {
  id: string;
  SectionItemId: number;
  order: number;
  questionTitle: string;
  isRequired: boolean;
  isDeleted: boolean;
  questionTypeId: QuestionType;
  questionDetails: IParagraphQuestion;
  choices: IChoiceModel[];
  isValid?: boolean;
  hasFollowUpQuestion?: boolean;
  isDuplicate?: boolean;
  chosen?: boolean;
  selected?: boolean;
}
export const intialSectionItemData: ISectionItems = {
  id: "",
  SectionItemId: 0,
  order: 0,
  isDeleted: false,
  questionTitle: "",
  isRequired: false,
  isValid: true,
  isDuplicate: false,
  questionTypeId: QuestionType.PARAGRAPH,
  questionDetails: {
    answer: ""
  },
  choices: []
};
export interface IParagraphQuestion {
  answer: string;
}

export interface Option {
  label: string;
  isSelected: boolean;
  isDefault: boolean;
}



export interface ITemplateModalData {
  templateId: number;
  name: string;
  description: string;
}

export interface IParagraphQuestion {
  answer: string;
}

export interface Option {
  label: string;
  isSelected: boolean;
  isDefault: boolean;
}

export enum FollowUpQuestionIdentifer {
  YES = 0,
  NO = 1
}

export const intialTemplateModalData: ITemplateModalData = {
  templateId: 0,
  name: "",
  description: ""
};

export const intialFormData: IFormData = {
  formId: 0,
  template: intialTemplateModalData,
  title: "",
  isTitleValid: true,
  description: "",
  isDescriptionValid: true,
  sections: []
};
export const intialCustomQuestionSettings: ICustomQuestionSettings = {
  templateLimit: 0,
  questionLimit: 0,
  choiceLimit: 0,
  additionalQuestionLimit: 0
};
export const intialQuestionnaireState: IQuestionnaireState = {
  isLoading: false,
  formData: intialFormData,
  customQuestionSettings: intialCustomQuestionSettings
};





export function GetAdditionalQuestion(questionType: QuestionType): ISectionItems {
  return {
    id: "",
    SectionItemId: 0,
    order: 0,
    isDeleted: false,
    questionTitle: "",
    isRequired: false,
    isValid: true,
    isDuplicate: false,
    questionTypeId: questionType,
    questionDetails: {
      answer: ""
    },
    choices: []
  };
}

export interface ICustomQuestionSettings {
  templateLimit: number;
  questionLimit: number;
  choiceLimit: number;
  additionalQuestionLimit: number;
}


export const initialMCQOptions: IChoiceModel[] = [initialChoiceData, { ...initialChoiceData, id: generateRandomNumber ? generateRandomNumber() : 0 }];

export const initialYesNoChoiceData: IChoiceModel[] = [
  { id: 0, text: "Yes", isDeleted: false, subQuestions: [] },
  { id: 0, text: "No", isDeleted: false, subQuestions: [] }
];

export interface IOnChangeHandlerPayload extends IItemIdentifier {
  value: string;
}
export interface ITitleOnChangeHandlerPayload {
  value: string;
}

export interface IItemIdentifier {
  sectionIndex: number;
  sectionItemIndex: number;
}
export interface IQuestionReOrderPayload {
  sectionIndex: number;
  sectionItems: ISectionItems[];
}
export interface IDupicateQuestionPayload extends IItemIdentifier {
  sectionItem: ISectionItems;
}
export interface IDupicateSectionPayload extends IItemIdentifier {
  section: ISections;
}
export interface IHandleQuestionTypeChangePayload extends IItemIdentifier {
  questionType: QuestionType;
}
export interface IHandleSubQuestionTypeChangePayload extends IHandleChoiceIdentifierPayload {
  questionType: QuestionType;
  subQuestionIndex: number;
}
export interface IHandleChoiceTextOnChangePayload extends IHandleChoiceIdentifierPayload {
  choiceText: string;
}

export interface ISubQuestionChoiceIdentifierPayload extends IHandleChoiceIdentifierPayload {
  subQuestionChoiceIndex: number;
  questionIndex: number;
}
export interface ISubQuestionChoiceTextOnChangePayload extends ISubQuestionChoiceIdentifierPayload {
  choiceText: string;
}
export interface ISubQuestionTitlePayload extends IHandleChoiceIdentifierPayload {
  value: string;
  questionIndex: number;
}
export interface IHandleChoiceIdentifierPayload extends IItemIdentifier {
  choiceIndex: number;
}
export interface IHandleAddChoiceIdentifierPayload extends IHandleChoiceIdentifierPayload {
  questionIndex: number;
}
export interface IAddSubQuestionPayload extends IHandleChoiceIdentifierPayload {
  questionType: number;
}
export interface IRemoveSubQuestionPayload extends IHandleChoiceIdentifierPayload {
  questionIndex: number;
}
export interface IHandleResetSubQuestionsPayload extends IItemIdentifier {
  isChecked: boolean;
}
