import React, { FC, useEffect, useState, memo } from "react";
import {
  PaginationForwordDoubleArrow,
  PaginationBackDoubleArrow,
  PaginationForwardSingalArrow,
  PaginationBackSingalArrow
} from "assets/icons/IconCollection";
import { generateUUID } from "helper/HelperFunctions";

export type PaginationPostion = "left" | "right";

interface PaginationProps {
  totalCount: number;
  pageIndex: number;
  pageSize: number;
  defaultPageSize: number;
  goToPage: (pageIndex: number, pageSize: number) => void;

  showpageCount: boolean;
  pageCountRightPosition?: boolean;

  showSizePerPageList?: boolean;
  showGoToPages?: boolean;
  sizePerPageList: number[];

  ShowListAndGoto: boolean;
  paginationPositition?: PaginationPostion;
}

const Pagination: FC<PaginationProps> = ({
  showSizePerPageList = false,
  showGoToPages = false,
  pageCountRightPosition = false,
  paginationPositition = null,
  ...props
}) => {
  const { pageSize, pageIndex, totalCount, sizePerPageList, defaultPageSize, showpageCount, ShowListAndGoto } = props;
  const totalPages = Math.ceil(totalCount / pageSize);
  const pageFirstRecordNo: number = totalCount == 0 ? 0 : (pageIndex - 1) * pageSize + 1;
  const pageLastRecordNo: number =
    totalCount == 0
      ? 0
      : totalCount / pageSize > pageIndex || totalCount % pageSize == 0
      ? pageIndex * pageSize
      : pageIndex * pageSize - (pageSize - (totalCount % pageSize));
  const [pageIndexText, setPageIndexText] = useState(pageIndex.toString());

  const handlePageSizeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    props.goToPage(1, Number(e.target.value));
  };
  const handlePageIndexChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const pattern = new RegExp(/^[0-9]*$/);
    if (pattern.test(e.target.value)) {
      setPageIndexText(e.target.value);
    }
  };
  const handlePageIndexEnter = (e: any) => {
    const newPageIndexString = e.target.value;
    if (parseInt(newPageIndexString) > 0 && parseInt(newPageIndexString) <= totalPages) {
      if (e.key === "Enter" && newPageIndexString.trim().length > 0 && pageIndex !== Number(newPageIndexString)) {
        props.goToPage(Number(newPageIndexString), pageSize);
      }
    }
  };

  const handlePaginationChanging = (pageNo: number) => {
    if (pageNo > 0 && pageNo <= totalPages && pageNo != pageIndex) {
      props.goToPage(pageNo, pageSize);
    }
  };

  useEffect(() => {
    setPageIndexText(pageIndex.toString());
  }, [pageIndex, pageSize]);

  return (
    <>
      <section className="tablePagination">
        {showpageCount ? (
          <div>
            <p style={{ marginBottom: "0px" }}>
              Showing {pageFirstRecordNo} to {pageLastRecordNo} of {totalCount} entries
            </p>
          </div>
        ) : null}

        <ul className="showPageNumber" style={{ flexDirection: pageCountRightPosition ? "row-reverse" : "row" }}>
          <li
            data-test-auto="2041a90f-9278-4cca-8c9c-90587bdc772f"
            title={pageIndex <= 1 ? "" : "First Page"}
            className={pageIndex <= 1 ? "paginationActionDisabled" : ""}
            onClick={() => handlePaginationChanging(1)}
            style={{ borderRadius: "3.2px 0px 0px 3.2px" }}
          >
            <PaginationBackDoubleArrow />
          </li>
          <li
            data-test-auto="f93b0ea7-960b-4433-8505-cf1683c2788d"
            title={pageIndex <= 1 ? "" : "Previous Page"}
            className={pageIndex <= 1 ? "paginationActionDisabled" : ""}
            onClick={() => handlePaginationChanging(pageIndex - 1)}
          >
            <PaginationBackSingalArrow />
          </li>
          <li title={pageIndex.toString()} className="currentPageNumber">
            {pageIndex.toString()}
          </li>
          <li
            data-test-auto="443fcf5c-d506-47b7-9fa3-4c68272339ff"
            title={pageIndex >= totalPages ? "" : "Next Page"}
            className={pageIndex >= totalPages ? "paginationActionDisabled" : ""}
            onClick={() => handlePaginationChanging(pageIndex + 1)}
          >
            <PaginationForwardSingalArrow />
          </li>
          <li
            data-test-auto="0db14ef5-0142-4abd-997d-363c06a70709"
            title={pageIndex >= totalPages ? "" : "Last Page"}
            className={pageIndex >= totalPages ? "paginationActionDisabled" : ""}
            onClick={() => handlePaginationChanging(totalPages)}
            style={{ borderRadius: "0px 3.2px 3.2px 0px" }}
          >
            <PaginationForwordDoubleArrow />
          </li>
        </ul>
        {ShowListAndGoto ? (
          <div className="configurePagination">
            {showSizePerPageList ? (
              <select
                name="pageSize"
                id="pageSize"
                className={`pageSize${totalCount <= defaultPageSize ? " paginationActionDisabled" : ""}`}
                disabled={totalCount <= defaultPageSize}
                value={pageSize.toString()}
                onChange={handlePageSizeChange}
              >
                {sizePerPageList.map((pageSize,index) => (
                  <option 
                  key={generateUUID()}
                  value={pageSize}
                  >{pageSize} / page</option>
                ))}
              </select>
            ) : null}

            {showGoToPages ? (
              <>
                <span style={{ color: "black", padding: "0px 8px 0px 8px" }}>&nbsp;Go&nbsp;to&nbsp;</span>
                <input
                  type="text"
                  name="goToPage"
                  id="goToPage"
                  autoComplete="off"
                  className={`goToPage${totalCount <= defaultPageSize ? " paginationActionDisabled" : ""}`}
                  maxLength={totalPages.toString().length}
                  value={pageIndexText}
                  onChange={handlePageIndexChange}
                  onKeyDown={handlePageIndexEnter}
                  disabled={totalCount <= defaultPageSize}
                />
                <span style={{ color: "black" }}>
                  &nbsp;of&nbsp;
                  <span className="totalNumberOfPages">{totalPages.toString()}</span> &nbsp;pages
                </span>
              </>
            ) : null}
          </div>
        ) : null}
      </section>
    </>
  );
};

export default memo(Pagination);
