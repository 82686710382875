import * as React from "react";
import { Col, Row } from "react-bootstrap";
import Template from "./Template";

import {
  ClearFormData,
  SaveTemplateModalData,
  SetIsInvalidFlag,
  setCustomQuestion,
  setCustomQuestionSettings
} from "store/slices/form-builder";
import { useAppDispatch, useAppSelector } from "common/hooks/redux-hooks";
import { ModalType, TemplateModal } from "./form-builder/Modals/TemplateModal";
import FormBuilder from "./form-builder/FormBuilder";
import Preview from "./preview/Preview";
import { getTemplateLimitErrorMessage, getTotalQuestions } from "helper/HelperFunctions";
import { QuestionType, customQuestionasInitialState } from "store/models/CustomQuestionModel";
import { CreateTemplateIcon } from "assets/svg/SVGIconsCollection";
import { IntialiseCqPreview } from "store/slices/custom-questions-preview";
import { FormBuilderConstants, PageLoaderKeys, TemplateModalConstants } from "helper/Constants";
import "./CustomQuestionsTemplateList.css";
import { cqSettingsDummy, customQuestionDummy, customQuestionsDummy } from "helper/dummy";
import { saveCustomQuestions } from "store/slices/custom-question-templates";
import { AppState } from "store";
import { ICustomQuestion } from "common/model/custom-questions";
import { deleteCustomQuestiontemplate, editCustomQuestiontemplate, getCustomQuestions, getCustomQuestionsSetting, getQuestionerForm, getTemplateInUse, setDefaultCustomQuestiontemplate } from "store/services/custom-questions-service";
import { customQuestionsInitialState, ICustomQuestionsState } from "store/models/CustomQuestionModel";
import { react } from "@babel/types";
import { AppNotifier } from "common/components/toast/Toast";
import ConfirmDialog from "common/components/modal/ConfirmDialog";
import LoaderWrapper from "common/pages/LoaderWrapper";
type CustomQuestionsProps = {
  // templates: any;
};

type TemplateData = {
  modalType: number;
  modalData: any;
  showModal: boolean;
};
type TemplateDeleteData = {
  isDefault: boolean;
  showModal: boolean;
  id: Number
};

const CustomQuestionsTemplateList: React.FC<CustomQuestionsProps> = (props) => {
  const dispatch = useAppDispatch();
  const [templateModal, setTemplateModal] = React.useState<TemplateData>({
    modalType: ModalType.Template,
    modalData: null,
    showModal: false
  });
  const [customQuestionsTemplateList, setCustomQuestionsTemplateList] = React.useState<ICustomQuestion[]>([]);
  const [showFormBuilder, setShowFormBuilder] = React.useState<boolean>(false);
  const [showPreview, setShowPreview] = React.useState<boolean>(false);
  const [isCreateTemplateForm, setIsCreateTemplateForm] = React.useState<boolean>(true);
  const [isRedirectedFromPreviewTab, setIsRedirectedFromPreviewTab] = React.useState<boolean>(false);
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = React.useState<any>({ show: false, templateId: 0 });
  const [templateDeleteModal, setTemplateDeleteModal] = React.useState<TemplateDeleteData>({
    showModal: false,
    isDefault: false,
    id: 0
  });

  let customQuestions: any = customQuestionsDummy;

  const formData = useAppSelector((state: AppState) => state.formBuilderReducer.formData);
  const customQuestionSettings = useAppSelector((state: AppState) => state.formBuilderReducer.customQuestionSettings);
  const customQuestionsTemplateModel = useAppSelector((state: AppState) => state.customQuestionTemplatesReducer.customQuestions);


  React.useEffect(() => {
    dispatch(getCustomQuestions(true));
    dispatch(getCustomQuestionsSetting(true));

    return () => {
      localStorage.removeItem("updatedFormData");
      localStorage.removeItem("formData");
    };
  }, []);
  React.useEffect(() => { setInitial() }, [customQuestionsTemplateModel])
  const setInitial = () => {

    if (customQuestionsTemplateModel !== undefined) {
      setCustomQuestionsTemplateList(customQuestionsTemplateModel)
    }
  };
  const onSetDefaultTemplateCallback = () => { dispatch(getCustomQuestions()); };
  const setDefaultTemplate = (selectedTemplateId: number, isDefault: boolean) => {
    dispatch(setDefaultCustomQuestiontemplate(selectedTemplateId, isDefault, onSetDefaultTemplateCallback))
  };

  const onTemplateClick = (data: ICustomQuestion) => {
    dispatch(getQuestionerForm(data.id));

    onEditCallback();
  };

  const onMenuItemClick = (template, index) => {
    switch (index) {
      case 0:
        onTemplateClick(template);
        dispatch(ClearFormData());
        break;
      case 1:
        setTemplateModal({ modalType: ModalType.Questionaire, modalData: { ...template }, showModal: true });
        break;
      case 2:
        setTemplateDeleteModal({ ...templateDeleteModal, showModal: true, isDefault: template.isDefault, id: template.id });

        break;
      default:
        break;
    }
  };
  const onConfirmDeleteSection = () => {
    setTemplateDeleteModal({ ...templateDeleteModal });
    onDelete();
    onHide();
  };

  const deleteTemplate = (templateId: number) => {
  };
  const getHasTemplateIsInUseDetailsCallback = () => {
    setShowDeleteConfirmModal({ show: true, templateId: templateDeleteModal.id });
    setTemplateDeleteModal({ ...templateDeleteModal });
  };
  const onHide = () => {
    setShowDeleteConfirmModal(false);
    onDeleteTemplateModalClose();
  };
  const onEditCallback = () => {
    setIsCreateTemplateForm(false);
    setShowFormBuilder(true);
  };
  const onFailureCallback = () => {

  };
  const redirectToTemplateListing = () => {
    setShowFormBuilder(false);
    setIsCreateTemplateForm(true);

  };

  const onDeleteCallback = () => { dispatch(getCustomQuestions()); };

  const onRenameCallback = () => {
    dispatch(getCustomQuestions());
  };
  const onConfirmClick = (data: any) => {
    if (templateModal.modalData) {
      let modalData: any = templateModal.modalData;
      modalData.name = data.name;
      modalData.description = data.description || "";
      dispatch(editCustomQuestiontemplate(modalData, onRenameCallback))
      setTemplateModal({ modalType: ModalType.Template, modalData: null, showModal: false });
    } else {
      setTemplateModal({ modalType: ModalType.Questionaire, modalData: null, showModal: false });
      setShowFormBuilder(true);
      let templateData: any = data;
      templateData.name = data.name;
      templateData.description = data.description || "";
      dispatch(SaveTemplateModalData(templateData));
    }
  };
  const onModalClose = (initialModalData: any) => {
    setTemplateModal({
      modalType: ModalType.Template,
      modalData: initialModalData ? { name: "", description: "" } : null,
      showModal: false
    });
  };
  const onDeleteTemplateModalClose = () => {
    setTemplateDeleteModal({
      isDefault: false,
      showModal: false,
      id: 0
    });
  };

  const onDelete = () => {
    dispatch(deleteCustomQuestiontemplate(templateDeleteModal.id, onDeleteCallback));
  };

  const onDeleteTemplateModalConfirm = () => {
    dispatch(getTemplateInUse(templateDeleteModal.id, (response: any) => {
      if (response === true) {
        getHasTemplateIsInUseDetailsCallback();
      } else {
        onHide();
        onDelete();
      }
    }));
  };

  const onCreateNewClick = () => {
    if (customQuestionsTemplateList.length < customQuestionSettings.templateLimit) {
      dispatch(ClearFormData());
      setTemplateModal({ modalType: ModalType.Questionaire, modalData: null, showModal: true });
      setIsCreateTemplateForm(true);
    } else {
      AppNotifier.Error(getTemplateLimitErrorMessage(customQuestionSettings.templateLimit))
    }
  };

  const onClickPreview = () => {
    if (getTotalQuestions(formData) > 0) {
      if (isFormDataValid()) {
        setShowPreview(true);
        dispatch(IntialiseCqPreview(formData));
      }
    }
  };
  const onClickPreviewClose = () => {
    setShowPreview(false);
    setIsRedirectedFromPreviewTab(true);
  };
  const resetIsComingFromPreviewTab = () => {
    setIsRedirectedFromPreviewTab(false);
  };
  const isFormDataValid = () => {
    let isValid = true;
    let hasDuplicateChoice = false;
    if (formData.title === "" || formData.description === "") {
      isValid = false;
    }
    formData.sections.forEach((section) =>
      section.sectionItems.forEach((sectionItem) => {
        if (sectionItem.questionTitle === "") {
          isValid = false;
        }
      })
    );
    formData.sections.forEach((section) => {
      section.sectionItems.forEach((sectionItem) => {
        let choiceTexts: string[] = [];
        sectionItem.choices.forEach((choice) => {
          if (choice.text === "") {
            isValid = false;
          } else {
            let choiceText = choice.text.toLowerCase();
            if (choiceTexts.includes(choiceText)) {
              hasDuplicateChoice = true;
            }
            choiceTexts.push(choiceText);
          }
        });
      });
    });
    formData.sections.forEach((section) => {
      section.sectionItems.forEach((sectionItem) => {
        sectionItem.choices.forEach((choice) => {
          if (choice.subQuestions && choice.subQuestions.length > 0) {
            choice.subQuestions.forEach((subQuestion) => {
              if (subQuestion.questionTitle === "") {
                isValid = false;
              }
            });
          }
        });
      });
    });
    formData.sections.forEach((section) => {
      section.sectionItems.forEach((sectionItem) => {
        sectionItem.choices.forEach((choice) => {
          if (choice.subQuestions && choice.subQuestions.length > 0) {
            choice.subQuestions.forEach((subQuestion) => {
              if (subQuestion.questionTypeId === QuestionType.MULTICHOICE) {
                let choiceTexts: string[] = [];
                subQuestion.choices.forEach((subQuestionChoice) => {
                  if (subQuestionChoice.text === "") {
                    isValid = false;
                  } else {
                    let subQuestionChoiceText = subQuestionChoice.text.toLowerCase();
                    if (choiceTexts.includes(subQuestionChoiceText)) {
                      hasDuplicateChoice = true;
                    }
                    choiceTexts.push(subQuestionChoiceText);
                  }
                });
              }
            });
          }
        });
      });
    });
    if (!isValid || hasDuplicateChoice) {
      dispatch(SetIsInvalidFlag());
      AppNotifier.Error(FormBuilderConstants.FORM_VALIDATION_ERROR)
    }

    return isValid && !hasDuplicateChoice;
  };
  return (
    <>
      {!showFormBuilder ? (
        <div>
          <LoaderWrapper keys={PageLoaderKeys.CustomQuestions} isScrollableView>
            <div>
              <Row style={{ marginTop: "5px" }}>
                <Col>
                  <div className="custom-questions-page-title">
                    <h3 className="questionHeading">{TemplateModalConstants.RecentQuestionnaires}</h3>
                    <hr className="horizontal-divider" />
                  </div>
                </Col>
              </Row>
            </div>
            <div className="templates-list">
              {customQuestionsTemplateList?.map((template: ICustomQuestion) => {
                return (
                  <Template
                    key={template?.id}
                    data={template}
                    onTemplateClick={onTemplateClick}
                    onMenuClick={onMenuItemClick}
                    setDefaultTemplate={setDefaultTemplate}
                  />
                );
              })}
              <div className="template-container">
                <div onClick={onCreateNewClick} className="create-new-card-container template">
                  <CreateTemplateIcon />
                  <span>Create New Template</span>
                </div>
                <div className="template-details">
                  <label className="template-name">{TemplateModalConstants.StartFromScratch}</label>
                  <label className="template-description">
                    {TemplateModalConstants.BuildNewQuestionnaire} <br />
                    {TemplateModalConstants.WithYourCustomQuestions}
                  </label>
                </div>
              </div>
            </div>
          </LoaderWrapper>
        </div>
      ) : !showPreview ? (
        <FormBuilder
          onClickPreview={onClickPreview}
          redirectToTemplateListing={redirectToTemplateListing}
          isCreateTemplateForm={isCreateTemplateForm}
          isRedirectedFromPreviewTab={isRedirectedFromPreviewTab}
          resetIsRedirectedFromPreviewTab={resetIsComingFromPreviewTab}
        />
      ) : (
        <Preview onClickPreviewClose={onClickPreviewClose} />
      )}
      {templateModal.showModal && (
        <TemplateModal
          modalType={templateModal.modalType}
          intialModalData={templateModal.modalData}
          show={templateModal.showModal}
          onConfirm={onConfirmClick}
          onClose={onModalClose}
        />
      )}
      {showDeleteConfirmModal.show && (
        <ConfirmDialog
          title={TemplateModalConstants.DeleteTemplateInUse}
          show={showDeleteConfirmModal.show}
          onHide={onHide}
          onSubmit={onConfirmDeleteSection}
          message={TemplateModalConstants.DeleteConfirmationForTemplateInUse}
          note={TemplateModalConstants.DeleteConfirmationNote}
        />
      )}
      {templateDeleteModal.showModal && (
        <ConfirmDialog
          title={TemplateModalConstants.DeleteTitle}
          message={
            templateDeleteModal.isDefault
              ? TemplateModalConstants.DeleteDefaultTemplateConfirmation
              : TemplateModalConstants.DeleteConfirmation
          }
          show={templateDeleteModal.showModal}
          onHide={onDeleteTemplateModalClose}
          onSubmit={onDeleteTemplateModalConfirm}
        />
      )}
    </>
  );
};
export default CustomQuestionsTemplateList;
