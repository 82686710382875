import { resetCookie } from 'helper/HelperFunctions';
import { createUserManager, createUserManagerWithRedirect } from './helpers'
import { store } from 'store';
import { resetUserCache, updateLogoutDate } from 'store/services/auth-service';

export var userManager = createUserManager()

export const signinRedirect = () => userManager.signinRedirect()

export const signinRedirectWithCallbackArgs = (args: string) => {
    if (args) {
        const redirectUri = userManager.settings.redirect_uri + "?callback_args=" + encodeURIComponent(args)
        userManager = createUserManagerWithRedirect(redirectUri);
    }
    userManager.signinRedirect()
}

export const signinRedirectCallback = () => userManager.signinRedirectCallback()

export async function signoutRedirect() {
    await resetUserCache();
    resetCookie('userAutoLogout', '0');
    updateLogoutDate(()=>{
        userManager.signoutRedirect({
            id_token_hint: store.getState().authReducer?.authentication?.user?.id_token,
            post_logout_redirect_uri: window.location.origin + "/signout-callback-oidc",
        });
    });

}

export const signoutRedirectCallback = () => userManager.signoutRedirectCallback()

export async function getAccessToken() {
    const user = store.getState()?.authReducer?.authentication?.user
    return user?.access_token;
}
