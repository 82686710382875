import { IDocumentRequestState } from "common/model/request-documents";
import { AppNotifier } from "common/components/toast/Toast";
import { IGatherDRLName, UpdateDRLMappingPayload, UpdateDRLNotApplicableStatusPayload } from "common/model/delivered-gather-table";
import { RequestNotification } from "helper/Constants";
import { batch } from "react-redux";
import { Action } from "redux";
import { ThunkAction } from "redux-thunk";
import { API } from "services/api/api-config";
import Axios from "services/api/axios-interceptor";
import { AppState } from "store";
import { setIsLoading, setImportedDocumentRequest, setDocumentRequest, setIsTemplateSelected, setImportedTemplateList, setDocumentRequestCategory } from "store/slices/documentRequest-slice";
import { startLoader, stopLoader } from "store/slices/delivered-gather-slice";

export const getGatherImportedList = (gatherId: number, isCacheable?: boolean): ThunkAction<void, AppState, unknown, Action> =>
    (dispatch) => {

        dispatch(setIsLoading(true));
        Axios.get(`${API.GATHER.DOCUMENTREQUEST.GET_IMPORTED_LIST}/gather/${gatherId}`, {
            method: 'GET',
            credentials: 'include',
            'X-Cacheable': isCacheable,
        })
            .then(response => response.data)
            .then((reponse: any) => {
                dispatch(setImportedDocumentRequest(reponse));
            }).catch(() => {
                AppNotifier.Error(RequestNotification.Failure.AllGathers)
            }).finally(() => {
                dispatch(setIsLoading(false));
            })

    }

export const getBatchImportedList = (batchId: number, isCacheable?: boolean): ThunkAction<void, AppState, unknown, Action> =>
    (dispatch) => {

        dispatch(setIsLoading(true));
        Axios.get(`${API.GATHER.DOCUMENTREQUEST.GET_IMPORTED_LIST}/batch/${batchId}`, {
            method: 'GET',
            credentials: 'include',
            'X-Cacheable': isCacheable,
        })
            .then(response => response.data)
            .then((reponse: any) => {
                dispatch(setImportedDocumentRequest(reponse));
            }).catch(() => {
                AppNotifier.Error(RequestNotification.Failure.AllGathers)
            }).finally(() => {
                dispatch(setIsLoading(false));
            })

    }

export const getGatherDocumentRequestList = (gatherId: number, isCacheable?: boolean): ThunkAction<void, AppState, unknown, Action> =>
    (dispatch) => {

        dispatch(setIsLoading(true));
        Axios.get(`${API.GATHER.DOCUMENTREQUEST.LIST}/gather/${gatherId}`, {
            method: 'GET',
            credentials: 'include',
            'X-Cacheable': isCacheable,
        })
            .then(response => response.data)
            .then((response: any) => {
                dispatch(setDocumentRequest(response));
            }).catch(() => {
                AppNotifier.Error(RequestNotification.Failure.AllGathers)
            }).finally(() => {
                dispatch(setIsLoading(false));
            })

    }

export const getBatchDocumentRequestList = (batchId: number, isCacheable?: boolean): ThunkAction<void, AppState, unknown, Action> =>
    (dispatch) => {

        dispatch(setIsLoading(true));
        Axios.get(`${API.GATHER.DOCUMENTREQUEST.LIST}/batch/${batchId}`, {
            method: 'GET',
            credentials: 'include',
            'X-Cacheable': isCacheable,
        })
            .then(response => response.data)
            .then((response: any) => {
                dispatch(setDocumentRequest(response));
            }).catch(() => {
                AppNotifier.Error(RequestNotification.Failure.AllGathers)
            }).finally(() => {
                dispatch(setIsLoading(false));
            })
    }

export const toggleIsTemplateSelected = (isChecked: boolean): ThunkAction<void, AppState, unknown, Action> =>
    (dispatch) => {
        try {
            dispatch(setIsTemplateSelected(isChecked))
        }
        catch (error: any) {
            dispatch(setIsLoading(false));
        }
    }

export const getImportedTemplateList = (templateId: number,
    successCallback: (templateId: number, data?: any) => void, isCacheable?: boolean): ThunkAction<void, AppState, unknown, Action> =>
    (dispatch) => {
        try {
            dispatch(setIsLoading(true));
            Axios.get(`${API.GATHER.DOCUMENTREQUEST.LIST}/imported-document-request/${templateId}`,
                { 'X-Cacheable': isCacheable, })
                .then(response => response.data)
                .then((response: any) => {
                    successCallback(templateId, response);
                })
        }
        catch (error: any) {
            dispatch(setIsLoading(false));
        }
    }

export const SaveOrUpdateBatchDocumentRequestList = (batchId: number, isDelivered: boolean, isPreviewGather: boolean, documentRequestState: IDocumentRequestState, successCallback: () => void, failedCallback: () => void): ThunkAction<void, AppState, unknown, Action> =>
    () => {
        try {
            Axios.post(`${API.GATHER.DOCUMENTREQUEST.LIST}/imported-document-request/${batchId}/${isDelivered}/${isPreviewGather}`, documentRequestState)
                .then((response) => {
                    successCallback();
                })
        }
        catch (error: any) {
            failedCallback();
        }
    }

export const requestGatherDRLNames = (gatherId: number, successCallback?: (data: IGatherDRLName[]) => void, isCacheable?: boolean): ThunkAction<void, AppState, unknown, Action> => {
    return (dispatch) => {
        dispatch(startLoader());
        Axios.get(`${API.GATHER.DOCUMENTREQUEST.GET_DRL_NAMES}/${gatherId}`,
            { 'X-Cacheable': isCacheable }
        )
            .then((response) => response.data as IGatherDRLName[])
            .then((data) => {
                successCallback && successCallback(data);
                dispatch(stopLoader());
            }).catch((error) => {
                dispatch(stopLoader());
                AppNotifier.Error(RequestNotification.Failure.GatherDRLNames);
            });
    }
};

export const clearDocumentsRequestsList = (): ThunkAction<void, AppState, unknown, Action> =>
    (dispatch) => {
        try {
            dispatch(setDocumentRequest(null));
        }
        catch (error: any) {
        }
    }

export const getDoumentRequestSectionList = (isCacheable?:boolean): ThunkAction<void, AppState, unknown, Action> =>
    (dispatch) => {
        Axios.get(`${API.GATHER.DOCUMENTREQUEST.LIST}/category`,
            {'X-Cacheable': isCacheable}
        )
            .then(response => response.data)
            .then((response: any) => {
                dispatch(setDocumentRequestCategory(response))
            })
            .catch(err => {

            })
    }

export const updateDRLmapping = (payload: UpdateDRLMappingPayload[], gatherId: number, callback?: () => void): ThunkAction<void, AppState, unknown, Action> => {
    return (dispatch) => {
        dispatch(startLoader());
        Axios.post(`${API.GATHER.DOCUMENTREQUEST.UPDATE_DRL_MAPPING}/${gatherId}`, payload)
            .then((response) => {
                if (response.status === 200) {
                    dispatch(stopLoader());
                }
                callback && callback();
            }).catch((error) => {
                dispatch(stopLoader());
                AppNotifier.Error(RequestNotification.Failure.UpdateDRLMapping);
            });
    }
}

export const UpdateDRLNotApplicableStatus = (payload: UpdateDRLNotApplicableStatusPayload, gatherId: number, callback?: () => void): ThunkAction<void, AppState, unknown, Action> => {
    return (dispatch) => {
        dispatch(startLoader());
        Axios.post(`${API.GATHER.DOCUMENTREQUEST.UPDATE_DRL_NOT_APPLICABLE_STATUS}/${gatherId}`, payload)
            .then((response) => {
                if (response.status === 200) {
                    callback && callback();
                    dispatch(stopLoader());
                }
            }).catch((error) => {
                dispatch(stopLoader());
                AppNotifier.Error(RequestNotification.Failure.UpdateDRLNotApplicableStatus);
            });
    }
}