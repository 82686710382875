import React, { useEffect } from "react";
import { AddIcon } from "assets/icons/IconCollection";
import { MessageType } from "../../client-instructions";
import { EmailTemplateMessage } from "common/model/client-instruction";
import { DefaultSetting } from "common/model/company-settings";
import { MessageSettingsConstants } from "pages/Constants";

export interface IMessageListBoxProps {
  data: EmailTemplateMessage[];
  defaultSettings: DefaultSetting;
  onAddMessage: (messageType: MessageType) => void;
  onSelectMessage: (index: number, messageType: MessageType) => void;
  messageType: MessageType;
  selectedMessage: EmailTemplateMessage;
}

const MessageListBox: React.FC<IMessageListBoxProps> = ({
  data,
  defaultSettings,
  messageType,
  onAddMessage,
  onSelectMessage,
  selectedMessage
}) => {
  useEffect(() => {
    messagebox(data, defaultSettings);
  }, [data, defaultSettings]);
  const messagebox = (data: EmailTemplateMessage[], defaultSettings: DefaultSetting) => {

    const isActive = (id: number) => selectedMessage?.id === id;

    return data.map((message, index) => (
      <div
        onClick={() => onSelectMessage(index, messageType)}
        key={message.id}
        className={`message-wrapper ${isActive(message.id) ? "active-msg" : ""}`}
        title={message.name}
      >
        <p className="d-inline-block text-truncate">{message.name}</p>
        {renderDefault(message.id, defaultSettings)}
      </div>
    ));
  };
  const renderDefault = (id: number, defaultSettings: DefaultSetting) => {
    if (defaultSettings != undefined) {
      if (messageType === MessageType.CLIENT_INSTRUCTION) {
        return defaultSettings.initialEmail == id && <span>Default</span>;
      }
      if (messageType === MessageType.EL) {
        return defaultSettings.downloadEngagementLetterEmail == id && <span>Default</span>;
      }
    }
  };
  return (
    <div className="message-list-box">
      <div>{messagebox(data, defaultSettings)}</div>
      <button onClick={() => onAddMessage(messageType)} className="btn-with-icon" title={MessageSettingsConstants.AddMessage}>
        <AddIcon />
        Add
      </button>
    </div>
  );
};

export default MessageListBox;
