import { IColumnSetting } from "common/model/delivered-gather-table";

export const DaysRangeOptionsList = {
  One_Day: "24 Hours",
  Two_Days: "2 Days",
  Three_Days: "3 Days",
  Five_Days: "5 Days",
  Seven_Days: "1 Week",
  Fifteen_Days: "15 Days",
  Thirty_Days: "30 Days"
};

export const RequestDocumentOptions = {
  5: "5%",
  10: "10%",
  15: "15%",
  20: "20%",
  25: "25%",
  30: "30%",
  35: "35%",
  40: "40%",
  45: "45%",
  50: "50%",
  55: "55%",
  60: "60%",
  65: "65%",
  70: "70%",
  75: "75%",
  80: "80%",
  85: "85%",
  90: "90%",
  95: "95%",
  100: "100%",
};

export const RequestDocumentOptionsArray = Object.entries(RequestDocumentOptions).map(([key, value]) => ({
  value: parseInt(key),
  label: value
}));

export const initialColumnOrder: IColumnSetting[] = [
  {
    id: 1,
    columnName: "Client Name",
    key: "taxpayerName",
    isActive: true
  },
  {
    id: 2,
    columnName: "Client ID",
    key: "clientId",
    isActive: true
  },
  {
    id: 3,
    columnName: "Taxpayer Email",
    key: "taxpayerEmail",
    isActive: true
  },
  {
    id: 4,
    columnName: "Spouse Email",
    key: "spouseEmail",
    isActive: true
  },
  {
    id: 5,
    columnName: "Tax Year",
    key: "taxYear",
    isActive: true
  },
  {
    id: 6,
    columnName: "ERO / Signer",
    key: "ero",
    isActive: true
  },
  {
    id: 7,
    columnName: "Office Location",
    key: "locationName",
    isActive: true
  },
  {
    id: 8,
    columnName: "Batch Name",
    key: "batchName",
    isActive: true
  },
  {
    id: 9,
    columnName: "Archived By",
    key: "archivedBy",
    isActive: true
  },
  {
    id: 10,
    columnName: "Delivered Date",
    key: "deliveredOn",
    isActive: true
  },
  {
    id: 11,
    columnName: "Signature Status",
    key: "engagementLetterStatus",
    isActive: true
  },
  {
    id: 12,
    columnName: "Organizer Status",
    key: "organizerStatus",
    isActive: true
  },
  {
    id: 13,
    columnName: "Source Document Status",
    key: "sourceDocumentStatus",
    isActive: true
  },
  {
    id: 14,
    columnName: "Signing Reminder",
    key: "signingReminder",
    isActive: true
  },
  {
    id: 15,
    columnName: "Organizer Reminder",
    key: "organizerReminder",
    isActive: true
  },
  {
    id: 16,
    columnName: "Request Documents",
    key: "requestedDocs",
    isActive: true
  }
];
