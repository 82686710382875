import { GatherTemplateDummy } from "helper/dummy";
import React, { useEffect, useState } from "react";
import { Col, Row, Toast } from "react-bootstrap";
import { CreateTemplateIcon } from "assets/svg/SVGIconsCollection";
import { TemplateModalConstants } from "pages/Constants";
import Template from "./Templates";
import { GatherTemplateModal } from "./GatherTemplateModal";
import { useAppDispatch, useAppSelector } from "common/hooks/redux-hooks";
import {
  addGatherTemplate,
  checkAddStatus,
  deleteGatherTemplate,
  editGatherTemplate,
  getGatherTemplate
} from "store/services/gathertemplate-settings-service";
import { GatherTemplate, GatherTemplateListState } from "common/model/gather-template-settings";
import { initialTemplateList, setGatherTemplateData } from "store/slices/gathertemplate-settings-slice";
import { AppState } from "store";
import { getCustomQuestions } from "store/services/custom-questions-service";
import { getGatherImportedList } from "store/services/documentRequest-service";
import { toast } from "react-toastify";
import { AppNotifier } from "common/components/toast/Toast";
import { GatherTemplateNotification, PageLoaderKeys } from "helper/Constants";
import ConfirmDialog from "common/components/modal/ConfirmDialog";
import LoaderWrapper from "common/pages/LoaderWrapper";
export interface IGatherTemplateModalData {
  id: number;
  name: string;
  description?: string;
  signDocuments: boolean;
  askCustomQuestions: boolean;
  selectedCqTemplate?: number;
  selectedDocumentTemplate?: number;
  sendFillabelOrg: boolean;
  requestDocuments: boolean;
}
const intialData: GatherTemplate = {
  uploadTemplateId: 0,
  name: "",
  description: "",
  isDefault: false,
  customQuestionTemplateId: 0,
  requestDocumentsTemplateId: 0,
  processSteps: []
};
export enum ModalType {
  Add,
  Edit
}
const GatherTemplateList = () => {
  const [gatherTemplates, setTemplateList] = useState<Array<GatherTemplate>>([intialData]);
  const [showCreateModal, toggleCreateModal] = useState(false);
  const [showDeleteModal, toggleDeleteModal] = useState(false);
  const [selected, setSelected] = useState(0);
  const [intialModalData, setIntialModalData] = useState<GatherTemplate>(intialData);
  const customQuestionsTemplateModel = useAppSelector((state: AppState) => state.customQuestionTemplatesReducer.customQuestions);
  const gatherTemplateListState = useAppSelector<GatherTemplateListState>((state: AppState) => state.gatherTemplateReducer);
  const requestDocumentList = useAppSelector((state: AppState) => state.documentRequestReducer.importedTemplateModel);
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(getGatherTemplate(undefined, true));
    dispatch(getCustomQuestions(true));
    dispatch(getGatherImportedList(0, true));
  }, []);
  useEffect(() => {
    setInitial();
    dispatch(checkAddStatus());
  }, [gatherTemplateListState.templateList]);
  const onTemplateClick = (data: GatherTemplate) => {
    setIntialModalData(data);
    toggleCreateModal(true);
  };
  const onChangeCallback = () => {
    toggleDeleteModal(false);
    dispatch(getGatherTemplate());
  };
  const onCreateNewClick = () => {
    if (gatherTemplateListState.addStatus) {
      setIntialModalData(intialData);
      toggleCreateModal(true);
    }
    else {
      AppNotifier.Error(GatherTemplateNotification.Warning.TemplateLimitExceede)
    }

  };
  const onDeleteTemplate = (id: number) => {
    setSelected(id);
    toggleDeleteModal(true);
  };
  const deleteSubmit = () => {
    dispatch(deleteGatherTemplate(selected, onChangeCallback));
  };
  const onEditTemplate = (data: GatherTemplate) => {
    setIntialModalData(data);
    toggleCreateModal(true);
  };
  const onDuplicateTemplate = (data: GatherTemplate) => {
    const newName = getTemplateName(data.name);
    var newData = { ...data, name: newName };
    newData = { ...newData, uploadTemplateId: 0 };
    setIntialModalData(newData);
    toggleCreateModal(true);
  };
  const onHideCreateModal = () => {
    toggleCreateModal(false);
    setIntialModalData(intialData);
  };
  const getTemplateName = (name: string) => {
    if (name !== "") {
      return `${name}_copy`;
    }
    return name;
  };

  const onSubmit = async (madalData: GatherTemplate, type: ModalType) => {
    if (type === ModalType.Add) {
      if (gatherTemplateListState.addStatus) {
        dispatch(addGatherTemplate(madalData, onChangeCallback));
        onHideCreateModal();
      } else {
        AppNotifier.Warning(GatherTemplateNotification.Warning.TemplateLimitExceede);
      }
    } else {
      dispatch(editGatherTemplate(madalData, onChangeCallback));
      onHideCreateModal();
    }
  };
  const setInitial = () => {
    setTemplateList(gatherTemplateListState.templateList);
  };

  return (
      <div>
        <LoaderWrapper keys={PageLoaderKeys.Template} isScrollableView>
        <div>
          <Row style={{ marginTop: "5px" }}>
            <Col>
              <div className="custom-questions-page-title">
                <h3 className="questionHeading">Gather Template(s)</h3>
                <hr className="horizontal-divider" />
              </div>
            </Col>
          </Row>
        </div>
        <div className="templates-list">
          {gatherTemplates?.map((template: GatherTemplate) => {
            return (
              <Template
                key={template.uploadTemplateId}
                data={template}
                onTemplateClick={onTemplateClick}
                onDelete={onDeleteTemplate}
                onDuplicate={onDuplicateTemplate}
                onEdit={onEditTemplate}
              />
            );
          })}
          <div className="template-container">
            <div onClick={onCreateNewClick} className="create-new-card-container template">
              <CreateTemplateIcon />
              <span>Create New Template</span>
            </div>
            <div className="template-details">
              <label className="template-name">{TemplateModalConstants.StartFromScratch}</label>
              <label className="template-description">{TemplateModalConstants.BuildNewTemplate}</label>
            </div>
          </div>
        </div>
        </LoaderWrapper>
        {showCreateModal && (
          <GatherTemplateModal
            show={showCreateModal}
            onHide={onHideCreateModal}
            intialModalData={intialModalData}
            customQuestionsTemplateModel={customQuestionsTemplateModel}
            requestDocumentList={requestDocumentList}
            onSave={onSubmit}
          />
        )}
        <ConfirmDialog
          show={showDeleteModal}
          message="Are you sure want to delete the template ?"
          title="Permanent Delete"
          onHide={() => toggleDeleteModal(false)}
          onSubmit={deleteSubmit}
        />
      </div>
  );
};

export default GatherTemplateList;
