import { FC, useEffect, useState } from 'react';
import { Modal,Button } from "react-bootstrap";
import LoginHistoryTable from 'common/components/table/LoginHistoryTable';

import CustomToggleSwitch from 'common/components/switch/CustomToggleSwitch';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux-hooks';
import { getDefaultSettingData } from 'store/services/user-service';
import { AppState } from 'store';
import { updateLastLoginHistory } from 'store/services/login-history-service';
import { LoginHistoryConstants } from 'helper/Constants';

export interface IMyLoginHistoryModalProps {
    show: boolean;
    onHide: () => void;
}



const MyLoginHistoryModal: FC<IMyLoginHistoryModalProps> = ({show,onHide}) => {
    
    const dispatch = useAppDispatch();
    const defaultUserSettings = useAppSelector((state:AppState)=>state.userSettingReducer.defaultUserSettings);
    const [enablePrompt,setEnablePrompt] = useState(false);

    const handleToggleChange = (enable: boolean)=>{
        const updatedSettings = {
            ...defaultUserSettings,
            enableLastLogin: !enable
        };
        dispatch(
            updateLastLoginHistory(updatedSettings, () => {
            })
          );
        setEnablePrompt(!enable);
    }

   
    useEffect(()=>{
        dispatch(getDefaultSettingData());  
    },[])

    useEffect(() => {
        if (defaultUserSettings?.enableLastLogin === true) {
          setEnablePrompt(true);
        } else {
          setEnablePrompt(false);
        }
      }, [defaultUserSettings]);

    return (
        <Modal show={show} onHide={onHide} className="login-history-modal" >
            <Modal.Header className="login-history-header" closeButton closeVariant="white">{LoginHistoryConstants.LoginHistoryTitle}</Modal.Header>
            <Modal.Body className="login-history-body">
                <LoginHistoryTable />
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-between">
                <div className='d-flex align-items-center'>

                    <CustomToggleSwitch 
                    switched={enablePrompt}
                    handleChange={() =>handleToggleChange(enablePrompt)}
                                  
                    />
                    <span className="ms-2 mt-1 prompt">{LoginHistoryConstants.LastLoginPrompt}</span>
                </div>
                <Button className='login-history-modal-close' onClick={onHide}>{LoginHistoryConstants.LastLoginClose}</Button>
            </Modal.Footer>
        </Modal>

    );

};

export default MyLoginHistoryModal;