import { CustomModal } from "common/components/UIComponents/CustomModal";
import { RequiredFieldError, TemplateModalConstants } from "helper/Constants";
import React, { FC, useEffect, useRef, useState } from "react";
import { Form } from "react-bootstrap";

interface TemplateModalProps {
  show: boolean;
  onClose: (data: any) => void;
  onConfirm: (data: any) => void;
  intialModalData?: any;
  modalType?: ModalType;
}

export enum ModalType {
  Template = 1,
  Questionaire = 2
}

export const TemplateModal: FC<TemplateModalProps> = ({ show, onClose, onConfirm, intialModalData, modalType }) => {
  const ref = useRef<HTMLFormElement>(null);
  const [modalData, setModalData] = useState({ ...intialModalData });
  const [validated, setValidated] = useState<boolean>(false);

  useEffect(() => {
    setModalData(intialModalData);
  }, [intialModalData]);

  const onFormChange: React.ChangeEventHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;
    const modalDataCopy = { ...modalData, [id]: value.trimStart() };
    setModalData(modalDataCopy);
  };
  const handleOnSubmit = () => {
    const form = ref.current;
    if (!!form && form.checkValidity()) {
      setValidated(false);
      onConfirm(modalData);
      setModalData(null);
    } else {
      setValidated(true);
    }
  };

  const hideModalHandler = () => {
    onClose(intialModalData);
    setValidated(false);
    setModalData(null);
  };
  return (
    <CustomModal
      onSubmit={handleOnSubmit}
      confirmButtonName={
        intialModalData ? TemplateModalConstants.EditModalConfirmButton : TemplateModalConstants.AddModalConfirmButton
      }
      title={intialModalData ? TemplateModalConstants.EditModalHeader : TemplateModalConstants.AddModalHeader}
      className="template-modal-container"
      show={show}
      onHide={hideModalHandler}
    >
      <Form
        ref={ref}
        noValidate
        validated={validated}
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <span className="required-marker required-marker-before">Required</span>
        <Form.Group>
          <Form.Label className="required-marker required-marker-after">{TemplateModalConstants.NameLabel}</Form.Label>
          <Form.Control
            type="text"
            id="name"
            name="name"
            defaultValue={intialModalData?.name}
            value={modalData?.name}
            placeholder={
              modalType === ModalType.Template
                ? TemplateModalConstants.TemplateNamePlaceholder
                : TemplateModalConstants.NamePlaceholder
            }
            onChange={onFormChange}
            required
            maxLength={TemplateModalConstants.TemplateNameMaxLength}
          />
          <Form.Control.Feedback type="invalid">
            {RequiredFieldError}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group>
          <Form.Label>{TemplateModalConstants.DesciptionLabel}</Form.Label>
          <Form.Control
            type="text"
            as="textarea"
            id="description"
            name="description"
            defaultValue={intialModalData?.description}
            value={modalData?.description}
            placeholder={
              modalType === ModalType.Template
                ? TemplateModalConstants.TemplateDesciptionPlaceholder
                : TemplateModalConstants.DesciptionPlaceholder
            }
            onChange={onFormChange}
            maxLength={TemplateModalConstants.TemplateDescriptionMaxLength}
          />
        </Form.Group>
      </Form>
    </CustomModal>
  );
};
