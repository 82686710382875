import { RequiredFieldError, TemplateModalConstants } from "helper/Constants";
import { Form } from "react-bootstrap";
import { ISections, intialSectionData } from "store/models/CustomQuestionModel";
import { CustomModal } from "../UIComponents/CustomModal";
import { useRef, useState } from "react";

interface SectionModalProps {
  show: boolean;
  onClose: () => void;
  onConfirm: (sectionName: string, isEditModal: boolean) => void;
  selectedSection: ISections;
}

export const SectionModal: React.FC<SectionModalProps> = ({ show, onClose, onConfirm, selectedSection }) => {
  const ref = useRef<HTMLFormElement>(null);
  const [sectionName, setSectionName] = useState(selectedSection.sectionName);
  const [validated, setValidated] =  useState<boolean>(false);

  const isEditModal = selectedSection.sectionName !== "";

  const onFormChange: React.ChangeEventHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const input = e.target.value.trimStart();
    setSectionName(input);
  };

  const handleOnSubmit = () => {
    const form = ref.current;
    if (!!form && form.checkValidity()) {
      setValidated(false);
      onConfirm(sectionName, isEditModal);
    }
    setValidated(true);
  };
  const hideModalHandler = () => {
    onClose();
    setValidated(false);
    setSectionName(intialSectionData.sectionName);
  };
  return (
    <CustomModal
      onSubmit={handleOnSubmit}
      className="template-modal-container"
      show={show}
      onHide={hideModalHandler}
      title={isEditModal ? TemplateModalConstants.EditSectionModalHeader : TemplateModalConstants.AddSectionModalHeader}
      confirmButtonName={TemplateModalConstants.EditModalConfirmButton}
    >
      <span className="required-marker required-marker-before">Required</span>
      <Form
        ref={ref}
        noValidate
        validated={validated}
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <Form.Group>
          <Form.Label className="required-marker required-marker-after">{TemplateModalConstants.SectionNameLabel}</Form.Label>
          <Form.Control
            type="text"
            id="sectionName"
            value={sectionName}
            placeholder={TemplateModalConstants.SectionNamePlaceholder}
            onChange={onFormChange}
            required
            maxLength={TemplateModalConstants.SectionNameMaxLength}
          />
          <Form.Control.Feedback type="invalid">{RequiredFieldError}</Form.Control.Feedback>
        </Form.Group>
      </Form>
    </CustomModal>
  );
};
