import { Action } from "@reduxjs/toolkit";
import { HttpStatusCode } from "axios";
import { DisplayDownloadFile } from "common/components/common/DisplayDownloadFile";
import { AppNotifier } from "common/components/toast/Toast";
import { ClientType, DownloadType } from "common/enum/GatherEnums";
import { GatherFilterType, GatherTransaction, IClientAccessCodeDetail, IManualCompletionRequest } from "common/model/GatherDocumentModel";
import { DeliveredILinkedMergedDetails, DeliveredILinkedMergedDetailsResponse, IColumnSetting, IDeliveredGridGatherDocument, IGatherTableModel } from "common/model/delivered-gather-table";
import { IDownloadRequest } from "common/model/download/download-query-filters";
import { RequestNotification } from "helper/Constants";
import { ThunkAction } from "redux-thunk";
import { API } from "services/api/api-config";
import Axios from "services/api/axios-interceptor";
import { AppState } from "store";
import { handleBlobwithFileName } from "store/library";
import { IGatherClientDetail } from "store/models/GatherClientDetailsModel";
import { setDeliveredGatherData, setReportColumnSettings, startLoader, stopLoader } from "store/slices/delivered-gather-slice";
let companyAssignmentAbortController = new AbortController();

export const requestDeliveredGather = (
  query: string,
  page: number,
  pageSize: number,
  callback?: any,
  isCacheable?:boolean
): ThunkAction<void, AppState, unknown, Action> => {
  return (dispatch) => {
    dispatch(startLoader());
    companyAssignmentAbortController.abort();
    companyAssignmentAbortController = new AbortController();
    Axios.get(
      `${API.DELIVERED_GATHER.GET_DELIVERED_GATHER_DATA}${query}`,
      {'X-Cacheable': isCacheable},
      undefined
    )
      .then((response) => response.data as IGatherTableModel)
      .then((data) => {
        if (data.documents) {
          dispatch(
            setDeliveredGatherData({
              model: {
                ...data,
                documents: data.documents.map((document: IDeliveredGridGatherDocument, index) => ({
                  ...document,
                  rowIndex: index,
                  index: (page - 1) * pageSize + index,
                })),
              },
              query: query,
              page: page,
              pageSize: pageSize
            })
          );
        }

        if (callback) {
          callback();
        }

        dispatch(stopLoader());
      })
      .catch((error: any) => {
        if (error.name !== "CanceledError") {
          AppNotifier.Error(RequestNotification.Failure.AllGathers);
          dispatch(stopLoader());
        }
      });
  };
};

export const requestReportColumnSetting = (
  reportType: GatherFilterType,
  callback?: () => void,
  isCacheable?:boolean
): ThunkAction<void, AppState, unknown, Action> => {
  return (dispatch) => {
    Axios.get(`${API.DELIVERED_GATHER.REPORT_COLUMN_SETTING}/${reportType}`,
      {'X-Cacheable': isCacheable,})
      .then((response) => response.data as IColumnSetting[])
      .then((data) => {
        if (Object.keys(data).length === 0) {
          data = [];
        }
        dispatch(setReportColumnSettings(data));

        if (callback) {
          callback();
        }
      })
      .catch((error: any) => {
        dispatch(stopLoader());
        AppNotifier.Error(RequestNotification.Failure.AllGathers);
      });
  };
};

export const recycleDeliveredGather = (gatherIds: number[], callback?: any): ThunkAction<void, AppState, unknown, Action> => {
  return () => {
    Axios.post(`${API.DELIVERED_GATHER.RECYCLE_GATHER}`, gatherIds)
      .then((response) => {
        if (response.status === HttpStatusCode.Ok) {
          AppNotifier.Success(RequestNotification.Success.RecycleGathers);

          if (callback) {
            callback();
          }
        }
      })
      .catch(() => {
        AppNotifier.Error(RequestNotification.Failure.RecycleGathers);
      });
  };
};
export const UpdateStatusToManual = (
  manualCompletionRequest: IManualCompletionRequest,
  callback?: any
): ThunkAction<void, AppState, unknown, Action> => {
  return () => {
    try {
      Axios.post(`${API.DELIVERED_GATHER.UPDATE_MANUALLY}`, manualCompletionRequest).then((response) => {
        if (response.status === HttpStatusCode.Ok) {
          AppNotifier.Success(RequestNotification.Success.Changestatus);

          if (callback) {
            callback();
          }
        }
      });
    } catch (error: any) {
      AppNotifier.Error(RequestNotification.Failure.Changestatus);
    }
  };
};

export const archiveDeliveredGather = (gatherIds: number[], callback?: any): ThunkAction<void, AppState, unknown, Action> => {
  return () => {
    Axios.post(`${API.DELIVERED_GATHER.ARCHIVE_GATHER}`, gatherIds)
      .then((response) => {
        if (response.status === HttpStatusCode.Ok) {
          AppNotifier.Success(RequestNotification.Success.ArchiveGathers);

          if (callback) {
            callback();
          }
        }
      })
      .catch(() => {
        AppNotifier.Error(RequestNotification.Failure.AllGathers);
      });
  };
};
export const deliveredCloseOrReopenGather = (
  gatherIds: number[],
  callback?: any
): ThunkAction<void, AppState, unknown, Action> => {
  return (dispatch) => {
    try {
      Axios.put(`${API.DELIVERED_GATHER.CLOSE_REOPEN_GATHER}`, gatherIds, {
        method: "PUT",
        credentials: "include"
      })
        .then((response) => response)
        .then(() => {
          AppNotifier.Success(RequestNotification.Success.CloseOrReopen);

          if (callback) {
            callback();
          }
        });
    } catch (error: any) {
      AppNotifier.Error(RequestNotification.Failure.CloseOrReopen);
    }
  };
};

export const saveReportColumnSetting = (
  reportType: GatherFilterType,
  data: IColumnSetting[],
  callback?: () => void
): ThunkAction<void, AppState, unknown, Action> => {
  return (dispatch) => {
    Axios.post(`${API.DELIVERED_GATHER.REPORT_COLUMN_SETTING}/${reportType}`, data)
      .then((response) => response)
      .then((res) => {
        AppNotifier.Success(RequestNotification.Success.SaveColumnSetting);
        dispatch(setReportColumnSettings(data));
        if (callback) {
          callback();
        }
      })
      .catch((error: any) => {
        AppNotifier.Error(RequestNotification.Failure.SaveColumnSetting);
      });
  };
};

export const exportDelieveredGather = (query: string, callback?: any): ThunkAction<void, AppState, unknown, Action> => {
  return (dispatch) => {
    dispatch(startLoader());
    Axios.get(`${API.DELIVERED_GATHER.EXPORT_DELIVERED_GATHER_DATA}${query}`, undefined, true)
      .then((response) => handleBlobwithFileName(response))
      .then((data) => {
        let displayDownloadFile = new DisplayDownloadFile();
        displayDownloadFile.showFile(data.blob, data.filename);
        AppNotifier.Success(RequestNotification.Success.ExportDeliveredGather);

        if (callback) {
          callback();
        }
      })
      .catch((error: any) => {
        dispatch(stopLoader());
        AppNotifier.Error(RequestNotification.Failure.ExportDeliveredGather);
      });
  };
};

export const generateClientView = (
  gatherId: number,
  clientType: ClientType
): ThunkAction<void, AppState, unknown, Action> => {
  return (dispatch) => {
    try {
      Axios.get(`${API.DELIVERED_GATHER.Preview}/${gatherId}/client-view/${clientType}`)
        .then((response) => response)
        .then((result: any) => {
          if (result.status == HttpStatusCode.Ok) {
            dispatch(validateTaxpayerClientView(result.data.url, result.data.token))
          } else {
            AppNotifier.Error(RequestNotification.Failure.GenerateTaxpayerClientViewError);
          }
        });
    } catch (error: any) {
      AppNotifier.Error(RequestNotification.Failure.GenerateTaxpayerClientViewError);
    }
  };
};

export const validateTaxpayerClientView = (validateUrl: string, cpaToken: string)
  : ThunkAction<void, AppState, unknown, Action> =>
  () => {
    var data = {
      token: cpaToken,
      scope: 'client_view'
    }
    Axios.post(validateUrl, data, null, false, true)
      .then((response: any) => response)
      .then((result: any) => {
        AppNotifier.Success(RequestNotification.Success.GenerateTaxpayerClientViewSuccess);
        window.open(result.data.url, '_blank');
      }).catch((error: any) => {
        AppNotifier.Error(RequestNotification.Failure.GenerateTaxpayerClientViewError);
      })
  }

export const closeGatherByFirm = (gatherId: number, successCallback?: () => any): ThunkAction<void, AppState, unknown, Action> => {
  return (dispatch) => {
    dispatch(startLoader());
    Axios.put(`${API.DELIVERED_GATHER.CLOSE_GATHER_BY_FIRM}/${gatherId}`, {
      method: "PUT",
      credentials: "include"
    })
      .then(() => {
        AppNotifier.Success(RequestNotification.Success.CloseGatherByFirm);
        dispatch(stopLoader());
        if (successCallback) {
          successCallback();
        }
      }).catch((error: any) => {
        AppNotifier.Error(RequestNotification.Failure.CloseGatherByFirm);
        dispatch(stopLoader());
      });
  }
};

export const resendAccessLink = (gatherId: number, client: IGatherClientDetail, callback?: any): ThunkAction<void, AppState, unknown, Action> => {
  return (dispatch) => {
    dispatch(startLoader());
    Axios.post(`${API.DELIVERED_GATHER.RESEND_ACCESS_LINK}/${gatherId}`, client)
      .then(() => {
        dispatch(stopLoader());
        AppNotifier.Success(RequestNotification.Success.ResendAccessLink);
          
        if (callback) {
          callback();
        }
      }).catch(() => {
        dispatch(stopLoader());
        AppNotifier.Error(RequestNotification.Failure.ResendAccessLink);
      });
  }
};

export const deliveredRequestAccessCode = (gatherId: number, successCallback?: (accessCodeData: IClientAccessCodeDetail) => any): ThunkAction<void, AppState, unknown, Action> => {
  return (dispatch) => {
      dispatch(startLoader());
      Axios.get(`${API.DELIVERED_GATHER.ACCESS_CODE}/${gatherId}`, {
          method: "GET",
          credentials: "include"
      })
          .then((response) => response.data as IClientAccessCodeDetail)
          .then((data) => {
              dispatch(stopLoader());
              if (successCallback) {
                  successCallback(data);
              }
          }).catch((error: any) => {
              dispatch(stopLoader());
          });
  }
};

export const deliveredUnlockGather = (gatherId: number, successCallback?: () => any): ThunkAction<void, AppState, unknown, Action> => {
  return () => {
    Axios.put(`${API.DELIVERED_GATHER.UNLOCK_GATHER}/${gatherId}`, {
      method: "PUT",
      credentials: "include"
    })
      .then(() => {
        AppNotifier.Success(RequestNotification.Success.UnlockGather);
        if (successCallback) {
          successCallback();
        }
      }).catch((error: any) => {
        AppNotifier.Error(RequestNotification.Failure.UnlockGather);
      });
  }
};

export const unlockOneHub = (emailAddress: string, successCallback?: () => any): ThunkAction<void, AppState, unknown, Action> => {
  return () => {
    Axios.put(`${API.DELIVERED_GATHER.UNLOCK_ONE_HUB}${emailAddress}`, {
      credentials: "include"
    })
      .then(() => {
        AppNotifier.Success(RequestNotification.Success.UnlockOneHub);
        if (successCallback) {
          successCallback();
        }
      }).catch((error: any) => {
        AppNotifier.Error(RequestNotification.Failure.UnlockOneHub);
      });
  }
};

export const reopenGatherByFirm = (gatherId: number, successCallback?: () => any): ThunkAction<void, AppState, unknown, Action> => {
  return (dispatch) => {
    dispatch(startLoader());
    Axios.put(`${API.DELIVERED_GATHER.RE_OPEN_GATHER_BY_FIRM}/${gatherId}`, {
      method: "PUT",
      credentials: "include"
    })
      .then((response) => {
        AppNotifier.Success(RequestNotification.Success.ReopenGatherByFirm);
        dispatch(stopLoader());
        if (successCallback) {
          successCallback();
        }
      }).catch((error: any) => {
        AppNotifier.Error(RequestNotification.Failure.ReopenGatherByFirm);
        dispatch(stopLoader());
      });
  }
};

export const reopenSourceDocumentUpload = (gatherId: number, successCallback?: () => any): ThunkAction<void, AppState, unknown, Action> => {
  return (dispatch) => {
    dispatch(startLoader());
    Axios.put(`${API.DELIVERED_GATHER.RE_OPEN_SOURCE_DOC_UPLOAD}/${gatherId}`, {
      method: "PUT",
      credentials: "include"
    })
      .then((response) => response)
      .then(() => {
        AppNotifier.Success(RequestNotification.Success.ReopenCompletedDocument);
        dispatch(stopLoader());
        if (successCallback) {
          successCallback();
        }
      }).catch((error: any) => {
        AppNotifier.Error(RequestNotification.Failure.ReopenCompletedDocument);
        dispatch(stopLoader());
      });
  }
};

export const reopenGather = (gatherId: number, successCallback?: () => any): ThunkAction<void, AppState, unknown, Action> => {
  return (dispatch) => {
    dispatch(startLoader());
    Axios.put(`${API.DELIVERED_GATHER.RE_OPEN_GATHER}/${gatherId}`, {
      method: "PUT",
      credentials: "include"
    })
      .then(() => {
        AppNotifier.Success(RequestNotification.Success.ReopenGather);
        dispatch(stopLoader());
        if (successCallback) {
          successCallback();
        }
      }).catch((error: any) => {
        AppNotifier.Error(RequestNotification.Failure.ReopenGather);
        dispatch(stopLoader());
      });
  }
};

export const requestDeliveredGatherClientTracking = (gatherId: number, successCallback?: (data: GatherTransaction[]) => void): ThunkAction<void, AppState, unknown, Action> => {
  return (dispatch) => {
      dispatch(startLoader());
      Axios.get(`${API.DELIVERED_GATHER.GET_CLIENT_TRACKING}/${gatherId}`)
          .then((response) => response.data as GatherTransaction[])
          .then((data) => {
              dispatch(stopLoader());
              if (successCallback) {
                  successCallback(data);
              }
          }).catch((error) => {
              AppNotifier.Error(RequestNotification.Failure.ClientTracking);
          });
  }
};

export const requestDeliveredGatherClientDeliveryStatus = (gatherId: number, successCallback?: (data: IGatherClientDetail[]) => void): ThunkAction<void, AppState, unknown, Action> => {
  return (dispatch) => {
    dispatch(startLoader());
    Axios.get(`${API.DELIVERED_GATHER.GET_CLIENT_DELIVERY_STATUS}/${gatherId}`)
      .then((response) => response.data as IGatherClientDetail[])
      .then((data) => {
        dispatch(stopLoader());
        if (successCallback) {
          successCallback(data);
        }
      }).catch((error) => {
        AppNotifier.Error(RequestNotification.Failure.GetClientInfo);
      });
  }
};

export const deliveredGenerateAccessCode = (
  gatherId: number,
  clientGuid: string,
  clientType: ClientType,
  successCallback?: () => any): ThunkAction<void, AppState, unknown, Action> => {
  return (dispatch) => {
    dispatch(startLoader());
    Axios.put(`${API.DELIVERED_GATHER.ACCESS_CODE}/${gatherId}/${clientGuid}/${clientType}`, {
      method: "PUT",
      credentials: "include"
    })
      .then(() => {
        dispatch(stopLoader());
        if (successCallback) {
          successCallback();
        }
      }).catch((error: any) => {
        dispatch(stopLoader());
        AppNotifier.Error(RequestNotification.Failure.GenerateAccessCode);
      });
  }
};

export const getLinkedUTEDetailsForDelivered = (gatherId : number, callback: (linkedMergedDetailsResponse: DeliveredILinkedMergedDetailsResponse) => void)
:ThunkAction<void, AppState, unknown, Action> => {
  return (dispatch) => {
    const linkedMergedDetailsResponse: DeliveredILinkedMergedDetailsResponse = {
      linkedDetails: [],
      error: false
  };
    dispatch(startLoader());
    Axios.get(`${API.DELIVERED_GATHER.GET_UTE_MERGE_DETAILS}?gatherId=${gatherId}`)
    .then((response) => response.data as DeliveredILinkedMergedDetails[])
      .then((data) => {
        dispatch(stopLoader());
        linkedMergedDetailsResponse.linkedDetails = data;
        callback(linkedMergedDetailsResponse);
      })
      .catch((error: any) => {
        dispatch(stopLoader());
        linkedMergedDetailsResponse.error = true;
        callback(linkedMergedDetailsResponse);
      });
  }
}