import { BrowserRouter } from 'react-router-dom';
import AppRoutes from 'route/routes';
import './App.scss';
import { useEffect } from 'react';

function App() {

  useEffect(() => {
    const loadStyles = async () => {
      await import('styles/index.scss');
    };
    loadStyles();
  }, []);

  return (
    <div className="App">
        <BrowserRouter>
          <AppRoutes />
        </BrowserRouter>
    </div>
  );
}

export default App;
