import LoaderWrapper from "common/pages/LoaderWrapper";
import React, { ReactNode } from "react";
import { Modal } from "react-bootstrap";
import ModalHeader from "react-bootstrap/esm/ModalHeader";

export interface GenericModalProps {
  show: boolean;
  onHide: () => void;
  onSubmit?: () => void;
}
export interface ICustomModalProps extends GenericModalProps {
  children: ReactNode;
  cutsomHeaderControl?: ReactNode;
  cancelButtonName?: string;
  confirmButtonName?: string;
  additionalActionButtonName?: string;
  title?: string;
  hideFooter?: boolean;
  onAdditionalButtonClick?: () => void;
  onCancelButtonClick?: () => void;
  className?: string;
  footerBtnSpaceBetween?: ReactNode;
  cancelDisabled?: boolean;
  confirmDisabled?: boolean;
  size?: "sm" | "lg" | "xl";
  hideConfirmButton?: boolean;
  hideCancelButton?: boolean;
  saveButtonRef?: any;
  removeBodyPadding?: boolean;
  isConfirmModal?: boolean;
  customSize?: "expanded" | "shrinked" | "850" | "700" | "650" | "600" | "500" | "550";
  confirmButtonClassName?: string;
  closeButtonClassName?: string;
  backdrop?: boolean;
}

export const CustomModal: React.FC<ICustomModalProps> = (props) => {

  const defaultCloseButtonClass = 'white';
  const closeButtonClass = props.closeButtonClassName || defaultCloseButtonClass;
  const onHideModal = () => {
    if (props.onCancelButtonClick) {
      props.onCancelButtonClick();
      return;
    } else {
      props.onHide();
    }
  };
  const onSubmitModal = () => {
    if (!props.confirmDisabled || !props.hideConfirmButton) {
      props.onSubmit && props.onSubmit();
    }
  };
  return (
    <Modal
      size={props.size}
      className={`custom-modal-container ${props.isConfirmModal ? "confirm-modal" : ""} ${props.className} modal-${props.customSize
        }`}
      show={props.show}
      onHide={props.onHide}
      onClick={(e: any) => {
        e.stopPropagation();
      }}
      backdrop={props.backdrop !== undefined ? props.backdrop : true}
    >
      <LoaderWrapper>
        <ModalHeader closeButton closeVariant={props.isConfirmModal ? "grey" : closeButtonClass}>
          <Modal.Title>
            <span className="ellipsis" title={props.title}>{props.title}</span>
          </Modal.Title>
          { props.cutsomHeaderControl ? props.cutsomHeaderControl : <></> }
        </ModalHeader>
        <Modal.Body style={{ padding: props.removeBodyPadding ? 0 : "intial" }}>{props.children}</Modal.Body>
        {!props.hideFooter && (
          <Modal.Footer className={props.footerBtnSpaceBetween ? "d-flex justify-content-between" : ""}>
            {!props.hideCancelButton && <button
              type="button"
              className="button-default ss-btn-secondary"
              disabled={props.cancelDisabled}
              onClick={() => {
                onHideModal();
              }}
            >
              {props.cancelButtonName ?? "Cancel"}
            </button>}
            {!props.hideConfirmButton && (
              <button
                ref={props.saveButtonRef}
                disabled={props.confirmDisabled}
                type="button"
                className={props.confirmButtonClassName ? `button-default ss-btn-primary ${props.confirmButtonClassName}` : 'button-default ss-btn-primary'}
                onClick={onSubmitModal}
              >
                {props.confirmButtonName ?? "Confirm"}
              </button>
            )}
            {props.additionalActionButtonName && (
              <button type="button" onClick={props.onAdditionalButtonClick} className="button-default ss-btn-primary">
                {props.additionalActionButtonName}
              </button>
            )}
          </Modal.Footer>
        )}
      </LoaderWrapper>
    </Modal>
  );
};
