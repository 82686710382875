import { DeleteIcon } from "assets/icons/IconCollection";
import { CqCopyIcon } from "assets/svg/SVGIconsCollection";
import { AutomationLocators } from "helper/AutomationLocators";
import { FormBuilderConstants } from "helper/Constants";
import { getQuestionsLimitErrorMessage, getTotalQuestions } from "helper/HelperFunctions";
import { cqSettingsDummy } from "helper/dummy";
import { IDupicateQuestionPayload, ISectionItems } from "store/models/CustomQuestionModel";
import { ChangeRequiredQuestionHandler, DeleteQuestionHandler, DuplicateQuestionHandler } from "store/slices/form-builder";
import { useAppDispatch, useAppSelector } from "common/hooks/redux-hooks";
import { AppNotifier } from "common/components/toast/Toast";
import { AppState } from "store";
import { FC } from "react";

interface SectionItemFooterProps {
  sectionItem: ISectionItems;
  sectionItemIndex: number;
  sectionIndex: number;
  totalquestions: number;
}

const SectionItemFooter: FC<SectionItemFooterProps> = ({ sectionItem, sectionIndex, sectionItemIndex, totalquestions }) => {
  const dispatch = useAppDispatch();
  const formData = useAppSelector((state: AppState) => state.formBuilderReducer.formData);
  const customQuestionSettings = cqSettingsDummy;

  const onClickDuplicateQuestion = () => {
    if (isQuestionLimitValid()) {
      if (`${sectionItem.questionTitle}_copy`.length > FormBuilderConstants.QuestionTitle.maxLength) {
        AppNotifier.Error(FormBuilderConstants.DUPLICATE_QUESTION_LIMIT);
      } else {
        dispatch(DuplicateQuestionHandler(getDuplicateQuestionPayload()));
      }
    }
  };
  const onClickDeleteQuestion = () => {
    if (totalquestions > 1) {
      dispatch(DeleteQuestionHandler({ sectionIndex, sectionItemIndex }));
    }
  };
  const onClickIsRequiredQuestion = () => {
    dispatch(ChangeRequiredQuestionHandler({ sectionIndex, sectionItemIndex }));
  };
  const isQuestionLimitValid = () => {
    if (getTotalQuestions(formData) + 1 > customQuestionSettings.questionLimit) {
      AppNotifier.Error(getQuestionsLimitErrorMessage(customQuestionSettings.questionLimit, "duplicate"));
      return false;
    }
    return true;
  };
  const getDuplicateQuestionPayload = () => {
    const sectionItemPayload: ISectionItems = JSON.parse(JSON.stringify(sectionItem));
    sectionItemPayload.questionTitle = getQuestionTitle(sectionItem.questionTitle);
    sectionItemPayload.isValid = true;
    if (sectionItemPayload.choices.length > 0) {
      sectionItemPayload.choices.forEach((choice) => (choice.isValid = true));
    }
    const payload: IDupicateQuestionPayload = {
      sectionItem: sectionItemPayload,
      sectionIndex,
      sectionItemIndex
    };
    return payload;
  };
  const getQuestionTitle = (questionTitle: string) => {
    if (questionTitle !== "") {
      return `${questionTitle}_copy`;
    }
    return questionTitle;
  };
  return (
    <div className="section-item-footer">
      <button
        data-test-auto={AutomationLocators.Settings.CustomQuestions.Section.duplicate}
        onClick={onClickDuplicateQuestion}
        className="cq-btn-with-icon"
      >
        <CqCopyIcon />
        Duplicate
      </button>
      <button
        data-test-auto={AutomationLocators.Settings.CustomQuestions.Section.delete}
        disabled={totalquestions === 1}
        title={totalquestions === 1 ? FormBuilderConstants.DELETE_QUESTION_DISABLED_TOOLTIP : ""}
        className="cq-btn-with-icon"
        onClick={onClickDeleteQuestion}
      >
        <DeleteIcon />
        Delete
      </button>
      <div className="required-wrapper">
        <label>Required</label>
        <label className="switch">
          <input
            data-test-auto={AutomationLocators.Settings.CustomQuestions.Section.required}
            type="checkbox"
            checked={sectionItem.isRequired}
            onChange={onClickIsRequiredQuestion}
          ></input>
          <span className="slider round"></span>
        </label>
      </div>
    </div>
  );
};

export default SectionItemFooter;
