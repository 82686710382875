import { FC, useState } from "react";
import { Col, Nav, Row, Tab } from "react-bootstrap";
import MyAccountContent from "./MyAccountContent";
import { MyAccountTitle } from "helper/Constants";

export interface IMyAccountLayout {
    onHide: () => void;
}


const MyAccountLayout: FC<IMyAccountLayout> = ({ onHide }) => {
    const menuKeys = Object.keys(MyAccountTitle),
        [selectedTab, setSelecteTab] = useState<string>(menuKeys[0]);

    const handleTabChange = (key: string) => {
        setSelecteTab(key);
    };

    return <Tab.Container defaultActiveKey={menuKeys[0]} onSelect={(key) => key && handleTabChange(key)} activeKey={selectedTab}>
        <Row className="my-account m-0">
            <Col xs={3} className="side-bar px-0 py-2">
                <Nav className="flex-column">
                    {menuKeys.map((data: string, index: number) => <Nav.Item key={`${data}-${index}`}>
                        <Nav.Link eventKey={data}>
                            <span>{MyAccountTitle[data]}</span>
                        </Nav.Link>
                    </Nav.Item>)}
                </Nav>
            </Col>
            <Col className="h-100">
                <Tab.Content className="h-100">
                    {menuKeys.map((data: string, index: number) => <Tab.Pane eventKey={data} key={`${data}-${index}`}>
                        <MyAccountContent eventKey={data} onHide={onHide} />
                    </Tab.Pane>)}
                </Tab.Content>
            </Col>
        </Row>
    </Tab.Container>;
};

export default MyAccountLayout;