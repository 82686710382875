import { UiTextConstants } from '../helper/Constants';
import { NoPermissionButProductAccessIcon } from 'assets/svg/SVGIconsCollection';
import './DisablePage.scss';
import { FC } from 'react';

const NoPermissionButProductAcess: FC<any> = () => {
    return <div className="product-access-denied">
        <div>
            <NoPermissionButProductAccessIcon width={200} height={200} />
        </div>
        <br />
        <div className="product-access-denied-text">
            <h3><b>{UiTextConstants.AccessDenied}</b></h3>
            <div>
                <div>
                    {UiTextConstants.NoPermissionButProductAcessMessageLine1}<br />
                    {UiTextConstants.NoPermissionButProductAcessMessageLine2}
                </div>
            </div>
        </div>
    </div>
}
export default NoPermissionButProductAcess;