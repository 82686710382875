import { DeleteIcon, EditIcon } from "assets/icons/IconCollection";
import { EngagementsType } from "common/model/engagements-type";
import { EngagementTypeSettingsConstants } from "pages/Constants";
import React, { useEffect, useState } from "react";
import "../style.scss";

export interface IMessageListBoxProps {
    data: EngagementsType[];
    onEdit: (data: EngagementsType) => void;
    onDelete: (data: EngagementsType) => void;
}

const EngagementTypeListBox: React.FC<IMessageListBoxProps> = ({
    data,
    onEdit,
    onDelete
}) => {
    const [list, setlist] = useState([{ id: 0, isSystemDefault: false, isUserDefault: false, name: "" }]);

    useEffect(() => { if (data) { setlist(data) } }, [data])
    return (
        <div className="message-list-box engagement-type-list-box">
            <div>
                {data &&
                    data.length > 0 &&
                    list.map((ele) => {
                        return (
                            <div
                                key={ele.id}
                                className={`message-wrapper row-padding-engagement-type`}
                                
                            >
                                <div className="d-flex">
                                    <p title={ele.name} className="d-inline-block text-truncate engagementtype-max-width">{ele.name}</p><p title={ele.engagementCategoryName} className="ml-1 d-inline text-muted"> | {ele.engagementCategoryName}</p>
                                </div>
                                <div className="diplay-flex-div">
                                {ele.isUserDefault === true && <span className="m-auto">Default</span>}
                                    <button onClick={() => onEdit(ele)}
                                        className={`btn-with-icon no-border-icon ${ele.isUserDefault ? 'system-default-engagement-type' : ''}`}
                                        title={EngagementTypeSettingsConstants.EditMessage}
                                    >
                                        <EditIcon />
                                        Edit
                                    </button>

                                    {!ele.isSystemDefault &&
                                        <button
                                            onClick={() => onDelete(ele)}
                                            className="btn-with-icon no-border-icon"
                                            title={EngagementTypeSettingsConstants.DeleteMessage}
                                        >
                                            <DeleteIcon />
                                            Delete
                                        </button>
                                    }
                                </div>

                            </div>
                        );
                    })}
            </div>
        </div>
    );
};

export default EngagementTypeListBox;
