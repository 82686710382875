import { FC, useEffect, useState } from "react";
import { CustomModal, GenericModalProps } from "common/components/UIComponents/CustomModal";
import "./style.scss";
import DropdownComponent from "common/components/UIComponents/DropdownComponent";
import { Form } from "react-bootstrap";
import { DefaultSenderInfoType, DownloadType } from "common/enum/GatherEnums";
import {  IUserDefaultSettings } from "common/model/userSetting";
import { IUserGroupViewModel, IUserViewModel, UserType } from "common/model/user";
import { IUploadTemplate } from "common/model/upload-template";
import CustomTabDropdown from "./CustomTabDropdown";

interface IMySettingsProps extends GenericModalProps {
  defaultSettings: IUserDefaultSettings;
  users: IUserViewModel[];
  userGroups: IUserGroupViewModel[];
  ero: any;
  templateList: IUploadTemplate[] | null;
  onChangeEROStamp(event: any): void;
  onChangeContactPerson(event: any): void;
  onChangeNotifySigningEvents(event: any): void;
  onChangeNotifyOrganizerEvents(event: any): void;
  onChangeNotifySourceDocEvents(event: any): void;
  onChangeSenderName(event: any): void;
  onChangeDefaultDownload(event: any): void;
  onChangeGatherTemplate(event: any): void;
  onhandleCompanySelect(event: any): void;
  onSave: () => void;
  onCancel: () => void;
}

const MySettings: FC<IMySettingsProps> = ({
  show,
  onCancel,
  defaultSettings,
  users,
  userGroups,
  templateList,
  ero,
  onChangeEROStamp,
  onChangeContactPerson,
  onChangeNotifySigningEvents,
  onChangeNotifyOrganizerEvents,
  onChangeNotifySourceDocEvents,
  onChangeSenderName,
  onChangeDefaultDownload,
  onChangeGatherTemplate,
  onhandleCompanySelect,
  onSave
}) => {
  const downloadOptions = [
    { value: DownloadType.DownloadNow, label: "Download Now" },
    { value: DownloadType.DownloadToMyDownloads, label: 'Download to "My Downloads"' },
    { value: DownloadType.PromptMeEveryTime, label: "Prompt me Every Time" }
  ];

  const otherUsers = () => {
    var usersList: any = [];
    users?.map((user: IUserViewModel) => {
      usersList.push({
        value: user.id,
        label: user.firstName + " " + user.lastName
      });
    });
    return usersList;
  };

  const mergedNotifierData = () => {
    var combinedList: any = [];

    userGroups?.map((group: IUserGroupViewModel) => {
      combinedList.push({
        value: group.groupId,
        label: group.groupName,
        type: UserType.UserGroup
      });
    });
    users?.map((user: IUserViewModel) => {
      combinedList.push({
        value: user.id,
        label: user.firstName + " " + user.lastName,
        type: UserType.User
      });
    });
    return combinedList;
  };
  const eroUsers = () => {
    var erosList: any = [];
    ero?.map((user: IUserViewModel) => {
      erosList.push({
        value: user.id,
        label: user.firstName + " " + user.lastName
      });
    });
    return erosList;
  };

  const gatherTemplateList = () => {
    var tempList: any = [];
    templateList?.map((user: IUploadTemplate) => {
      tempList.push({
        value: user.id,
        label: user.name
      });
    });
    return tempList;
  };
  useEffect(() => {
    setuserList(otherUsers);
    setNotifierList(mergedNotifierData);
    setEroList(eroUsers);
    settempList(gatherTemplateList);
  }, [users, ero, templateList]);
  const [userList, setuserList] = useState(otherUsers);
  const [notifierList, setNotifierList] = useState(mergedNotifierData);
  const [eroList, setEroList] = useState(eroUsers);
  const [tempList, settempList] = useState(gatherTemplateList);
  const [openDropdown, setOpenDropdown] = useState<string | null>(null);
  const [isSigningExpanded, setIsSigningExpanded] = useState<boolean>(false);
  const [isOrganizerExpanded, setIsOrganizerExpanded] = useState<boolean>(false);
  const [isUploadingExpanded, setIsUploadingExpanded] = useState<boolean>(false);

  const handleDropdownToggle = (dropdownName: string) => {
    setOpenDropdown(openDropdown === dropdownName ? null : dropdownName);
  };
  const handleSigningOverflow = (isOverflow: boolean) => {
      setIsSigningExpanded(isOverflow);
  };
  const handleOrganizerOverflow = (isOverflow: boolean) => {
      setIsOrganizerExpanded(isOverflow);
  };
  const handleUploadOverflow = (isOverflow: boolean) => {
      setIsUploadingExpanded(isOverflow);
  };

  return (
    <CustomModal
      title="My Default Settings"
      confirmButtonName="Save"
      show={show}
      onHide={onCancel}
      onSubmit={onSave}
      customSize="650"
      className="custom-modal-body"
    >
      <div className="my-settings-wrapper">
        <main>
          <div className="left-wrapper">
            <div>
              <h4>My Default Settings</h4>
              <div className="dropdown-label-wrapper">
                <label className="label-data">Gather Template</label>
                <DropdownComponent
                  customPlaceHolder={"Select Gather Template"}
                  options={tempList}
                  onChange={(e) => onChangeGatherTemplate(e)}
                  selectedValue={defaultSettings.gatherTemplate}
                />
              </div>
              <div className="dropdown-label-wrapper">
                <label className="label-data">ERO/Signer</label>
                <DropdownComponent
                  customPlaceHolder={"Select ERO/Signer"}
                  options={eroList}
                  onChange={(e) => onChangeEROStamp(e)}
                  selectedValue={defaultSettings.eroUser}
                />
              </div>

              <div className="dropdown-label-wrapper">
                <label className="label-data">Contact Person</label>
                <DropdownComponent
                  customPlaceHolder={"Select Contact Person"}
                  options={userList}
                  onChange={(e) => onChangeContactPerson(e)}
                  selectedValue={defaultSettings.contactPerson}
                />
              </div>
              <div
                className={
                  defaultSettings.sender.senderType == DefaultSenderInfoType.Company ? "hidden" : "dropdown-label-wrapper"
                }
              >
                <label className="label-data">Sender Name</label>
                <DropdownComponent
                  options={userList}
                  onChange={(e) => onChangeSenderName(e)}
                  selectedValue={defaultSettings.sender.senderId}
                />
              </div>
            </div>
            <div className="label-with-radio-inline">
              <label className="label-data">Sender</label>
              <Form.Check
                type="radio"
                label="Company"
                inline
                name="senderType"
                value={"Company"}
                checked={defaultSettings && defaultSettings.sender.senderType == DefaultSenderInfoType.Company}
                onChange={(e) => onhandleCompanySelect(e)}
              />
              <Form.Check
                type="radio"
                label="Users"
                inline
                value="User"
                checked={defaultSettings && defaultSettings.sender.senderType == DefaultSenderInfoType.User}
                name="senderType"
                onChange={(e) => onhandleCompanySelect(e)}
              />
            </div>
            <div className="dropdown-label-wrapper download-options-wrapper">
          <label className="label-data">Download Options</label>
          <DropdownComponent
            customPlaceHolder={"Select Download Options"}
            options={downloadOptions}
            onChange={(e) => onChangeDefaultDownload(e)}
            selectedValue={defaultSettings && defaultSettings.download.downloadType}
          />
        </div>
          </div>
          <div className="right-wrapper">
            <h4>Notify Events to:</h4>
            <div className={`dropdown-label-wrapper ${isSigningExpanded ? 'expanded-height' : 'default-height'}`}>
                <label className="label-data">Signing</label>
                <CustomTabDropdown
                    userList={notifierList}
                    selectedUsers={defaultSettings.notifyELEventsUser}
                    onChange={(e) => onChangeNotifySigningEvents(e)}
                    userGroups={userGroups}
                    isOpen={openDropdown === 'signing'}
                    onToggle={() => handleDropdownToggle('signing')}
                    customWidth={280}
                    customHeight={66}
                    onContentOverflow={handleSigningOverflow}
                />
            </div>
            <div className={`dropdown-label-wrapper ${isOrganizerExpanded ? 'expanded-height' : 'default-height'}`}>
                <label className="label-data">Organizer</label>
                <CustomTabDropdown
                    userList={notifierList}
                    selectedUsers={defaultSettings.notifyOrganizerEventsUser}
                    onChange={(e) => onChangeNotifyOrganizerEvents(e)}
                    userGroups={userGroups}
                    isOpen={openDropdown === 'organizer'}
                    onToggle={() => handleDropdownToggle('organizer')}
                    customWidth={280}
                    customHeight={66}
                    onContentOverflow={handleOrganizerOverflow}
                />
            </div>
            <div className={`dropdown-label-wrapper ${isUploadingExpanded ? 'expanded-height' : 'default-height'}`}>
                <label className="label-data">Uploading</label>
                <CustomTabDropdown
                    userList={notifierList}
                    selectedUsers={defaultSettings.notifySourceDocEventsUser}
                    onChange={(e) => onChangeNotifySourceDocEvents(e)}
                    userGroups={userGroups}
                    isOpen={openDropdown === 'uploading'}
                    onToggle={() => handleDropdownToggle('uploading')}
                    customWidth={280}
                    customHeight={66}
                    onContentOverflow={handleUploadOverflow}
                />
            </div>
            </div>
        </main>
      </div>
    </CustomModal>
  );
};

export default MySettings;
