import { FormBuilderConstants } from "helper/Constants";
import { FC } from "react";
import { Form } from "react-bootstrap";


interface IParagraphQuestionProps {
}

const ParagraphQuestion: FC<IParagraphQuestionProps> = () => {
    return (
        <div className="question-type-wrapper">
            <Form.Control placeholder={FormBuilderConstants.QuestionTitle.answerPlaceholder} as="textarea" disabled />
        </div>
    );
};

export default ParagraphQuestion;
