import { ApplicationInsights, ITelemetryItem } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from "history";
import Axios from 'services/api/axios-interceptor';
import { APP_INSIGHT_URL } from 'services/api/api-config';

let profileData: any;
const browserHistory = createBrowserHistory();
var reactPlugin = new ReactPlugin();

var appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: '',
    extensions: [reactPlugin],
    enableAutoRouteTracking: false,
    extensionConfig: {
      [reactPlugin.identifier]: { history: browserHistory }
    }
  }
});
appInsights.loadAppInsights();
export const getDataForAppInsights = (callback?: () => void) => {
  Axios.get(APP_INSIGHT_URL).then((response: any) => {
    profileData = response;
    setTelemetryData();
    appInsights.config.instrumentationKey = profileData.data.instrumentationKey;
  })
};

//Note: Middleware will be executed for every track event (PageView/Dependency/Trace/Exception/etc)
const setTelemetryData = () => {
  let cloudRoleName = "";
  if (cloudRoleName.length === 0) {
    appInsights.addTelemetryInitializer((envelope: ITelemetryItem) => {
      envelope.tags = envelope.tags || []
      envelope.tags.push({ 'ai.cloud.role': 'GTRCPAService-UI' })
      if (envelope.baseType === 'PageviewData') {
        console.log('pageViewData middleware');
        console.log(envelope);
      }

      if (profileData !== undefined) {
        envelope.tags.push({ 'ai.user.accountId': profileData.data.companyId })
        envelope.tags.push({ 'ai.user.id': profileData.data.userId })
      }

      var telemetryItem = envelope.baseData
      if (telemetryItem !== undefined && profileData !== undefined) {
        telemetryItem.properties = telemetryItem.properties || {}
        telemetryItem.properties['UserId'] = profileData.data.userId
        telemetryItem.properties['CompanyId'] = profileData.data.companyId
        telemetryItem.properties['CompanyName'] = profileData.data.companyName
        telemetryItem.properties['Origin'] = 'GTRCPAService-UI'
      }
      cloudRoleName = envelope.tags['ai.cloud.role'];
    })
  }
}

export default appInsights;