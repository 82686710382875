import { TemplateModalConstants } from "helper/Constants";
import Select from "react-select";
import { ISections } from "store/models/CustomQuestionModel";
import { CustomModal } from "../UIComponents/CustomModal";
import { Form } from "react-bootstrap";
import { DropUpArrow, DropdownArrow } from "assets/icons/IconCollection";
import { useEffect, useState } from "react";

interface SectionMergeModalProps {
  show: boolean;
  onClose: () => void;
  onConfirm: (source, target) => void;
  sourceSection: ISections;
  sections: ISections[];
}

const SectionMergeModal: React.FC<SectionMergeModalProps> = ({ show, onClose, onConfirm, sourceSection, sections }) => {
  const [mergeSourceSection, setMergeSourceSection] = useState<any>({});
  const [targetSection, setTargetSection] = useState<any>();
  const [sectionsList, setSectionsList] = useState(sections || []);
  useEffect(() => {
    reConstructSections();
  }, [sections]);

  const reConstructSections = () => {
    sections = sections?.map((item) => {
      return { ...item, label: item.sectionName, value: item.sectionId };
    });
    sections = sections.filter((section) => section.sectionId !== sourceSection.sectionId);
    setSectionsList(sections);
    let section = { ...sourceSection, label: sourceSection.sectionName, value: sourceSection.sectionId };
    setMergeSourceSection(section);
  };

  const onTargetSectionChange = (selectedSection) => {
    setTargetSection(selectedSection);
  };

  const handleOnSubmit = () => {
    onConfirm(sourceSection, targetSection);
  };
  const hideModalHandler = () => {
    onClose();
  };

  const createCustomOption = (props: any) => {
    return (
      <div
        style={{ width: "100%", height: "30px", alignItems: "center", paddingLeft: "5px", display: "flex", flexDirection: "row" }}
        onMouseDown={(e) => {
          e.preventDefault();
          e.stopPropagation();
          props.onSelect(props.option, e);
        }}
        onMouseEnter={(e) => {
          props.onFocus(props.option, e);
        }}
        onMouseMove={(e) => {
          if (props.isFocused) return;
          props.onFocus(props.option, e);
        }}
      >
        <Form.Check
          type="checkbox"
          id="isToolTipRequired"
          checked={props.isSelected}
          onChange={(e) => props.onSelect(props.option, e)}
        />
        <label className="cq-merge-sections-dropdown-label ellipsis dd-option" title={props.children}>
          {props.children}
        </label>
      </div>
    );
  };
  const createCustomTag = (props: any) => {
    let numberOfSectionSelected = targetSection?.length || 0;
    return (
      <div className="cq-merge-sections-dropdown-control">
        <label className="cq-merge-sections-dropdown-label ellipsis">
          {numberOfSectionSelected > 1
            ? numberOfSectionSelected + " sections selected"
            : numberOfSectionSelected + " section selected"}
        </label>
      </div>
    );
  };

  return (
    <CustomModal
      className="template-modal-container"
      onSubmit={handleOnSubmit}
      show={show}
      onHide={hideModalHandler}
      title={TemplateModalConstants.MergeSectionModalHeader}
      confirmButtonName={TemplateModalConstants.SectionMerge}
      cancelDisabled={!targetSection}
      isConfirmModal
    >
      <div className="merge-section-modal-content">
        <div>
          <label>Merge</label>
          <Select
            arrowRenderer={(event) => !event.isOpen ? <DropdownArrow /> : <DropUpArrow />}
            className="cq-merge-sections-dropdown source-dd"
            options={[mergeSourceSection]}
            value={mergeSourceSection}
            clearable={false}
            searchable={false}
          />
        </div>
        <div>
          <label>with</label>
          <Select
            arrowRenderer={(event) => !event.isOpen ? <DropdownArrow /> : <DropUpArrow />}
            className="cq-merge-sections-dropdown"
            options={sectionsList}
            onChange={onTargetSectionChange}
            value={targetSection}
            optionComponent={createCustomOption}
            valueComponent={createCustomTag}
            removeSelected={false}
            multi
            searchable={false}
            backspaceRemoves={false}
            placeholder="Select"
          />
        </div>
      </div>
    </CustomModal>
  );
};
export default SectionMergeModal;
