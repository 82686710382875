import { CircleDeleteIcon } from "assets/svg/SVGIconsCollection";
import { AutomationLocators } from "helper/AutomationLocators";
import { FormBuilderConstants, FormPreviewConstants } from "helper/Constants";
import { removeUnwantedWhitespacesFromText } from "helper/HelperFunctions";
import { FC, useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { IChoiceModel, ISectionItems } from "store/models/CustomQuestionModel";

interface IChoiceProps {
  choice: IChoiceModel;
  choiceIndex: number;
  onChangeHandler: (e: any, choiceIndex: number) => void;
  onRemoveChoiceHandler: (choiceIndex: number) => void;
  sectionItem: ISectionItems;
}
const Choice: FC<IChoiceProps> = ({ choice, onChangeHandler, choiceIndex, sectionItem, onRemoveChoiceHandler }) => {
  const [choiceText, setChoiceText] = useState(choice.text);

  useEffect(() => {
    setChoiceText(choice.text);
  }, [choice.text]);

  const onChangeHandlerLocal = (e: any) => {
    setChoiceText(e.target.value);
  };
  const afterTextChangeComplete = (e: any, choiceIndex: number) => {
    const text = removeUnwantedWhitespacesFromText(e.target.value);
    onChangeHandler(text, choiceIndex);
  };
  return (
    <div key={choiceIndex + choice.id} className="option-row">
      <div className="question-title-wrapper">
        <Form.Check type="radio" disabled />
        <Form.Control
          data-test-auto={AutomationLocators.Settings.CustomQuestions.Section.questionType.multiChoice.choiceText}
          type="text"
          value={choiceText}
          title={choiceText}
          onChange={(e) => onChangeHandlerLocal(e)}
          onBlur={(e) => afterTextChangeComplete(e, choiceIndex)}
          placeholder={FormBuilderConstants.MultiChoiceConstants.placeholder}
          maxLength={FormBuilderConstants.MultiChoiceConstants.maxLength}
          className={choice.isValid === false || choice.isDuplicate === true ? "invalid-input" : ""}
        />
        {choice.isValid === false && <p className="error-text">{FormPreviewConstants.RequiredValidation}</p>}
        {choice.isDuplicate === true && (
          <p className="error-text">{FormPreviewConstants.MultiCoiceTypeQuestion.optionAlreadyExists}</p>
        )}
      </div>
      <span
        data-test-auto={AutomationLocators.Settings.CustomQuestions.Section.questionType.multiChoice.deleteChoice}
        className={sectionItem.choices.length === 1 ? "isDisabled" : ""}
        onClick={() => onRemoveChoiceHandler(choiceIndex)}
        title={sectionItem.choices.length === 1 ? FormBuilderConstants.MultiChoiceConstants.atLeastOneOptionText : ""}
      >
        <CircleDeleteIcon />
      </span>
    </div>
  );
};

export default Choice;
