import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import reportWebVitals from './reportWebVitals';
import App from './App';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './store';
import AuthProvider from 'oidc-client/AuthProvider';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-select/dist/react-select.css';
import 'react-phone-input-2/lib/style.css'
import './index.scss';
import 'react-phone-input-2/lib/style.css';


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <Provider store={store}>
    <AuthProvider store={store}>
      <PersistGate persistor={persistor}>
        <App />
      </PersistGate>
    </AuthProvider>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
