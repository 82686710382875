export const MyDownloadConstant = {
    Download: {
        DownloadInProgress: "Download in progress",
        Download: "Download",
        DownloadNotAllowed: "Download not allowed",
        Failed:"Download failed"
    },
    Delete: {
        Delete: "Delete",
        DeleteNotAllowed: "Delete not allowed",
        DeleteNotAllowedWhileDownloadInProgress: "Delete not allowed while download in progress",
    }
};