import { SortAscending, SortDescending, SortInitial } from "assets/icons/IconCollection";
import { EngagementType, EngagementTypeString, OrganizerClientType, TaxSoftware, TaxSoftwareTypeLabels, TemplateOptions } from "common/enum/GatherEnums";
import { FilterActionType, IGatherFilter } from "common/model/GatherDocumentModel";
import { SortOrder } from "react-bootstrap-table";
import { IFormData, ISections } from "store/models/CustomQuestionModel";
import { isValidEmailAddress } from "./Validations";
import { IGatherClientDetail } from "store/models/GatherClientDetailsModel";
import { PasswordOptions } from "common/model/PasswordPolicySettings.model";
import { AppNotifier } from "common/components/toast/Toast";
import { DefaultPhoneConstants, MAX_FILE_TEXT_LENGTH, ReportFilterConstants, ValidationContants, VERTICAL_SEPARATOR } from "./Constants";
import { IOfficeLocation } from "common/model/company";
import { IDropdown } from "common/components/modal/edit-client-info/parts/OrganizerClientDetails";
import { IProcessStep } from "common/model/upload-template";
import { TaxSoftwares } from "common/enum";
import { createCookie, getDomain } from "@sssuite-component-ui/session-timeout";
import { EngagementsTypeDetails } from "common/model/engagements-type";
import { getParser } from 'bowser';
import moment, { utc, duration } from "moment";
import { UploadedFile } from "common/model/download";


export function formattedClientName(clientName: string) {
  if (clientName == undefined) {
    return "";
  } else {
    const nameList = clientName.trim().split(/ +/).reverse();
    if (nameList.length > 2) {
      return nameList[0] + ", " + nameList.slice(1).reverse().join(" ");
    } else {
      return nameList.join(", ");
    }
  }
}


export const getEngagementType = (engagementType: EngagementType): string => {
  switch (engagementType) {
    case EngagementType.E1040:
      return EngagementTypeString.E1040;
    case EngagementType.Other:
      return EngagementTypeString.Other;
    default:
      return "";
  }
};

export const formatClassName = (data: string) => {
  return data.toLowerCase().split(" ").join("_");
};
export const determineEngagementType = (type: number): number => {
  return type === 0 || type === undefined ? 1 : type;
}

export const getPercentageOptions = () => {
  const options = [];
  for (let i = 1; i <= 20; i++) {
    options.push({ label: `${i * 5}%`, value: i * 5 });
  }
  return options;
};
export const getDaysOptions = () => {
  const options = [{ label: `1 day`, value: 1 }];
  for (let i = 2; i <= 30; i++) {
    options.push({ label: `${i} days`, value: i });
  }
  return options;
};
export const SizePerPageList = [10, 20, 30, 40, 50];

export const sortRenderer = (direction: SortOrder | null, fieldName: string) => {
  if (fieldName === "button") {
    return <></>;
  }
  if (!direction) {
    return <span style={{ cursor: 'pointer' }}><SortInitial /></span>;
  }

  return direction === "asc" ? <span style={{ cursor: 'pointer' }}><SortAscending /></span> : <span style={{ cursor: 'pointer' }}><SortDescending /></span>;
};

export const findKeyByValue = (obj: any, value: any) => {
  for (const key in obj) {
    if (obj[key] === value) {
      return key;
    }
  }
  return ""; // Return null if the value is not found in any key
};

export const getSourceDocumentStatusList = (allowGatheringSourceDocuments: boolean) => {
  return [
    { label: "AWAITING UPLOAD", value: 1 },
    { label: "UPLOADED", value: 2 },
    { label: "DOWNLOADED", value: 3 },
    { label: "FINISHED", value: 4 }
  ].map((item) => {
    if (item.value === 1 && allowGatheringSourceDocuments === false) {
      return { ...item, label: "N/A" };
    } else if (item.value === 1 && allowGatheringSourceDocuments) {
      return { ...item, label: "AWAITING UPLOAD" };
    } else return { ...item };
  });
};
export const getVariableList = () => {
  const tempVariableListModel = [];
  tempVariableListModel.push(
    { variable: "COMPANYNAME", helpText: "The listed Name of your company on your account" },
    { variable: "TAXPAYERNAME", helpText: "The listed Taxpayer on the Organizer" },
    { variable: "SPOUSENAME", helpText: "The listed Spouse on the Organizer" },
    { variable: "HELPCONTACT", helpText: "The name of the user designated for customer support" },
    { variable: "HELPPHONE", helpText: "The phone number of the user designated for customer support" },
    { variable: "HELPEMAIL", helpText: "The email address of the designated help contact" },
    { variable: "TAXYEAR", helpText: "The Taxyear on the Organizer" },
    { variable: "RECIPIENT", helpText: "The person name for which the email is being sent" },
    { variable: "SENDERNAME", helpText: "The name of the user selected as the 'Sender' of the Organizer" }
  );
  return tempVariableListModel;
};
function GetPreviousDate(value: number) {
  const d = new Date();
  d.setDate(d.getDate() - value);
  return moment(d.toString()).format("MM/DD/YYYY");
}

export function GetNumberOfDays(value: any) {
  switch (value) {
    case "One_Day":
      return GetPreviousDate(1);

    case "Two_Days":
      return GetPreviousDate(2);

    case "Three_Days":
      return GetPreviousDate(3);

    case "Five_Days":
      return GetPreviousDate(5);

    case "Seven_Days":
      return GetPreviousDate(7);

    case "Fifteen_Days":
      return GetPreviousDate(15);

    case "Thirty_Days":
      return GetPreviousDate(30);
    default:
      return GetPreviousDate(1);
  }
}
export const generateRandomString = () => Math.floor(Math.random() * Date.now()).toString(36);

export function generateRandomNumber() {
  return Number(
    Math.floor(Math.random() * Date.now())
      .toString()
      .slice(0, 4)
  );
}
export const updateSectionAndQuestionnaireOrder = (data: IFormData) => {
  if (data) {
    const mutableData = JSON.parse(JSON.stringify(data));
    if (data.sections && mutableData.sections.length > 0) {
      mutableData.sections.forEach((section: ISections, sectionIndex: number) => {
        section.order = sectionIndex + 1;
        section.sectionItems.forEach((sectionItem, sectionItemIndex) => {
          if (sectionItem.hasOwnProperty("hasFollowUpQuestion")) {
            delete sectionItem.hasFollowUpQuestion;
          }
          sectionItem.order = sectionItemIndex + 1;
        });
      });
    }
    return mutableData;
  }
  return data;
};
export function removeUnwantedWhitespacesFromText(text: string) {
  const splittedText = text.split(/\s+/);
  if (splittedText.length > 1) {
    const lastsplittedText = splittedText.at(-1);
    const firstsplittedText = splittedText.at(0);
    if (lastsplittedText === "") {
      splittedText.pop();
    }
    if (firstsplittedText === "") {
      splittedText.shift();
    }
  }
  return splittedText.join(" ");
}
export const ValidateFilterName = (filterName: string) => {
  return /[#?//\\]/.test(filterName) == true;
};
export const getTotalQuestions = (formData: IFormData) => {
  let totalQuestions = 0;

  formData && formData.sections.forEach((section) => (totalQuestions = section.sectionItems.length + totalQuestions));
  return totalQuestions;
};

export const getTemplateLimitErrorMessage = (templateLimit: number): string => `Maximum limit of templates is ${templateLimit}`;

export const getQuestionsLimitErrorMessage = (questionLimit: number, text: string): string =>
  `Unable to ${text}. Question limit exceeded! Maximum limit of questions is ${questionLimit}`;

export const getMaxOptionsDisbaledTooltip = (optionLimit: number): string => `Maximum ${optionLimit} options are allowed`;

export const getMaxAdditionalQuestionDisbaledTooltip = (optionLimit: number): string =>
  `Maximum ${optionLimit} additional questions are allowed`;
export const insertRandomIds = (data: IFormData) => {
  if (data?.sections?.length > 0) {
    data.sections.forEach((section) => section.sectionItems.forEach((sectionItem) => (sectionItem.id = generateRandomString())));
  }
  return data;
};
export function capitaliseString(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
};
export function invertCapitaliseString(string: string) {
  return string.charAt(0).toLowerCase() + string.slice(1);
};
export function GetFormatedDateTime(date: Date) {
  return utc(date).local().format("MM/DD/YYYY hh:mm:ss A");
};
export function validatePhone(phoneNoId: any) {
  var phoneNo = phoneNoId.trim();
  if (phoneNo.trim() == "") {
    AppNotifier.Warning(ValidationContants.PhoneNumberWarning);
    return false;
  } else if (phoneNo.trim().length < 10 || /^[0-9]{1,10}$/.test(phoneNo) == false) {
    AppNotifier.Warning(ValidationContants.PhoneNumberLengthWarning);
    return false;
  } else return true;
}
export const validateOrganizerClient = (client: IGatherClientDetail): boolean => {
  if (client) {
    const { clientType, emailAddress, zip, city, mobileNumber, countryCode, name, isDeceased, signingOrder } = client;
    const clientTypeString: string = OrganizerClientType[clientType].toString();

    if (emailAddress && !isValidEmailAddress(emailAddress)) {
      AppNotifier.Warning(`Please enter valid ${clientTypeString} Email Id`);
      return false;
    }

    if (!name?.trim()) {
      AppNotifier.Warning(`Please enter ${clientTypeString} Name`);
      return false;
    }

    if (mobileNumber && !validatePhone(mobileNumber)) {
      AppNotifier.Warning(`Please enter valid mobile number for ${clientTypeString}`);
      return false;
    }

    if (mobileNumber && !countryCode) {
      client.countryCode = DefaultPhoneConstants.CountryCode;
    }
    if (!mobileNumber && countryCode) {
      client.countryCode = '';
    }
  }
  return true;
};
export function phoneNumberDisplay(phoneNumber: string): string {
  if (!phoneNumber) return phoneNumber;
  return phoneNumber && phoneNumber.length == 10
    ? "(" + phoneNumber.substring(0, 3) + ") " + phoneNumber.substring(3, 6) + "-" + phoneNumber.substring(6, 10)
    : phoneNumber;
}
export function addSpaceToPhoneNumber(phoneNumber: string): string {
  if (!phoneNumber) return phoneNumber;
  return phoneNumber && phoneNumber.length === 10
    ? `${phoneNumber.substring(0, 3)} ${phoneNumber.substring(3, 6)}  ${phoneNumber.substring(6, 10)}`
    : phoneNumber;
}

export function getOrganizersCurrentTaxYear() {
  if (new Date().getMonth() == 11) {
    // If Current month is Dec then take the current year as tax year
    return new Date().getFullYear();
  } else {
    // If Current month is not Dec then take the previous year as tax year
    return new Date().getFullYear() - 1;
  }
}
export function getFileSize(fileSizeInBytes: number) {
  let file = parseInt((fileSizeInBytes / 1024).toString(), 10);
  let fileSize = "";
  fileSize = file < 1024 ? (file + " KB").toString() : (parseInt((file / 1024).toString(), 10) + " MB").toString();
  return fileSize;
}

export const formatBytes = (bytes: number, decimals: number = 2) => {
  if (bytes === 0) return "0 Bytes";
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};

export const getPercentageOptionse = (): { value: number; label: string }[] => {
  let options: { value: number; label: string }[] = [];
  for (let i = 1; i <= 100; i++) {
    if (i % 5 === 0) {
      options.push({
        value: i,
        label: `${i.toString()}%`
      });
    }
  }
  return options;
};
export function countryCodeDisplay(countryCode: string): string {
  return countryCode ? "(" + countryCode + ") " : "";
}

export function GetCountryCode() {
  const countryCodeOption = [];
  countryCodeOption.push({ value: "+1", label: "USA/Canada (+1)" });
  countryCodeOption.push({ value: "+213", label: "Algeria (+213)" });
  countryCodeOption.push({ value: "+376", label: "Andorra (+376)" });
  countryCodeOption.push({ value: "+244", label: "Angola (+244)" });
  countryCodeOption.push({ value: "+1264", label: "Anguilla (+1264)" });
  countryCodeOption.push({ value: "+1268", label: "Antigua Barbuda (+1268)" });
  countryCodeOption.push({ value: "+54", label: "Argentina (+54)" });
  countryCodeOption.push({ value: "+374", label: "Armenia (+374)" });
  countryCodeOption.push({ value: "+297", label: "Aruba (+297)" });
  countryCodeOption.push({ value: "+61", label: "Australia (+61)" });
  countryCodeOption.push({ value: "+43", label: "Austria (+43)" });
  countryCodeOption.push({ value: "+994", label: "Azerbaijan (+994)" });
  countryCodeOption.push({ value: "+1242", label: "Bahamas (+1242)" });
  countryCodeOption.push({ value: "+973", label: "Bahrain (+973)" });
  countryCodeOption.push({ value: "+880", label: "Bangladesh (+880)" });
  countryCodeOption.push({ value: "+1246", label: "Barbados (+1246)" });
  countryCodeOption.push({ value: "+375", label: "Belarus (+375)" });
  countryCodeOption.push({ value: "+32", label: "Belgium (+32)" });
  countryCodeOption.push({ value: "+501", label: "Belize (+501)" });
  countryCodeOption.push({ value: "+229", label: "Benin (+229)" });
  countryCodeOption.push({ value: "+1441", label: "Bermuda (+1441)" });
  countryCodeOption.push({ value: "+975", label: "Bhutan (+975)" });
  countryCodeOption.push({ value: "+591", label: "Bolivia (+591)" });
  countryCodeOption.push({ value: "+387", label: "Bosnia Herzegovina (+387)" });
  countryCodeOption.push({ value: "+267", label: "Botswana (+267)" });
  countryCodeOption.push({ value: "+55", label: "Brazil (+55)" });
  countryCodeOption.push({ value: "+673", label: "Brunei (+673)" });
  countryCodeOption.push({ value: "+359", label: "Bulgaria (+359)" });
  countryCodeOption.push({ value: "+226", label: "Burkina Faso (+226)" });
  countryCodeOption.push({ value: "+257", label: "Burundi (+257)" });
  countryCodeOption.push({ value: "+855", label: "Cambodia (+855)" });
  countryCodeOption.push({ value: "+237", label: "Cameroon (+237)" });
  countryCodeOption.push({ value: "+238", label: "Cape Verde Islands (+238)" });
  countryCodeOption.push({ value: "+1345", label: "Cayman Islands (+1345)" });
  countryCodeOption.push({
    value: "+236",
    label: "Central African Republic (+236)"
  });
  countryCodeOption.push({ value: "+56", label: "Chile (+56)" });
  countryCodeOption.push({ value: "+86", label: "China (+86)" });
  countryCodeOption.push({ value: "+57", label: "Colombia (+57)" });
  countryCodeOption.push({ value: "+242", label: "Congo (+242)" });
  countryCodeOption.push({ value: "+682", label: "Cook Islands (+682)" });
  countryCodeOption.push({ value: "+506", label: "Costa Rica (+506)" });
  countryCodeOption.push({ value: "+385", label: "Croatia (+385)" });
  countryCodeOption.push({ value: "+53", label: "Cuba (+53)" });
  countryCodeOption.push({ value: "+90392", label: "Cyprus North (+90392)" });
  countryCodeOption.push({ value: "+357", label: "Cyprus South (+357)" });
  countryCodeOption.push({ value: "+42", label: "Czech Republic (+42)" });
  countryCodeOption.push({ value: "+45", label: "Denmark (+45)" });
  countryCodeOption.push({ value: "+253", label: "Djibouti (+253)" });
  countryCodeOption.push({
    value: "+1809",
    label: "Dominican Republic (+1809)"
  });
  countryCodeOption.push({ value: "+593", label: "Ecuador (+593)" });
  countryCodeOption.push({ value: "+20", label: "Egypt (+20)" });
  countryCodeOption.push({ value: "+503", label: "El Salvador (+503)" });
  countryCodeOption.push({ value: "+240", label: "Equatorial Guinea (+240)" });
  countryCodeOption.push({ value: "+291", label: "Eritrea (+291)" });
  countryCodeOption.push({ value: "+372", label: "Estonia (+372)" });
  countryCodeOption.push({ value: "+251", label: "Ethiopia (+251)" });
  countryCodeOption.push({ value: "+500", label: "Falkland Islands (+500)" });
  countryCodeOption.push({ value: "+298", label: "Faroe Islands (+298)" });
  countryCodeOption.push({ value: "+679", label: "Fiji (+679)" });
  countryCodeOption.push({ value: "+358", label: "Finland (+358)" });
  countryCodeOption.push({ value: "+33", label: "France (+33)" });
  countryCodeOption.push({ value: "+594", label: "French Guiana (+594)" });
  countryCodeOption.push({ value: "+689", label: "French Polynesia (+689)" });
  countryCodeOption.push({ value: "+241", label: "Gabon (+241)" });
  countryCodeOption.push({ value: "+220", label: "Gambia (+220)" });
  countryCodeOption.push({ value: "+7880", label: "Georgia (+7880)" });
  countryCodeOption.push({ value: "+49", label: "Germany (+49)" });
  countryCodeOption.push({ value: "+233", label: "Ghana (+233)" });
  countryCodeOption.push({ value: "+350", label: "Gibraltar (+350)" });
  countryCodeOption.push({ value: "+30", label: "Greece (+30)" });
  countryCodeOption.push({ value: "+299", label: "Greenland (+299)" });
  countryCodeOption.push({ value: "+1473", label: "Grenada (+1473)" });
  countryCodeOption.push({ value: "+590", label: "Guadeloupe (+590)" });
  countryCodeOption.push({ value: "+671", label: "Guam (+671)" });
  countryCodeOption.push({ value: "+502", label: "Guatemala (+502)" });
  countryCodeOption.push({ value: "+224", label: "Guinea (+224)" });
  countryCodeOption.push({ value: "+245", label: "Guinea - Bissau (+245)" });
  countryCodeOption.push({ value: "+592", label: "Guyana (+592)" });
  countryCodeOption.push({ value: "+509", label: "Haiti (+509)" });
  countryCodeOption.push({ value: "+504", label: "Honduras (+504)" });
  countryCodeOption.push({ value: "+852", label: "Hong Kong (+852)" });
  countryCodeOption.push({ value: "+36", label: "Hungary (+36)" });
  countryCodeOption.push({ value: "+354", label: "Iceland (+354)" });
  countryCodeOption.push({ value: "+91", label: "India (+91)" });
  countryCodeOption.push({ value: "+62", label: "Indonesia (+62)" });
  countryCodeOption.push({ value: "+98", label: "Iran (+98)" });
  countryCodeOption.push({ value: "+964", label: "Iraq (+964)" });
  countryCodeOption.push({ value: "+353", label: "Ireland (+353)" });
  countryCodeOption.push({ value: "+972", label: "Israel (+972)" });
  countryCodeOption.push({ value: "+39", label: "Italy (+39)" });
  countryCodeOption.push({ value: "+1876", label: "Jamaica (+1876)" });
  countryCodeOption.push({ value: "+81", label: "Japan (+81)" });
  countryCodeOption.push({ value: "+962", label: "Jordan (+962)" });
  countryCodeOption.push({ value: "+254", label: "Kenya (+254)" });
  countryCodeOption.push({ value: "+686", label: "Kiribati (+686)" });
  countryCodeOption.push({ value: "+850", label: "Korea North (+850)" });
  countryCodeOption.push({ value: "+82", label: "Korea South (+82)" });
  countryCodeOption.push({ value: "+965", label: "Kuwait (+965)" });
  countryCodeOption.push({ value: "+996", label: "Kyrgyzstan (+996)" });
  countryCodeOption.push({ value: "+856", label: "Laos (+856)" });
  countryCodeOption.push({ value: "+371", label: "Latvia (+371)" });
  countryCodeOption.push({ value: "+961", label: "Lebanon (+961)" });
  countryCodeOption.push({ value: "+266", label: "Lesotho (+266)" });
  countryCodeOption.push({ value: "+231", label: "Liberia (+231)" });
  countryCodeOption.push({ value: "+218", label: "Libya (+218)" });
  countryCodeOption.push({ value: "+417", label: "Liechtenstein (+417)" });
  countryCodeOption.push({ value: "+370", label: "Lithuania (+370)" });
  countryCodeOption.push({ value: "+352", label: "Luxembourg (+352)" });
  countryCodeOption.push({ value: "+853", label: "Macao (+853)" });
  countryCodeOption.push({ value: "+389", label: "Macedonia (+389)" });
  countryCodeOption.push({ value: "+261", label: "Madagascar (+261)" });
  countryCodeOption.push({ value: "+265", label: "Malawi (+265)" });
  countryCodeOption.push({ value: "+60", label: "Malaysia (+60)" });
  countryCodeOption.push({ value: "+960", label: "Maldives (+960)" });
  countryCodeOption.push({ value: "+223", label: "Mali (+223)" });
  countryCodeOption.push({ value: "+356", label: "Malta (+356)" });
  countryCodeOption.push({ value: "+692", label: "Marshall Islands (+692)" });
  countryCodeOption.push({ value: "+596", label: "Martinique (+596)" });
  countryCodeOption.push({ value: "+222", label: "Mauritania (+222)" });
  countryCodeOption.push({ value: "+269", label: "Mayotte (+269)" });
  countryCodeOption.push({ value: "+52", label: "Mexico (+52)" });
  countryCodeOption.push({ value: "+691", label: "Micronesia (+691)" });
  countryCodeOption.push({ value: "+373", label: "Moldova (+373)" });
  countryCodeOption.push({ value: "+377", label: "Monaco (+377)" });
  countryCodeOption.push({ value: "+976", label: "Mongolia (+976)" });
  countryCodeOption.push({ value: "+1664", label: "Montserrat (+1664)" });
  countryCodeOption.push({ value: "+212", label: "Morocco (+212)" });
  countryCodeOption.push({ value: "+258", label: "Mozambique (+258)" });
  countryCodeOption.push({ value: "+95", label: "Myanmar (+95)" });
  countryCodeOption.push({ value: "+264", label: "Namibia (+264)" });
  countryCodeOption.push({ value: "+674", label: "Nauru (+674)" });
  countryCodeOption.push({ value: "+977", label: "Nepal (+977)" });
  countryCodeOption.push({ value: "+31", label: "Netherlands (+31)" });
  countryCodeOption.push({ value: "+687", label: "New Caledonia (+687)" });
  countryCodeOption.push({ value: "+64", label: "New Zealand (+64)" });
  countryCodeOption.push({ value: "+505", label: "Nicaragua (+505)" });
  countryCodeOption.push({ value: "+227", label: "Niger (+227)" });
  countryCodeOption.push({ value: "+234", label: "Nigeria (+234)" });
  countryCodeOption.push({ value: "+683", label: "Niue (+683)" });
  countryCodeOption.push({ value: "+672", label: "Norfolk Islands (+672)" });
  countryCodeOption.push({ value: "+670", label: "Northern Marianas (+670)" });
  countryCodeOption.push({ value: "+47", label: "Norway (+47)" });
  countryCodeOption.push({ value: "+968", label: "Oman (+968)" });
  countryCodeOption.push({ value: "+680", label: "Palau (+680)" });
  countryCodeOption.push({ value: "+507", label: "Panama (+507)" });
  countryCodeOption.push({ value: "+675", label: "Papua New Guinea (+675)" });
  countryCodeOption.push({ value: "+595", label: "Paraguay (+595)" });
  countryCodeOption.push({ value: "+51", label: "Peru (+51)" });
  countryCodeOption.push({ value: "+63", label: "Philippines (+63)" });
  countryCodeOption.push({ value: "+48", label: "Poland (+48)" });
  countryCodeOption.push({ value: "+351", label: "Portugal (+351)" });
  countryCodeOption.push({ value: "+1787", label: "Puerto Rico (+1787)" });
  countryCodeOption.push({ value: "+974", label: "Qatar (+974)" });
  countryCodeOption.push({ value: "+262", label: "Reunion (+262)" });
  countryCodeOption.push({ value: "+40", label: "Romania (+40)" });
  countryCodeOption.push({ value: "+7", label: "Russia (+7)" });
  countryCodeOption.push({ value: "+250", label: "Rwanda (+250)" });
  countryCodeOption.push({ value: "+378", label: "San Marino (+378)" });
  countryCodeOption.push({ value: "+239", label: "Sao Tome Principe (+239)" });
  countryCodeOption.push({ value: "+966", label: "Saudi Arabia (+966)" });
  countryCodeOption.push({ value: "+221", label: "Senegal (+221)" });
  countryCodeOption.push({ value: "+381", label: "Serbia (+381)" });
  countryCodeOption.push({ value: "+248", label: "Seychelles (+248)" });
  countryCodeOption.push({ value: "+232", label: "Sierra Leone (+232)" });
  countryCodeOption.push({ value: "+65", label: "Singapore (+65)" });
  countryCodeOption.push({ value: "+421", label: "Slovak Republic (+421)" });
  countryCodeOption.push({ value: "+386", label: "Slovenia (+386)" });
  countryCodeOption.push({ value: "+677", label: "Solomon Islands (+677)" });
  countryCodeOption.push({ value: "+252", label: "Somalia (+252)" });
  countryCodeOption.push({ value: "+27", label: "South Africa (+27)" });
  countryCodeOption.push({ value: "+34", label: "Spain (+34)" });
  countryCodeOption.push({ value: "+94", label: "Sri Lanka (+94)" });
  countryCodeOption.push({ value: "+290", label: "St. Helena (+290)" });
  countryCodeOption.push({ value: "+1869", label: "St. Kitts (+1869)" });
  countryCodeOption.push({ value: "+1758", label: "St. Lucia (+1758)" });
  countryCodeOption.push({ value: "+249", label: "Sudan (+249)" });
  countryCodeOption.push({ value: "+597", label: "Suriname (+597)" });
  countryCodeOption.push({ value: "+268", label: "Swaziland (+268)" });
  countryCodeOption.push({ value: "+46", label: "Sweden (+46)" });
  countryCodeOption.push({ value: "+41", label: "Switzerland (+41)" });
  countryCodeOption.push({ value: "+963", label: "Syria (+963)" });
  countryCodeOption.push({ value: "+886", label: "Taiwan (+886)" });
  countryCodeOption.push({ value: "+66", label: "Thailand (+66)" });
  countryCodeOption.push({ value: "+228", label: "Togo (+228)" });
  countryCodeOption.push({ value: "+676", label: "Tonga (+676)" });
  countryCodeOption.push({ value: "+1868", label: "Trinidad Tobago (+1868)" });
  countryCodeOption.push({ value: "+216", label: "Tunisia (+216)" });
  countryCodeOption.push({ value: "+90", label: "Turkey (+90)" });
  countryCodeOption.push({ value: "+993", label: "Turkmenistan (+993)" });
  countryCodeOption.push({
    value: "+1649",
    label: "Turks Caicos Islands (+1649)"
  });
  countryCodeOption.push({ value: "+688", label: "Tuvalu (+688)" });
  countryCodeOption.push({ value: "+256", label: "Uganda (+256)" });
  countryCodeOption.push({ value: "+44", label: "UK (+44)" });
  countryCodeOption.push({ value: "+380", label: "Ukraine (+380)" });
  countryCodeOption.push({
    value: "+971",
    label: "United Arab Emirates (+971)"
  });
  countryCodeOption.push({ value: "+598", label: "Uruguay (+598)" });
  countryCodeOption.push({ value: "+678", label: "Vanuatu (+678)" });
  countryCodeOption.push({ value: "+379", label: "Vatican City (+379)" });
  countryCodeOption.push({ value: "+58", label: "Venezuela (+58)" });
  countryCodeOption.push({ value: "+84", label: "Virgin Islands" });
  countryCodeOption.push({ value: "+681", label: "Wallis Futuna (+681)" });
  countryCodeOption.push({ value: "+969", label: "Yemen North (+969)" });
  countryCodeOption.push({ value: "+967", label: "Yemen South (+967)" });
  countryCodeOption.push({ value: "+260", label: "Zambia (+260)" });
  countryCodeOption.push({ value: "+263", label: "Zimbabwe (+263)" });
  return countryCodeOption;
}
export const getDefaultCountryCode = () => "+1";

export const getPasswordOptionLabel = (option: number): string => {
  switch (option) {
    case PasswordOptions.None:
      return "";
    case PasswordOptions.UpperCase:
      return "Upper Case Letter";
    case PasswordOptions.LowerCase:
      return "Lower Case Letter";
    case PasswordOptions.Numbers:
      return "Numbers";
    case PasswordOptions.SpecialCharacters:
      return "Special Character";
    default:
      return "";
  }
};

export function validateFilter(filter: IGatherFilter): boolean {
  let isValid = false;

  for (const fieldKey of Object.keys(filter.fields)) {
    if (fieldKey !== 'selectedTaxYear' && filter.fields[fieldKey] &&
      filter.fields[fieldKey].toString().length > 0) {
      isValid = true;
    }
  }

  if (filter.searchText.length > 0) {
    isValid = true;
  }

  return isValid;
}
export const convertTextToHtml = (str: string) => {
  //This method replaces /n character with <br> and // Encode angle brackets to HTML entities
  if (str != null) {
    str = str.replace(/(?:\<)/g, '&lt;').replace(/(?:\>)/g, '&gt;');
    str = str.replace(/(?:\r\n|\r|\n)/g, "<br>");
  }
  return str;
};
export function getExtension(fileName: string): string {
  return fileName.slice((fileName.lastIndexOf(".") - 1 >>> 0) + 2);
}

export function isValidateExtension(file: any, expectedExtensions: string[]): boolean {
  const fileExtension: string = getExtension(file);
  return expectedExtensions.indexOf(fileExtension.toLowerCase()) !== -1;
}
export function normalizeFileName(fileName: string): string {
  var pattern = new RegExp(/[^a-z A-Z 0-9. _\-]+/g);
  let newFileName = fileName.replace(pattern, '_');
  let extension = getExtension(fileName);
  return newFileName.replace(new RegExp('\\.' + extension + '$'), `.${extension.toLowerCase()}`)
}

export function isFileExist(fileName: string, files: string[]): boolean {
  let found: boolean = false;
  for (var i = 0; i < files.length; i++) {
      if (files[i] == fileName) {
          found = true;
          break;
      }
  }
  return found;
}

export function handleDuplicates(uploadedFiles: UploadedFile[], existingFiles: string[]): UploadedFile[] {
  for (let i = 0; i < uploadedFiles.length; i++) {
      let file = uploadedFiles[i];
      let fileName: string = file.name || "";
      let fileExtension = fileName.slice((fileName.lastIndexOf(".") - 1 >>> 0) + 2);

      let filecount = 1;
      while (isFileExist(fileName, existingFiles)) {
          fileName = file.name || "";
          fileName = fileName.replace("." + fileExtension, '');
          fileName = fileName + " (" + filecount + ")." + fileExtension;
          filecount++;
      }
      uploadedFiles[i].name = fileName;
  }
  return uploadedFiles;
}

export const convertTextToHtmlInSavedMessage = (str: string) => {
  //This method replaces /n character with <br> and // Encode angle brackets to HTML entities
  if (str != null) {
    str = str.replace(/(?:\r\n|\r|\n)/g, "<br>");
  }
  return str;
};
//used to get engagementtype options
export const getEngagementTypesOptions = (engagementTypes: EngagementsTypeDetails[]) => {
  var engagementOptions: IDropdown[] = [];
  if (engagementTypes) {
    engagementTypes.map((engagementType: EngagementsTypeDetails) => {
      engagementOptions.push({
        value: engagementType.id,
        label: engagementType.name + VERTICAL_SEPARATOR + engagementType.engagementCategoryName
      })
    })
  }
  return engagementOptions;
}
//used to customize engagement type options for dropdown
export const customEngagementOptionRenderer = (option: any) => {
  const [name, category] = option.label.split(VERTICAL_SEPARATOR);
  return (
    <div className="option-engagement-type">
      <span  title={name} className="option-engagement-name">{name}</span>
      {category && <span className="option-separator"></span>}
      <span className="option-engagement-category">{category}</span>
    </div>
  );
};
//used to customize engagement type selected value for dropdown
export const customEngagementValueRenderer = (option: any) => {
  const [name, category] = option.label.split(VERTICAL_SEPARATOR);
  return (
    <div className="selected-engagement-value">
      <span title={name} className="selected-engagement-name">{name}</span>
      {category && <span className="selected-separator"></span>}
      <span className="selected-engagement-category">{category}</span>
    </div>
  );
};
//Used for office location dropdown on grid
export const getUserLocationData = (companyLocations: IOfficeLocation[], userLocations: number[], addBlankLocation = true) => {
  if (companyLocations && userLocations) {
    const commonUserLocations = companyLocations.filter((location: IOfficeLocation) =>
      userLocations.includes(location.locationId)
    );
    let locationList: IDropdown[] = [];
    if (commonUserLocations) {
      commonUserLocations?.map((location: IOfficeLocation) => {
        const locationVal: IDropdown = {
          label: location.name,
          value: location.locationId
        };
        locationList.push(locationVal);
      });
    }
    if (addBlankLocation) {
      locationList.push({
        label: "Blanks",
        value: -2
      });
    }
    return locationList;
  }
};
export const getFilterStatusMessage = (filterActionType: FilterActionType) => {
  switch (filterActionType) {
    case FilterActionType.SET_DEFAULT:
      return ReportFilterConstants.StatusMessage.DefaultFilterSuccess;
    case FilterActionType.REMOVE_DEFAULT:
      return ReportFilterConstants.StatusMessage.RemoveDefaultFilterSuccess;
    case FilterActionType.UPDATE_CURRENT_FILTER:
      return ReportFilterConstants.StatusMessage.UpdateSuccessMessage;

    default:
      return ReportFilterConstants.StatusMessage.UpdateSuccessMessage;
  }
};
export const getProcessStepsForBatch = (steps: any, batchError: any, isGatheringEnabled: boolean) => {
  const filteredSteps = isGatheringEnabled ? steps : steps?.filter((step: IProcessStep) => step.id != TemplateOptions.RequestDocuments);
  let templateSteps: any[] = [];
  let template: any[] = filteredSteps?.map((step: any) => ({ ...step, status: '' }));
  templateSteps = template?.filter((step: any) => step.id !== TemplateOptions.ClientInformation);
  if (templateSteps) {
    if (batchError?.length > 0) {
      templateSteps.unshift({ id: TemplateOptions.Errors, name: 'Errors', order: 1, status: '' })
    }
  }
  return templateSteps ?? [];
};

export const isExceedingOneDay = (date: Date) => {
  const now = moment();
  const hours: number = duration(now.diff(date)).asHours();
  return (hours > 24);
}

export const getTaxSoftwares = (taxSoftwares: TaxSoftware[]) => {
  let taxSoftwareTypes = [];
  const desiredOrder = ["GS", "UT", "LT", "CCH"];
  for (let software of taxSoftwares) {
    const label = TaxSoftwareTypeLabels[software];
    const value = TaxSoftwares[label as keyof typeof TaxSoftwares];
    if (label) {
      taxSoftwareTypes.push({
        value: value,
        label: label
      });
    }
  }
  taxSoftwareTypes.sort((a, b) => {
    return desiredOrder.indexOf(a.label) - desiredOrder.indexOf(b.label);
  });
  return taxSoftwareTypes;
};

export const createForethoughtCookie = (key: string, value: string, expiryMiliSeconds?: number) => {
  const domain = getDomain(window.location.href);
  createCookie(key, value, expiryMiliSeconds ?? 24 * 60 * 60 * 1000, `.${domain}`);
};

export function resetCookie(cookiename: string, value: string) {
  var cookiestring = document.cookie;
  var cookiearray = cookiestring.split(";");
  for (var i = 0; i < cookiearray.length; ++i) {
    if (cookiearray[i].trim().match("^" + cookiename + "=")) {
      if (cookiearray[i]) {
        cookiearray[i] = `${cookiename}=${value}`.trim();
      }
    }
  }
}

export const reportNameFormatter = (cell: any) => {
  return (
    <span title={cell} className="ellipsis report-name">
      {cell}
    </span>
  );
}

export const getKeyFromUrl = (url?: string) => {
  let key = "default";
  if (url && url.trim().length > 0) {
    let splitCharacter = url?.includes('/?') ? '/?' : '?';
    let urlArray = url?.split(splitCharacter)[0]?.split('/');
    if (urlArray) {
      const regex = /^[+-]?(\d+(\.\d*)?|\.\d+)$/;
      let isNumber: boolean = regex.test(urlArray[urlArray.length - 1]);
      key = isNumber ? `${urlArray[urlArray.length - 2]}` : `${urlArray[urlArray.length - 1]}`;
    }
  }
  return key;

}

export const getTruncatedFileName = (filename: string) => {
  var newFilename = filename.length > MAX_FILE_TEXT_LENGTH ? filename.slice(0, MAX_FILE_TEXT_LENGTH) + "..." : filename;
  return newFilename;
}

export const getBrowserDetails = (userBrowser: string) => {
  const browser = getParser(userBrowser);
  const formatedData =
    browser.getBrowserName() +
    '(' +
    browser.getBrowserVersion() +
    ')' +
    ' on ' +
    browser.getOSName() +
    '(' +
    browser.getOSVersion() +
    ')';

  return (
    <span title={formatedData}>
      {formatedData}
    </span>
  );

};

export function getFormattedLocalDateTime(utcDate: Date | string, format: string) {
  return utcDate ? utc(utcDate).local().format(format ?? 'MM/DD/YYYY') : '-';
}
export function getTextLengthInByte(text: string) {
  var textLength = text.length;
  for (let i = text.length - 1; i >= 0; i--) {
      let code = text.charCodeAt(i);
      if (code > 0x7f && code <= 0x7ff) {
          textLength++;
      } else if (code > 0x7ff && code <= 0xffff) {
          textLength += 2;
      }
      if (code >= 0xdc00 && code <= 0xdfff) {
          i--;
      }
  }
  return textLength;
}

export function generateUUID() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      const r = Math.random() * 16 | 0;
      const v = c === 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
  });
}
export const RangeList={
  "0 - 10%":1,
  "11 - 20%":2,
  "21 - 30%":3,
  "31 - 40%":4,
  "41 - 50%":5,
  "51 - 60%":6,
  "61 - 70%":7,
  "71 - 80%":8,
  "81 - 90%":9,
  "91 - 100%":10
  };
  
export function formatRangeListForDropdown(rangeList: { [key: string]: number }): { value: number; label: string }[] {
    return Object.entries(rangeList).map(([label, value]) => ({
      label,
      value
    }));
  }

export function checkAndRequestNewData(stateData: any, dispatch: any, action: (param?: any) => void, ...params: any) {
  if (stateData) {
    if ((Array.isArray(stateData) && stateData.length === 0) ||
      (typeof stateData === 'object' &&  Object.keys(stateData).length === 0)
      || stateData.length === 0 ||  stateData === true || stateData === "NoPolicy") {
      dispatch(action(...params));
    }
  }
  else {
    dispatch(action(...params));
  }
};

export function areObjectsEqual(obj1: any, obj2: any): boolean {
  if (obj1 === obj2) return true;
  
  if (typeof obj1 !== 'object' || obj1 === null || typeof obj2 !== 'object' || obj2 === null) return false;
  
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);
  
  if (keys1.length !== keys2.length) return false;
  for (const key of keys1) {
    if (!keys2.includes(key) || !areObjectsEqual(obj1[key], obj2[key])) return false;
  }
  return true;
};