import { TemplateModalConstants } from "helper/Constants";
import { ISections } from "store/models/CustomQuestionModel";
import { CustomModal } from "../UIComponents/CustomModal";
import { GrabIcon } from "assets/svg/SVGIconsCollection";
import { ReactSortable } from "react-sortablejs";
import { FC, useEffect, useState } from "react";

interface SectionMoveModalProps {
  show: boolean;
  onClose: () => void;
  onConfirm: (source: ISections[]) => void;
  sections: ISections[];
}

const SectionMoveModal: FC<SectionMoveModalProps> = ({ show, onClose, onConfirm, sections }) => {
  const [sectionsList, setSectionsList] = useState<ISections[]>([]);
  const handleOnSubmit = () => {
    onConfirm(sectionsList);
  };
  const hideModalHandler = () => {
    onClose();
  };
  const addIdForEverySection = () => {
    if (sections) {
      let newSections = sections?.map((section) => {
        return { ...section, id: section.sectionId };
      });
      setSectionsList(newSections);
    }
  };
  useEffect(() => {
    addIdForEverySection();
  }, [sections]);
  // TODO: Sorting to be done
  return (
    <CustomModal
      onSubmit={handleOnSubmit}
      title={TemplateModalConstants.ReorderSections}
      className="template-modal-container"
      show={show}
      onHide={hideModalHandler}
      removeBodyPadding={true}
      isConfirmModal
    >
      <div className="bootbox-body no-horizontal-padding">
        <ReactSortable
          filter=".addImageButtonContainer"
          dragClass="sortableDrag"
          list={sectionsList}
          setList={setSectionsList}
          animation={200}
          easing="ease-out"
        >
          {sectionsList?.map((item, index) => (
            <div className="draggableItem" key={item.id}>
              <GrabIcon />
              <div className="section-details">
                <label className="section-details-sectionName ellipsis" title={item.sectionName}>
                  {item.sectionName}
                </label>
                <label className="section-details-sectionCount">{`Section ${index + 1} of ${sectionsList.length}`}</label>
              </div>
            </div>
          ))}
        </ReactSortable>
      </div>
    </CustomModal>
  );
};
export default SectionMoveModal;
