import { Form } from "react-bootstrap";
import QuestionTypeDropdown from "../QuestionTypeDropdown";
import ParagraphQuestion from "./ParagraphQuestion";
import {
  FollowUpQuestionIdentifer,
  IAddSubQuestionPayload,
  IHandleChoiceIdentifierPayload,
  IHandleSubQuestionTypeChangePayload,
  IRemoveSubQuestionPayload,
  ISectionItems,
  ISubQuestionTitlePayload,
  QuestionType
} from "store/models/CustomQuestionModel";
import FollowUpQuestionTitle from "./followup-question-types/FollowUpQuestionTitle";
import { getMaxAdditionalQuestionDisbaledTooltip } from "helper/HelperFunctions";
import { PlusCircleIcon } from "assets/svg/SVGIconsCollection";
import MultiChoiceQuestion from "./followup-question-types/MultiChoiceQuestion";
import {
  HandleResetSubQuestions,
  OnAddAdditionalQuestion,
  OnChangeSubQuestionTitle,
  OnClickYesNoCheckbox,
  OnRemoveAdditionalQuestion,
  OnSubQuestionTypeChange
} from "store/slices/form-builder";
import { useAppDispatch, useAppSelector } from "common/hooks/redux-hooks";
import { AppState } from "store";
import { FC, useEffect, useState } from "react";

interface IYesNoQuestionProps {
  sectionItem: ISectionItems;
  sectionItemIndex: number;
  sectionIndex: number;
}

const YesNoQuestion: FC<IYesNoQuestionProps> = ({ sectionItem, sectionIndex, sectionItemIndex }) => {
  const [showYesNoCheckbox, setShowYesNoCheckbox] = useState(false);
  // const formData = customQuestionDummy;
  // const customQuestionSettings = cqSettingsDummy;
  const formData = useAppSelector((state: AppState) => state.formBuilderReducer.formData);
  const customQuestionSettings = useAppSelector((state: AppState) => state.formBuilderReducer.customQuestionSettings);
  const [localFormData, setLocalFormData] = useState(formData);
  const yesFollowUpQuestion =
    sectionItem.choices.length > 0 ? sectionItem.choices[FollowUpQuestionIdentifer.YES]?.subQuestions : [];
  const noFollowUpQuestion = sectionItem.choices.length > 0 ? sectionItem.choices[FollowUpQuestionIdentifer.NO]?.subQuestions : [];
  const dispatch = useAppDispatch();

  const ifSubQuestionsExists = () => {
    return sectionItem.choices.some((choice) => choice.subQuestions.length > 0);
  };
   useEffect(() => {
    if (ifSubQuestionsExists() || sectionItem.hasFollowUpQuestion) {
      setShowYesNoCheckbox(true);
    }
    setLocalFormData(formData);
  }, [formData]);

  const onClickAddFollowUpQuestion = () => {
    dispatch(
      HandleResetSubQuestions({
        data: {
          sectionIndex,
          sectionItemIndex
        },
        isChecked: !showYesNoCheckbox
      })
    );
    setShowYesNoCheckbox(!showYesNoCheckbox);
  };

  const onNoQuestionTypeChange = (selectedType: QuestionType) => {
    const payload: IHandleSubQuestionTypeChangePayload = {
      choiceIndex: FollowUpQuestionIdentifer.NO,
      sectionIndex: sectionIndex,
      sectionItemIndex: sectionItemIndex,
      questionType: selectedType,
      subQuestionIndex: 0
    };
    dispatch(OnSubQuestionTypeChange(payload));
  };
  const onYesQuestionTypeChange = (selectedType: QuestionType) => {
    const payload: IHandleSubQuestionTypeChangePayload = {
      choiceIndex: FollowUpQuestionIdentifer.YES,
      sectionIndex: sectionIndex,
      sectionItemIndex: sectionItemIndex,
      questionType: selectedType,
      subQuestionIndex: 0
    };
    dispatch(OnSubQuestionTypeChange(payload));
  };

  const getQuestionType = (
    followUpQuestionIdentifer: FollowUpQuestionIdentifer,
    questionType: QuestionType,
    subSectionItem: ISectionItems,
    sectionIndex: number,
    sectionItemIndex: number,
    questionIndex: number
  ) => {
    let isAdditionalQuestion = false;
    questionIndex === 0 ? (isAdditionalQuestion = false) : (isAdditionalQuestion = true);
    switch (questionType) {
      case QuestionType.PARAGRAPH:
        return (
          <div className="sub-question-wrapper">
            <FollowUpQuestionTitle
              sectionItem={subSectionItem}
              onChangeStoreHandler={onChangeQuestionTitle}
              isAdditionalQuestion={isAdditionalQuestion}
              choiceIndex={followUpQuestionIdentifer}
              questionIndex={questionIndex}
              onRemoveAdditionalQuestion={onRemoveAdditionalQuestion}
            />
            <ParagraphQuestion />
          </div>
        );
      case QuestionType.YESNO:
        return (
          <div className="sub-question-wrapper">
            <FollowUpQuestionTitle
              sectionItem={subSectionItem}
              onChangeStoreHandler={onChangeQuestionTitle}
              isAdditionalQuestion={isAdditionalQuestion}
              choiceIndex={followUpQuestionIdentifer}
              questionIndex={questionIndex}
              onRemoveAdditionalQuestion={onRemoveAdditionalQuestion}
            />
          </div>
        );
      case QuestionType.MULTICHOICE:
        return (
          <div className="sub-question-wrapper">
            <FollowUpQuestionTitle
              sectionItem={subSectionItem}
              onChangeStoreHandler={onChangeQuestionTitle}
              isAdditionalQuestion={isAdditionalQuestion}
              choiceIndex={followUpQuestionIdentifer}
              questionIndex={questionIndex}
              onRemoveAdditionalQuestion={onRemoveAdditionalQuestion}
            />
            <MultiChoiceQuestion
              followUpQuestionIdentifer={followUpQuestionIdentifer}
              sectionIndex={sectionIndex}
              sectionItem={subSectionItem}
              sectionItemIndex={sectionItemIndex}
              questionIndex={questionIndex}
            />
          </div>
        );

      default:
        break;
    }
  };
  const onClickYesNoCheckbox = (followUpQuestionIdentifer: FollowUpQuestionIdentifer) => {
    const payload: IHandleChoiceIdentifierPayload = {
      choiceIndex: followUpQuestionIdentifer,
      sectionIndex: sectionIndex,
      sectionItemIndex: sectionItemIndex
    };
    dispatch(OnClickYesNoCheckbox(payload));
  };
  const onAddAdditionalQuestion = (followUpQuestionIdentifer: FollowUpQuestionIdentifer, questionType: QuestionType) => {
    if (
      (followUpQuestionIdentifer == FollowUpQuestionIdentifer.NO &&
        noFollowUpQuestion.length < customQuestionSettings.additionalQuestionLimit) ||
      (followUpQuestionIdentifer == FollowUpQuestionIdentifer.YES &&
        yesFollowUpQuestion.length < customQuestionSettings.additionalQuestionLimit)
    ) {
      const payload: IAddSubQuestionPayload = {
        choiceIndex: followUpQuestionIdentifer,
        sectionIndex: sectionIndex,
        sectionItemIndex: sectionItemIndex,
        questionType: questionType
      };
      dispatch(OnAddAdditionalQuestion(payload));
    }
  };
  const onRemoveAdditionalQuestion = (followUpQuestionIdentifer: FollowUpQuestionIdentifer, questionIndex: number) => {
    const payload: IRemoveSubQuestionPayload = {
      choiceIndex: followUpQuestionIdentifer,
      sectionIndex: sectionIndex,
      sectionItemIndex: sectionItemIndex,
      questionIndex: questionIndex
    };
    dispatch(OnRemoveAdditionalQuestion(payload));
  };
  const onChangeQuestionTitle = (choiceIndex, questionIndex, input: string) => {
    const payload: ISubQuestionTitlePayload = {
      choiceIndex: choiceIndex,
      sectionIndex: sectionIndex,
      sectionItemIndex: sectionItemIndex,
      value: input,
      questionIndex: questionIndex
    };
    dispatch(OnChangeSubQuestionTitle(payload));
  };

  return (
    <div className="question-type-wrapper follow-up-wrapper">
      <Form.Check checked={showYesNoCheckbox} onClick={() => onClickAddFollowUpQuestion()} label="Follow-up Question" />

      {showYesNoCheckbox && (
        <div className="yes-no-wrapper">
          <div className="child-border">
            <div className="checkbox-dropdown-wrapper">
              <Form.Check
                checked={yesFollowUpQuestion.length > 0}
                onClick={() => onClickYesNoCheckbox(FollowUpQuestionIdentifer.YES)}
                label={`If "Yes"`}
              />
              {yesFollowUpQuestion.length > 0 && (
                <QuestionTypeDropdown
                  isFollowUpDropdown={true}
                  onQuestionTypeChange={onYesQuestionTypeChange}
                  selectedQuestionType={yesFollowUpQuestion[0].questionTypeId}
                />
              )}
            </div>
            {yesFollowUpQuestion.length > 0 && (
              <div className="question-wrapper">
                {localFormData.sections[sectionIndex].sectionItems[sectionItemIndex].choices[FollowUpQuestionIdentifer.YES]
                  .subQuestions &&
                  localFormData.sections[sectionIndex].sectionItems[sectionItemIndex].choices[FollowUpQuestionIdentifer.YES]
                    .subQuestions.length > 0 &&
                  localFormData.sections[sectionIndex].sectionItems[sectionItemIndex].choices[
                    FollowUpQuestionIdentifer.YES
                  ].subQuestions.map((additionalQuestion, questionIndex) => {
                    return getQuestionType(
                      FollowUpQuestionIdentifer.YES,
                      additionalQuestion.questionTypeId,
                      additionalQuestion,
                      sectionIndex,
                      sectionItemIndex,
                      questionIndex
                    );
                  })}
                <div
                  title={
                    yesFollowUpQuestion.length >= customQuestionSettings.additionalQuestionLimit
                      ? getMaxAdditionalQuestionDisbaledTooltip(customQuestionSettings.additionalQuestionLimit)
                      : ""
                  }
                  className={`add-additional-question-button ${
                    yesFollowUpQuestion.length >= customQuestionSettings.additionalQuestionLimit ? "isDisabled" : ""
                  }`}
                  onClick={() => onAddAdditionalQuestion(FollowUpQuestionIdentifer.YES, yesFollowUpQuestion[0].questionTypeId)}
                >
                  <PlusCircleIcon />
                  Add Additional Question
                </div>
              </div>
            )}
            <div className="checkbox-dropdown-wrapper">
              <Form.Check
                checked={noFollowUpQuestion.length > 0}
                onClick={() => onClickYesNoCheckbox(FollowUpQuestionIdentifer.NO)}
                label={`If "No"`}
              />
              {noFollowUpQuestion.length > 0 && (
                <QuestionTypeDropdown
                  isFollowUpDropdown={true}
                  onQuestionTypeChange={onNoQuestionTypeChange}
                  selectedQuestionType={noFollowUpQuestion[0].questionTypeId}
                />
              )}
            </div>
          </div>
          <div>
            {noFollowUpQuestion.length > 0 && (
              <div className="question-wrapper">
                {localFormData.sections[sectionIndex].sectionItems[sectionItemIndex].choices[FollowUpQuestionIdentifer.NO]
                  .subQuestions &&
                  localFormData.sections[sectionIndex].sectionItems[sectionItemIndex].choices[FollowUpQuestionIdentifer.NO]
                    .subQuestions.length > 0 &&
                  localFormData.sections[sectionIndex].sectionItems[sectionItemIndex].choices[
                    FollowUpQuestionIdentifer.NO
                  ].subQuestions.map((additionalQuestion, questionIndex) => {
                    {
                      return getQuestionType(
                        FollowUpQuestionIdentifer.NO,
                        additionalQuestion.questionTypeId,
                        additionalQuestion,
                        sectionIndex,
                        sectionItemIndex,
                        questionIndex
                      );
                    }
                  })}
                <div
                  title={
                    noFollowUpQuestion.length >= customQuestionSettings.additionalQuestionLimit
                      ? getMaxAdditionalQuestionDisbaledTooltip(customQuestionSettings.additionalQuestionLimit)
                      : ""
                  }
                  className={`add-additional-question-button ${
                    noFollowUpQuestion.length >= customQuestionSettings.additionalQuestionLimit ? "isDisabled" : ""
                  }`}
                  onClick={() => onAddAdditionalQuestion(FollowUpQuestionIdentifer.NO, noFollowUpQuestion[0].questionTypeId)}
                >
                  <PlusCircleIcon />
                  Add Additional Question
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default YesNoQuestion;
