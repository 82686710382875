import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { act } from "@testing-library/react";
import { EngagementCategory } from "common/model/engagement-category";
import { EngagementsType, EngagementsTypeDetails, EngagementsTypeState } from "common/model/engagements-type";

const engagementsType:EngagementsTypeDetails[]=[{id:0,isSystemDefault:true,isUserDefault:true,name:"",engagementCategoryId:0,engagementCategoryName:""}];
const initialEngagementsType:EngagementsTypeState={
    engagementsTypeArray:engagementsType,
    engagementCategories:[]
      
}
const engagementsTypeSlice = createSlice({
    name: 'engagementsType',
    initialState: initialEngagementsType,
    reducers: {
        setEngagementsType(state, action: PayloadAction<EngagementsTypeDetails[]>)
        {            
            state.engagementsTypeArray = action.payload.filter(p => p.isUserDefault && p.isSystemDefault).sort((a, b) => a.id - b.id)
                                                .concat(action.payload
                                                    .filter(p => p.isSystemDefault && !p.isUserDefault).sort((a, b) => a.id - b.id))
                                                .concat(action.payload
                                                    .filter(p => !p.isSystemDefault && p.isUserDefault).sort((a, b) => a.id - b.id))
                                                .concat(action.payload
                                                    .filter(p => !p.isSystemDefault && !p.isUserDefault).sort((a, b) => a.id - b.id))
        },
        setEngagementCategories(state, action: PayloadAction<EngagementCategory[]>)
        {
            state.engagementCategories=action.payload.sort((a, b) => a.id - b.id);
        },
        addEngagementsType(state, action: PayloadAction<EngagementsType>)
        {
            state.engagementsTypeArray.push(action.payload);
        },
        editEngagementsType(state, action: PayloadAction<EngagementsType>)
        {            
            state.engagementsTypeArray[state.engagementsTypeArray.findIndex((obj: EngagementsType) => obj.id===action.payload.id)]=(action.payload);
        },
        deleteEngagementsType(state, action: PayloadAction<EngagementsType>)
        {
           state.engagementsTypeArray= state.engagementsTypeArray.filter((item :EngagementsType )=> item.id !== action.payload.id);
        },
    }
});

export const {setEngagementsType,setEngagementCategories,addEngagementsType,editEngagementsType,deleteEngagementsType} = engagementsTypeSlice.actions;

export const { reducer: engagementsTypeReducer } = engagementsTypeSlice;