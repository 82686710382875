import { ThunkAction, Action } from '@reduxjs/toolkit';
import { ProductType } from '@sssuite-component-ui/ss-suite-layout';
import { AppNotifier } from 'common/components/toast/Toast';
import { GatherTemplate } from 'common/model/gather-template-settings';
import { GatherCompanySettingsModel } from 'common/model/company-settings';
import { Console } from 'console';
import { API } from "services/api/api-config"
import Axios from "services/api/axios-interceptor";
import { AppState } from 'store';
import { setCompanySettingData } from 'store/slices/companySettings-slice';
import { addGatherTemplateData, deleteGatherTemplateData, editGatherTemplateData, setAddStatus, setGatherTemplateData, startLoader, stopLoader } from 'store/slices/gathertemplate-settings-slice';
import { GatherTemplateNotification, RequestNotification } from 'helper/Constants';
import { setUploadTemplates } from 'store/slices/upload-template-slice';
import { IUploadTemplate } from 'common/model/upload-template';


export const getGatherTemplate = (callback?: (data: GatherTemplate[]) => void, isCacheable?:boolean): ThunkAction<void, AppState, unknown, Action> =>
    (dispatch) => {
        dispatch(startLoader());
        Axios.get(`${API.GATHER_TEMPLATE_SETTING.GATHER_TEMPLATE_SETTING_GETALL}`, {
            method: 'GET',
            credentials: 'include',
            'X-Cacheable': isCacheable
        })
            .then(response => {
                const templateData: GatherTemplate[] = response.data as GatherTemplate[];
                dispatch(setGatherTemplateData(templateData));
                let uploadTemplates: IUploadTemplate[] = templateData.map((template: GatherTemplate) => {
                    return {
                        id: template.uploadTemplateId,
                        name: template.name,
                        isDefault: template.isDefault
                    }
                });
                dispatch(setUploadTemplates(uploadTemplates));
                callback && callback(templateData);
                dispatch(stopLoader());
            }).catch((error: any) => {
                AppNotifier.Error(GatherTemplateNotification.Failure.FailedToFetch);
                dispatch(stopLoader());
            });
    }

export const addGatherTemplate = (gathetTemplate: GatherTemplate, callback: () => void): ThunkAction<void, AppState, unknown, Action> =>
    (dispatch) => {
        dispatch(startLoader());
        Axios.post(`${API.GATHER_TEMPLATE_SETTING.GATHER_TEMPLATE_SETTING_ADD}`, gathetTemplate, {
            credentials: 'include'
        })
            .then(response => {
                if (callback) callback();
                dispatch(addGatherTemplateData(gathetTemplate));
                dispatch(stopLoader());
                AppNotifier.Success(GatherTemplateNotification.Success.AddSuccess);
            }).catch((error: any) => {
                AppNotifier.Error(GatherTemplateNotification.Failure.FailedToAdd);
                dispatch(stopLoader());
            });

    }

export const editGatherTemplate = (gathetTemplate: GatherTemplate, callback: () => void): ThunkAction<void, AppState, unknown, Action> =>
    (dispatch) => {

        dispatch(startLoader());
        Axios.post(`${API.GATHER_TEMPLATE_SETTING.GATHER_TEMPLATE_SETTING_EDIT}`, gathetTemplate, {
            credentials: 'include'
        })
            .then(response => {
                if (callback) callback();
                dispatch(editGatherTemplateData(gathetTemplate));
                dispatch(stopLoader());
                AppNotifier.Success(GatherTemplateNotification.Success.EditSuccess);
            }).catch((error: any) => {
                AppNotifier.Error(GatherTemplateNotification.Failure.FailedToEdit);
                dispatch(stopLoader());
            });

    }

export const deleteGatherTemplate = (id: number, callback: () => void): ThunkAction<void, AppState, unknown, Action> =>
    (dispatch) => {

        dispatch(startLoader());
        Axios.delete(`${API.GATHER_TEMPLATE_SETTING.GATHER_TEMPLATE_SETTING_DELETE}/${id}`, {
            credentials: 'include'
        })
            .then(response => {
                if (callback) callback();
                dispatch(deleteGatherTemplateData(id));
                dispatch(stopLoader());
                AppNotifier.Success(GatherTemplateNotification.Success.DeletedSuccess);
            }).catch((error: any) => {
                AppNotifier.Error(GatherTemplateNotification.Failure.FailedToDelete);
                dispatch(stopLoader());
            });

    }

export const checkAddStatus = (): ThunkAction<void, AppState, unknown, Action> =>
    (dispatch) => {

        Axios.get(`${API.GATHER_TEMPLATE_SETTING.GATHER_TEMPLATE_SETTING_CHECKADDSTATUS}`, {
            credentials: 'include'
        })
            .then(response => {
                dispatch(setAddStatus(response.data))
            })

    }