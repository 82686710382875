import React from 'react'
import { useSelector } from "react-redux";
import { Route } from "react-router-dom";
import { AppState } from 'store';
import FeatureDisabled from 'Authorization/FeatureDisabled';
import ProductDisabled from 'Authorization/ProductDisabled';
import NoPermissionButProductAcess from 'Authorization/NoPermissionButProductAccess';
import { ProductType } from '@sssuite-component-ui/ss-suite-layout';
import { ICompanyProduct } from 'common/model/company';
import Loader from 'common/components/loader/Loader';

interface RouteProps {
    component: React.ComponentType<any>
    path: string
    checkPath?: string
    skipAuthorization?: boolean
}

const RBACRoute: React.FC<RouteProps> = ({ component, path,checkPath, skipAuthorization, ...rest }): React.ReactElement => {
    const isLoading = useSelector((state: AppState) => state.layoutReducer.isLoading);
    const isProductLoading = useSelector((state: AppState) => state.companyReducer.isLoading);
    const isRoutesEmpty = useSelector((state: AppState) => state.layoutReducer.isRoutesEmpty);
    const isHideLeftMenu = useSelector((state: AppState) => state.layoutReducer.hideLeftmenu);
    const routes : GatherRoute[] = useSelector((state: AppState) => state.layoutReducer.routeList);
    const companyProducts :ICompanyProduct[] = useSelector((state: AppState) => state.companyReducer.companyProducts);


    const isCompanyGatherSubscriptionEnabled = companyProducts.some(x=>x.productId==ProductType.Gather && x.isSubscribed == true);
    const isCompanyGatherProductEnabled =companyProducts.some(x=>x.productId==ProductType.Gather && x.isSubscribed == true && x.productStatus == 1);
    const isSkip = skipAuthorization !== undefined && skipAuthorization;
    const checkRouteAuthorization = (): boolean => {
        if (routes?.length > 0) {
            const route = checkPath !== null && checkPath !== undefined ? checkPath : path;
            return routes.some(x => x.route === route && x.isEnabled)
        }
        return false;
    }

    if (isLoading || isProductLoading || (!isRoutesEmpty && routes?.length == 0)) {
        return isHideLeftMenu ? <></> : <Loader />
    }
    else if (!isCompanyGatherSubscriptionEnabled) {
        return <Route exact  path={path} component={FeatureDisabled} {...rest} />
    }
    else if (!isCompanyGatherProductEnabled) {
        return <Route  exact path={path} component={ProductDisabled} {...rest} />
    }
    else if (checkRouteAuthorization() || isSkip) {
        return <Route exact path={path} component={component} {...rest} />
    }
    else {
        return <Route exact path={path} component={NoPermissionButProductAcess} {...rest} />
    }
}

export default RBACRoute;