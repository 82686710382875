import { DropUpArrow, DropdownArrow } from "assets/icons/IconCollection";
import { FC } from "react";
import Select, { Option } from "react-select";

export interface IDropdownComponentProps {
  selectedValue?: any;
  options: any;
  onChange?: (selectedValue: any) => void;
  onOpen?: () => void;
  clearable?: boolean;
  disabled?: boolean;
  customPlaceHolder?: any;
  id?: string;
  onFocus?: (event: any) => void;
  backspaceRemoves?: boolean;
  deleteRemoves?: boolean;
  searchable?: boolean;
  customClassName?: string;
  overflowClassName?: string;
  multipleSelection?: boolean;
  customOptionRenderer?: (option: Option) => React.ReactNode;
  customValueRenderer?: (option: Option) => React.ReactNode;
}

export const DropdownComponent: FC<IDropdownComponentProps> = (props) => {
  const onChange = (event: any) => {
    const selectedValue = event ? event.value : event;
    if (props.onChange) {
      props.onChange(selectedValue);
    }
  };
  const onOpen = () => {
    if (props.onOpen) {
      props.onOpen();
    }
  };
  const defaultOptionRenderer = (option: Option) => {
    return (
      <p title={option.label} className="ellipsis custom-option-label">
        {option.label}
      </p>
    );
  }; 
  const defaultValueRenderer = (option: Option) => {
    return (
      <p title={option.label} className={`ellipsis ${props.overflowClassName??""}`}>
        {option.label}
      </p>
    );
  };
  const optionRenderer = props.customOptionRenderer ?? defaultOptionRenderer;
  const valueRenderer = props.customValueRenderer ?? defaultValueRenderer;
  return (
    <Select
      arrowRenderer={(event) => !event.isOpen ? <DropdownArrow /> : <DropUpArrow />}
      className={"ss-select " + props.customClassName}
      searchable={false}
      id={props.id}
      value={props.selectedValue}
      onChange={onChange}
      onOpen={onOpen}
      options={props.options || []}
      placeholder={props.customPlaceHolder && props.customPlaceHolder.length > 0 ? props.customPlaceHolder : "Select..."}
      clearable={props.clearable}
      disabled={props.disabled ? props.disabled : false}
      data-toggle="dropdown"
      data-boundary="window"
      onFocus={props.onFocus}
      backspaceRemoves={!props.backspaceRemoves}
      deleteRemoves={!props.deleteRemoves}
      optionRenderer={optionRenderer}
      valueRenderer={valueRenderer}
    />
  );
};

export default DropdownComponent;
