import { Action, ThunkAction } from '@reduxjs/toolkit';
import { AppNotifier } from 'common/components/toast/Toast';
import { EngagementCategory } from 'common/model/engagement-category';
import { EngagementsType, EngagementsTypeDetails } from 'common/model/engagements-type';
import { EngagementTypeApiConstants } from 'helper/Constants';
import { API } from "services/api/api-config";
import Axios from "services/api/axios-interceptor";
import { AppState } from 'store';
import { addEngagementsType, deleteEngagementsType, editEngagementsType, setEngagementCategories, setEngagementsType } from 'store/slices/engagement-type-slice';
import { } from 'store/slices/loading-status-slice';


export const getEngagementsType = (callback?: () => void, isCacheable?:boolean): ThunkAction<void, AppState, unknown, Action> =>
    (dispatch) => {
        try {
            Axios.get(`${API.ENGAGEMENT_TYPE.GET_ENGAGEMENT_TYPE}`, {
                method: 'GET',
                credentials: 'include',
                'X-Cacheable': isCacheable
            })
                .then(response => {
                    // Assuming response.data contains the settings data
                    const engagementsType: EngagementsTypeDetails[] = response.data as EngagementsTypeDetails[];
                    // Dispatch the action to update the Redux store
                    dispatch(setEngagementsType(engagementsType));
                    callback && callback();
                }).catch(() => {
                    callback && callback();
                })
        }
        catch (error: any) {
            AppNotifier.Error(EngagementTypeApiConstants.Failure.Get);
        }
    }

    export const getAllEngagementsType = (callback?: (engagementsType: EngagementsTypeDetails[]) => void,isCacheable?:boolean): ThunkAction<void, AppState, unknown, Action> =>
        (dispatch) => {
            try {
                Axios.get(`${API.ENGAGEMENT_TYPE.GET_ALL_ENGAGEMENT_TYPE}`, {
                    method: 'GET',
                    credentials: 'include',
                    'X-Cacheable': isCacheable
                })
                    .then(response => {
                        // Assuming response.data contains the settings data
                        const engagementsType: EngagementsTypeDetails[] = response.data as EngagementsTypeDetails[];
                        // Dispatch the action to update the Redux store
                        callback && callback(engagementsType);
                    })
            }
            catch (error: any) {
                AppNotifier.Error(EngagementTypeApiConstants.Failure.Get);
            }
        } 

export const addEngagementsTypeapi = (
    addedEngagenetType: EngagementsType,
    callback?: () => void): ThunkAction<void, AppState, unknown, Action> =>
    (dispatch) => {
        Axios.post(`${API.ENGAGEMENT_TYPE.ADD_ENGAGEMENT_TYPE}`, addedEngagenetType, {
            credentials: 'include'
        })
            .then(response => {

                // Assuming response.data contains the settings data
                addedEngagenetType.id = response.data as number;
                // Dispatch the action to update the Redux store
                dispatch(addEngagementsType(addedEngagenetType))
                AppNotifier.Success(EngagementTypeApiConstants.Success.Add);
                callback && callback();
            }).catch(() => {
                AppNotifier.Error(EngagementTypeApiConstants.Failure.Add);
            })
    }

export const editEngagementsTypeapi = (
    editedEngagementsType: EngagementsType,
    callback?: () => void): ThunkAction<void, AppState, unknown, Action> =>
    (dispatch) => {
        Axios.put(`${API.ENGAGEMENT_TYPE.EDIT_ENGAGEMENT_TYPE}`, editedEngagementsType, {
            credentials: 'include'
        })
            .then(response => {
                dispatch(editEngagementsType(editedEngagementsType))
                AppNotifier.Success(EngagementTypeApiConstants.Success.Edit);
                callback && callback();
            }).catch(() => {
                AppNotifier.Error(EngagementTypeApiConstants.Failure.Edit);
            })
    }

export const deleteEngagementTypesApi = (deletemessage: EngagementsType, callback?: () => void): ThunkAction<void, AppState, unknown, Action> =>
    (dispatch) => {
        const data = {
            id: deletemessage.id,
            name: deletemessage.name,
        }
        Axios.deleteWithPayload(`${API.ENGAGEMENT_TYPE.DELETE_ENGAGEMENT_TYPE}`, data, {
            headers: { credentials: 'include' }
        })
            .then(response => {

                dispatch(deleteEngagementsType(deletemessage))
                AppNotifier.Success(EngagementTypeApiConstants.Success.Delete);
                callback && callback();
            }).catch(() => {
                AppNotifier.Error(EngagementTypeApiConstants.Failure.Delete);
            })
    }

export const getEngagementCategories = (callback?: () => void,isCacheable?:boolean): ThunkAction<void, AppState, unknown, Action> =>
    (dispatch) => {
        try {
            Axios.get(`${API.ENGAGEMENT_TYPE.GET_ENGAGEMENT_CATEGORIES}`, {
                method: 'GET',
                credentials: 'include',
                'X-Cacheable': isCacheable
            })
                .then(response => {
                    // Assuming response.data contains the settings data
                    const engagementCategories: EngagementCategory[] = response.data as EngagementCategory[];
                    // Dispatch the action to update the Redux store
                    dispatch(setEngagementCategories(engagementCategories));
                    callback && callback();
                }).catch(() => {
                    callback && callback();
                })
        }
        catch (error: any) {
            AppNotifier.Error(EngagementTypeApiConstants.Failure.GetCategories);
        }
    }