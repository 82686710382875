import { ThunkAction, Action } from "@reduxjs/toolkit";
import { AppNotifier } from "common/components/toast/Toast";
import { ICustomQuestionTemplate } from "common/model/custom-questions";
import { ErrorMessage, RequestNotification, TemplateModalConstants } from "helper/Constants";
import { API } from "services/api/api-config";
import Axios from "services/api/axios-interceptor";
import { AppState } from "store";
import {
  ICustomQuestion,
  ICustomQuestionSettings,
  ICustomQuestionsState,
  IFormData,
  IQuestionnaireState,
  ISelectedTemplate
} from "store/models/CustomQuestionModel";
import {
  saveCustomQuestions,
  setQuestionnaireTemplateList,
  setSelectedCustomQuestion,
  startLoader,
  stopLoader
} from "store/slices/custom-question-templates";
import { setCustomQuestion, setCustomQuestionSettings } from "store/slices/form-builder";

export const getCustomQuestions = (isCacheable?:boolean): ThunkAction<void, AppState, unknown, Action> => (dispatch) => {
  dispatch(startLoader());
  Axios.get(`${API.SETTINGS.CustomQuestions.CUSTOM_QUESTIONS_TEMPLATES}`,{'X-Cacheable': isCacheable,})
    .then((response) => response.data)
    .then((data) => {
      const response: ICustomQuestionsState = {
        customQuestions: data,
        isLoading: false,
        questionnaireTemplates: [],
        selectedTemplate: null
      };
      dispatch(saveCustomQuestions(response));
    })
    .catch(() => {
      AppNotifier.Error(RequestNotification.Failure.AllGathers);
      dispatch(stopLoader());
    });
};

export const editCustomQuestiontemplate =
  (data: ICustomQuestion, callback?: () => void): ThunkAction<void, AppState, unknown, Action> =>
  (dispatch) => {
    dispatch(startLoader());
    Axios.put(`${API.SETTINGS.CustomQuestions.CUSTOM_QUESTIONS_TEMPLATES}/${data.id}`, data, {
      credentials: "include"
    })
      .then((response) => response)
      .then((data) => {
        if (data) {
          AppNotifier.Success(TemplateModalConstants.EditSuccessMessage);
        }
        if (callback) callback();
      })
      .catch(() => {
        dispatch(stopLoader());
        AppNotifier.Error(RequestNotification.Failure.AllGathers);
      })
      .finally(() => {
        dispatch(stopLoader());
      });
  };

export const deleteCustomQuestiontemplate =
  (data: Number, callback?: () => void): ThunkAction<void, AppState, unknown, Action> =>
  (dispatch) => {
    dispatch(startLoader());
    Axios.delete(`${API.SETTINGS.CustomQuestions.CUSTOM_QUESTIONS_TEMPLATES}/${data}`, {
      credentials: "include"
    })
      .then((response) => response)
      .then((data) => {
        if (data) {
          AppNotifier.Success(TemplateModalConstants.DeleteSuccessMessage);
        }
        if (callback) callback();
      })
      .catch(() => AppNotifier.Error(RequestNotification.Failure.AllGathers))
      .finally(() => {
        dispatch(stopLoader());
      });
  };

export const setDefaultCustomQuestiontemplate =
  (id: Number, data: boolean, callback?: () => void): ThunkAction<void, AppState, unknown, Action> =>
  (dispatch) => {
    dispatch(startLoader());
    Axios.put(`${API.SETTINGS.CustomQuestions.CUSTOM_QUESTIONS_TEMPLATES}/${id}/set-default`, data, {
      credentials: "include"
    })
      .then((response) => response)
      .then((data) => {
        if (data) {
          AppNotifier.Success(
            data ? TemplateModalConstants.SetDefaultTemplateSuccess : TemplateModalConstants.UnsetDefaultTemplateSuccess
          );
        }
        if (callback) callback();
      })
      .catch(() => {
        AppNotifier.Error(RequestNotification.Failure.AllGathers);
      })
      .finally(() => {
        dispatch(stopLoader());
      });
  };

export const getCustomQuestionsSetting = (isCacheable?:boolean): ThunkAction<void, AppState, unknown, Action> => (dispatch) => {
  dispatch(startLoader());
  Axios.get(`${API.SETTINGS.CustomQuestions.CUSTOM_QUESTIONS_SETTINGS}`, {
    method: "GET",
    credentials: "include",
    'X-Cacheable': isCacheable,
  })
    .then((response) => response)
    .then((data: any) => {
      const response: ICustomQuestionSettings = data.data as ICustomQuestionSettings;
      dispatch(stopLoader());
      dispatch(setCustomQuestionSettings(response));
    })
    .catch(() => {
      AppNotifier.Error(RequestNotification.Failure.AllGathers);
    })
    .finally(() => {
      dispatch(stopLoader());
    });
};

export const getQuestionerForm =
  (id: number, isCacheable?:boolean): ThunkAction<void, AppState, unknown, Action> =>
  (dispatch, getState) => {
    dispatch(startLoader());
    Axios.get(`${API.SETTINGS.CustomQuestions.CUSTOM_QUESTIONS_FORM}/${id}`, {
      method: "GET",
      credentials: "include",
      'X-Cacheable': isCacheable,
    })
      .then((response) => response)
      .then((data: any) => {
        const store = getState();
        const response: IQuestionnaireState = {
          formData: data.data as IFormData,
          isLoading: false,
          customQuestionSettings: store.formBuilderReducer.customQuestionSettings
        };
        dispatch(setCustomQuestion(response.formData));
      })
      .catch(() => {
        AppNotifier.Error(RequestNotification.Failure.AllGathers);
      })
      .finally(() => {
        dispatch(stopLoader());
      });
  };

export const CreateQuestionerForm =
  (data: IFormData, callback?: () => void): ThunkAction<void, AppState, unknown, Action> =>
  (dispatch) => {
    dispatch(startLoader());
    Axios.post(`${API.SETTINGS.CustomQuestions.CUSTOM_QUESTIONS_FORM}`, data, {
      credentials: "include"
    })
      .then((response) => response)
      .then((data) => {
        dispatch(getCustomQuestions());
        AppNotifier.Success(TemplateModalConstants.AddSuccessMessage);
        if (callback) callback();
      })
      .catch(() => {
        AppNotifier.Error(RequestNotification.Failure.AllGathers);
      })
      .finally(() => {
        dispatch(stopLoader());
      });
  };
export const UpdateQuestionerForm =
  (data: IFormData, callback?: () => void): ThunkAction<void, AppState, unknown, Action> =>
  (dispatch) => {
    dispatch(startLoader());
    Axios.put(`${API.SETTINGS.CustomQuestions.CUSTOM_QUESTIONS_FORM}/${data.template.templateId}`, data, {
      credentials: "include"
    })
      .then((response) => response)
      .then((data) => {
        if (callback) callback();
        AppNotifier.Success(TemplateModalConstants.EditSuccessMessage);
      })
      .catch(() => {
        AppNotifier.Error(RequestNotification.Failure.AllGathers);
      })
      .finally(() => {
        dispatch(stopLoader());
      });
  };
export const getSelectedCustomQuestionnaire =
  (
    questionnaireTemplateId: number,
    successCallback?: (template: ICustomQuestionTemplate) => void,
    failureCallback?: () => void
  ): ThunkAction<void, AppState, unknown, Action> =>
  (dispatch) => {
    Axios.get(`${API.SETTINGS.CustomQuestions.CUSTOM_QUESTIONS_FORM}/${questionnaireTemplateId}`)
      .then((response) => response.data)
      .then((data) => {
        dispatch(setSelectedCustomQuestion(data));
        successCallback && successCallback(data as ICustomQuestionTemplate);
      })
      .catch(() => {
        AppNotifier.Error(RequestNotification.Failure.AllGathers);
      })
      .finally(() => {
        dispatch(stopLoader());
      });
  };

export const getBatchSelectedCustomQuestionnaire =
  (batchId: number, isCacheable?:boolean): ThunkAction<void, AppState, unknown, Action> =>
  (dispatch) => {
    Axios.get(`${API.CUSTOMQUESTIONS.GET_CUSTOM_QUESTIONS}/batch/${batchId}`, {
      method: "GET",
      credentials: "include",
      'X-Cacheable': isCacheable,
    })
      .then((response) => response.data)
      .then((response: ISelectedTemplate) => {
        dispatch(setSelectedCustomQuestion(response));
      })
      .catch(() => {
        AppNotifier.Error(RequestNotification.Failure.AllGathers);
      })
      .finally(() => {
        dispatch(stopLoader());
      });
  };
export const getGatherSelectedCustomQuestionnaire =
  (gatherId: number,isCacheable?:boolean): ThunkAction<void, AppState, unknown, Action> =>
  (dispatch) => {
    Axios.get(`${API.CUSTOMQUESTIONS.GET_CUSTOM_QUESTIONS}/gather/${gatherId}`, {
      method: "GET",
      credentials: "include",
      'X-Cacheable': isCacheable,
    })
      .then((response) => response.data)
      .then((response: ISelectedTemplate) => {
        dispatch(setSelectedCustomQuestion(response));
      })
      .catch(() => {
        AppNotifier.Error(RequestNotification.Failure.AllGathers);
      })
      .finally(() => {
        dispatch(stopLoader());
      });
  };
export const getQuestionnaireTemplateList = (isCacheable?:boolean): ThunkAction<void, AppState, unknown, Action> => (dispatch) => {
  Axios.get(`${API.CUSTOMQUESTIONS.GET_CUSTOM_QUESTION_TEMPLATES}`, {
    method: "GET",
    credentials: "include",
    'X-Cacheable': isCacheable,
  })
    .then((response) => response.data)
    .then((response) => {
      dispatch(setQuestionnaireTemplateList(response));
    })
    .catch(() => {
      AppNotifier.Error(RequestNotification.Failure.AllGathers);
    })
    .finally(() => {
      dispatch(stopLoader());
    });
};

export const getTemplateInUse =
  (gatherId: Number, onResponse: (response: any) => void, isCacheable?:boolean): ThunkAction<void, AppState, unknown, Action> =>
  (dispatch) => {
    Axios.get(`${API.CUSTOMQUESTIONS.GET_QUESTIONNAIRE_IN_USE}/${gatherId}`, {
      method: "GET",
      credentials: "include",
      'X-Cacheable': isCacheable,
    })
      .then((response) => response.data)
      .then((response) => {
        if (onResponse) {
          onResponse(response);
        }
      })
      .catch(() => {
        AppNotifier.Error(ErrorMessage);
      });
  };
